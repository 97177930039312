import { memo, useCallback, useMemo } from 'react';
import { KtruDetail, OkpdDetail } from '@/api/okpdApi/okpdApiTypes';
import { Button, Flex, Typography } from 'antd';
import { StandardContract } from '@/widgets/OkpdKtpu/components';
import { Badge } from '@/components';
import CheckRoundIcon from '/public/icons/checkRound.svg';
import CopyIcon from '/public/icons/copy.svg';
import ExportIcon from '/public/icons/export.svg';
import SearchIcon from '/public/icons/search.svg';
import ListPaperIcon from '/public/icons/listPaper.svg';
import QuestionRoundIcon from '/public/icons/questionRound.svg';
import Link from 'next/link';
import cx from 'classnames';
import styles from './OkpdKtruDetailInfo.module.scss';
import { SetOkpdKtruModalPageParamsType } from '@/components/OkpdFlowModal/types';
import { pageRoutes } from '@/app/pageRoutes';
import { OkpdLink } from '@/components/OkpdFlowModal/OkpdLink/OkpdLink';
import RestrictIcon from '/public/icons/restrict.svg';
import { NmckType } from '@/api/nmckApi/nmckApiTypes';
import { useRouter } from 'next/navigation';
import { isKtruDetail } from '@/components/OkpdKtruDetail/utils';

type OkpdDetailInfoProps = {
  data: OkpdDetail | KtruDetail;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
  showStandardContracts?: boolean;
};

const copyable = {
  tooltips: ['Нажмите чтобы скопировать', 'Скопировано'],
  icon: [
    <CopyIcon
      key="copy-icon"
      className={styles.copyIcon}
    />,
  ],
};

const StatusIcon = ({ active }: { active: boolean }) =>
  active ? (
    <CheckRoundIcon className={styles.statusIcon} />
  ) : (
    <RestrictIcon className={styles.statusIcon} />
  );

export const OkpdKtruDetailInfo = memo(
  ({ data, setModalPageParams, showStandardContracts = true }: OkpdDetailInfoProps) => {
    const isKtru = isKtruDetail(data);
    const router = useRouter();

    const recommendedCalculationMethodUrl = useMemo(() => {
      switch (data.nmckMethod.type) {
        case NmckType.MARKET: {
          const query = new URLSearchParams({
            ...(isKtru ? { ktruCode: data.code } : { okpdCode: data.code }),
            name: data.name,
          });

          return `${pageRoutes.nmckAnalysis}?${query}`;
        }
        case NmckType.DRUGS: {
          return pageRoutes.nmckDrugs;
        }
        case NmckType.GUARD: {
          return pageRoutes.nmckGuard;
        }
        case NmckType.MED_DEVICES: {
          const query = new URLSearchParams({
            ...(isKtru ? { ktruCode: data.code } : { okpdCode: data.code }),
            ...(isKtru && data.nkmiId ? { nkmi: String(data.nkmiId) } : {}),
            name: data.name,
          });

          return `${pageRoutes.nmckMedDevices}?${query}`;
        }

        default: {
          return '';
        }
      }
    }, [data, isKtru]);

    const handleExcelDownload = useCallback(() => {
      // todo: Ручка скачивания экселя (еще не готова)
    }, []);

    const handleContractsClick = useCallback(() => {
      const query = new URLSearchParams({
        code: data.code,
      });

      router.push(`${pageRoutes.docs}?${query}`);
    }, [data.code, router]);

    const handleChildLinkClick = useCallback(
      (code: string) => () =>
        setModalPageParams &&
        setModalPageParams((prev) => ({
          ...prev,
          code,
        })),
      [setModalPageParams],
    );

    return (
      <Flex
        gap={64}
        className={styles.wrapper}
      >
        <Flex
          gap={8}
          vertical
          className={styles.left}
        >
          <div>
            <StatusIcon active={data.active} />
            <Typography.Text copyable={{ ...copyable, text: data.name }}>
              <span className={styles.title}>
                {data.code} — {data.name}
              </span>
            </Typography.Text>
          </div>
          {'children' in data && (
            <Flex
              vertical
              gap={12}
              className={styles.childrenList}
            >
              {data.children.map(({ id, code, name }) => (
                <OkpdLink
                  key={id}
                  isLink={!setModalPageParams}
                  href={`${pageRoutes.okpd2}/${code}`}
                  onClick={handleChildLinkClick(code)}
                  className={styles.childLink}
                >
                  {code} — {name}
                </OkpdLink>
              ))}
            </Flex>
          )}
        </Flex>

        <Flex
          vertical
          gap={8}
          className={styles.right}
        >
          <Button
            icon={<ExportIcon />}
            type="link"
            className={styles.button}
            onClick={handleExcelDownload}
          >
            Excel
          </Button>
          <div className={cx(styles.contract, styles.row)}>
            <QuestionRoundIcon />
            <div>
              Рекомендуемый метод расчёта НМЦК:{' '}
              <Link href={recommendedCalculationMethodUrl}>{data.nmckMethod.name}</Link>
            </div>
          </div>
          {showStandardContracts && (
            <div className={cx(styles.contract, styles.row)}>
              <ListPaperIcon />
              <StandardContract standardContract={data.standardContracts} />
            </div>
          )}
          <Flex
            align={'center'}
            gap={8}
          >
            <Button
              icon={<SearchIcon />}
              type="link"
              className={styles.button}
              onClick={handleContractsClick}
              disabled={!data.countOfContracts}
            >
              Связанные закупки
            </Button>
            <Badge count={data.countOfContracts} />
          </Flex>
        </Flex>
      </Flex>
    );
  },
);

OkpdKtruDetailInfo.displayName = 'OkpdDetailInfo';
