import { CSSProperties, memo, Ref, useEffect, useMemo, useRef, useState } from 'react';
import { Tooltip } from 'antd';
import Link from 'next/link';
import cx from 'classnames';
import { TooltipPlacement } from 'antd/lib/tooltip';

import styles from './ElipsisLabel.module.scss';

type ElipsisLabelType = {
  label?: string;
  href?: string;
  placement?: TooltipPlacement;
  width?: number;
};

const tooltipStyle: CSSProperties = {
  width: 'max-content',
};

export const ElipsisLabel = memo(({ label, href, placement, width = 595 }: ElipsisLabelType) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const containerRef = useRef<HTMLAnchorElement | HTMLParagraphElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      setIsOverflowing(containerRef.current.scrollHeight > containerRef.current.clientHeight);
    }
  }, []);

  const getStyle = useMemo(() => {
    return { ...tooltipStyle, maxWidth: width };
  }, [width]);

  return (
    <div>
      <Tooltip
        title={isOverflowing && label}
        overlayInnerStyle={getStyle}
        placement={placement}
      >
        {href ? (
          <Link
            ref={containerRef as Ref<HTMLAnchorElement>}
            href={href}
            className={cx(styles.text, styles.link)}
            target="_blank"
          >
            {label ?? '—'}
          </Link>
        ) : (
          <p
            ref={containerRef as Ref<HTMLParagraphElement>}
            className={styles.text}
          >
            {label ?? '—'}
          </p>
        )}
      </Tooltip>
    </div>
  );
});

ElipsisLabel.displayName = 'ElipsisLabel';
