import { memo } from 'react';
import SearchIcon from '/public/icons/search.svg';
import styles from './EisSearch.module.scss';
import { Button, Tooltip } from 'antd';
import Question from 'public/icons/questionRound.svg';

type EisSearchProps = {
  onClick: () => void;
};

export const EisSearch = memo(({ onClick }: EisSearchProps) => {
  return (
    <div className={styles.eis}>
      <Button
        type="link"
        size="small"
        className={styles.eisText}
        onClick={onClick}
      >
        <SearchIcon />
        <span>ЕИС</span>
      </Button>
      <Tooltip
        title="Поиск исполненных без неустоек контрактов"
        placement="top"
      >
        <Question className="hint-icon" />
      </Tooltip>
    </div>
  );
});
EisSearch.displayName = 'EisSearch';
