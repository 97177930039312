import {
  BDUIFilterItemType,
  BDUIFilterParam,
  BDUIFilterResponse,
} from '@/components/BDUIFilter/types';
import { Option } from '@/types/types';
import { uniqBy } from 'lodash';

export const getItemsMapByBDUIFiltersResponse = (
  filters: BDUIFilterResponse[],
): Record<string, Option[]> =>
  filters.reduce(
    (acc, { caption, values }) => {
      if (values) {
        acc[caption] = values.map((item) => ({ value: item.value, label: item.caption }));
      }
      return acc;
    },
    {} as Record<string, Option[]>,
  );

const getNameOption = ({
  caption,
  type,
  queryParamName,
  queryParamNameFrom,
  queryParamNameTo,
}: BDUIFilterResponse): Option => ({
  value: caption,
  label: caption,
  type,
  queryParamName,
  queryParamNameFrom,
  queryParamNameTo,
});

export const getNameOptions = (filtersResponse: BDUIFilterResponse[]): Option[] =>
  filtersResponse.map(getNameOption);

export const getItemWithDefaultsValues = (
  filtersResponse: BDUIFilterResponse[],
): BDUIFilterItemType[] => {
  const filterItemsWithDefaultValues = filtersResponse.filter(
    ({ selectedValue, selectedFromDtValue, selectedToDtValue, selectedValuesInt }) =>
      selectedFromDtValue || selectedValue || selectedValuesInt || selectedToDtValue,
  );
  const nameOptions = getNameOptions(filtersResponse);

  return filterItemsWithDefaultValues.map(
    (
      {
        emptyValue,
        emptyToValue,
        emptyFromValue,
        type,
        selectedValue,
        selectedValuesInt,
        selectedFromDtValue,
        selectedToDtValue,
      },
      index,
    ) => {
      const isEmptyValuesExist = emptyValue || emptyFromValue || emptyToValue;
      let itemValue: BDUIFilterItemType['value'];

      if (selectedValue) {
        itemValue = {
          type: 'single',
          value: selectedValue,
        };
      } else if (selectedValuesInt) {
        itemValue = {
          type: 'multiselect',
          values: selectedValuesInt,
        };
      } else if (selectedFromDtValue || selectedToDtValue) {
        itemValue = {
          type: 'range',
          min: selectedFromDtValue,
          max: selectedToDtValue,
        };
      }

      return {
        id: index + 1,
        name: nameOptions[index],
        type,
        value: itemValue,
        ...(isEmptyValuesExist
          ? {
              emptyValues: {
                emptyValue,
                emptyToValue,
                emptyFromValue,
              },
            }
          : {}),
      };
    },
  );
};

export const getAllUniqueItems = (
  items: BDUIFilterItemType[],
  itemsWithEmptyValues: BDUIFilterItemType[],
): BDUIFilterItemType[] =>
  uniqBy(
    [
      ...items.filter((item) => {
        if (item.value) {
          if (item.value.type === 'multiselect') {
            return item.value.values?.length;
          }
          if (item.value.type === 'single') {
            return item.value.value;
          }

          return item.value.max || item.value.min;
        }
      }),
      ...itemsWithEmptyValues,
    ],
    (value: BDUIFilterItemType) => value.name?.label,
  );

export const getParamsByItems = (items: BDUIFilterItemType[]): BDUIFilterParam[] =>
  items
    .filter(({ name, value, emptyValues }) => name && (value || emptyValues))
    .map(({ name, value, emptyValues }) => {
      const param: BDUIFilterParam = {
        value,
        emptyValues,
      };

      if (value?.type === 'single') {
        param.queryParamName = name?.queryParamName ?? '';
      } else if (value?.type === 'range') {
        if (name?.queryParamNameFrom) {
          param.queryParamNameFrom = name.queryParamNameFrom;
        }
        if (name?.queryParamNameTo) {
          param.queryParamNameTo = name.queryParamNameTo;
        }
      } else if (value?.type === 'multiselect') {
        param.queryParamName = name?.queryParamName ?? '';
      }

      return param;
    });
