import { ChangeEvent, useCallback, useState, memo } from 'react';
import { Flex, Input, Select } from 'antd';
import Cross from '/public/icons/cross.svg';
import DeleteIcon from '/public/icons/trash.svg';
import Vicon from '/public/icons/v.svg';
import { Option } from '@/types/types';
import {
  Characteristic,
  CharacteristicType,
  RangeSign,
} from '@/api/planningCurrentApi/planningCurrentApiTypes';
import styles from './CharsItem.module.scss';

const clearIcon = { clearIcon: <Cross className={styles.crossIcon} /> };
const typeOptions: Option[] = [
  { value: CharacteristicType.RANGE, label: 'Диапазон' },
  { value: CharacteristicType.TEXT, label: 'Качественная' },
  { value: CharacteristicType.NUMERIC, label: 'Точное значение' },
];
const rangeOptions: Option[] = [
  { value: RangeSign.LESS, label: '<' },
  { value: RangeSign.MORE, label: '>' },
  { value: RangeSign.MORE_EQUAL, label: '≥' },
  { value: RangeSign.LESS_EQUAL, label: '≤' },
];

type CharsItemProps = {
  char: Characteristic;
  updateChar: (id: number, updatedChar: Characteristic) => void;
  deleteChar: (id: number) => void;
};

export const CharsItem = memo(({ char, updateChar, deleteChar }: CharsItemProps) => {
  const [state, setState] = useState<Characteristic>(char);

  const handleInputChange = useCallback(
    (key: keyof Characteristic, value: string | number | CharacteristicType | RangeSign) => {
      const updatedChar = { ...state, [key]: value };
      setState(updatedChar);
      updateChar(updatedChar.id, updatedChar);
    },
    [state, updateChar],
  );

  const handleTextInputChange = useCallback(
    (key: keyof Characteristic) => (e: ChangeEvent<HTMLInputElement>) => {
      handleInputChange(key, e.target.value);
    },
    [handleInputChange],
  );

  const handleNumberInputChange = useCallback(
    (key: keyof Characteristic) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value === '' ? null : Number(e.target.value);
      handleInputChange(key, Number(value));
    },
    [handleInputChange],
  );

  const handleSelectChange = useCallback(
    (key: keyof Characteristic) => (value: CharacteristicType | RangeSign) => {
      handleInputChange(key, value);
    },
    [handleInputChange],
  );

  const handleDeleteCharItem = useCallback(() => {
    deleteChar(state.id);
  }, [deleteChar, state.id]);

  return (
    <div className={styles.wrapper}>
      <Input
        value={state.name}
        className={styles.nameInput}
        allowClear={clearIcon}
        placeholder="Название"
        onChange={handleTextInputChange('name')}
      />
      <Select
        value={state.type}
        className={styles.typeSelect}
        suffixIcon={<Vicon className="v-icon" />}
        placeholder="Тип"
        options={typeOptions}
        onChange={handleSelectChange('type')}
      />
      {state.type === CharacteristicType.TEXT && (
        <Input
          value={state.textValue ?? ''}
          className={styles.valueInput}
          allowClear={clearIcon}
          placeholder="Не указано"
          onChange={handleTextInputChange('textValue')}
        />
      )}
      {state.type === CharacteristicType.NUMERIC && (
        <Input
          type="number"
          value={state.numValue || ''}
          className={styles.valueInput}
          allowClear={clearIcon}
          placeholder="Не указано"
          onChange={handleNumberInputChange('numValue')}
        />
      )}
      {state.type === CharacteristicType.RANGE && (
        <Flex
          gap={10}
          align="center"
        >
          <Select
            value={state.leftRangeSign}
            className={styles.rangeSelect}
            suffixIcon={<Vicon className="v-icon" />}
            placeholder="От..."
            options={rangeOptions}
            onChange={handleSelectChange('leftRangeSign')}
          />
          <Input
            type="number"
            value={state.leftRange || ''}
            className={styles.valueInputShort}
            allowClear={clearIcon}
            placeholder="Значение"
            onChange={handleNumberInputChange('leftRange')}
          />
          <div>—</div>
          <Select
            value={state.rightRangeSign}
            className={styles.rangeSelect}
            suffixIcon={<Vicon className="v-icon" />}
            placeholder="До..."
            options={rangeOptions}
            onChange={handleSelectChange('rightRangeSign')}
          />
          <Input
            type="number"
            value={state.rightRange || ''}
            className={styles.valueInputShort}
            allowClear={clearIcon}
            placeholder="Значение"
            onChange={handleNumberInputChange('rightRange')}
          />
        </Flex>
      )}
      <DeleteIcon
        className={styles.deleteIcon}
        onClick={handleDeleteCharItem}
      />
    </div>
  );
});
CharsItem.displayName = 'CharsItem';
