import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { DebounceInputNumber, DebounceInputNumberValueType } from '@/components';
import { AutoComplete, Empty, Flex, Spin, Tooltip } from 'antd';
import { updateNmckRow, useGetKtru, useGetNmckById } from '@/api/nmckApi/nmckApi';
import { NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { KtruResult } from '@/api/okpdApi/okpdApiTypes';
import styles from './NkmiAutocomplete.module.scss';
import { InputNumberRef } from 'rc-input-number';

type Props = {
  id: string;
  row: NmckRow;
};

const renderOption = (option: KtruResult) => (
  <Flex align="center">
    <div>{`${option.nkmiId} (${option.code}) –`}&nbsp;</div>
    <Tooltip
      arrow={false}
      title={option.name}
      placement="right"
    >
      <div className={styles.autocompleteName}>{option.name}</div>
    </Tooltip>
  </Flex>
);

const notFoundContent = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

export const NkmiAutocomplete = memo(({ id, row }: Props) => {
  const [nkmiValue, setNkmiValue] = useState(row.nkmiId);
  const [searchString, setSearchString] = useState<number | undefined>(row.nkmiId);
  const inputRef = useRef<InputNumberRef>(null);

  const { mutate: refreshCalculation } = useGetNmckById(id);
  const { data, isLoading } = useGetKtru({ nkmiId: searchString });

  const options = useMemo(
    () =>
      data?.results.map((item) => ({
        value: item.code.toString(),
        data: item,
        label: renderOption(item),
      })) ?? [],
    [data?.results],
  );

  const handleUpdateRow = useCallback(
    async ({ nkmiId, code }: KtruResult) => {
      await updateNmckRow(id, { ...row, nkmiId, ktruCode: code, okpdCode: undefined });
      await refreshCalculation();
    },
    [id, refreshCalculation, row],
  );

  const onSelect = useCallback(
    (_: unknown, { data }: { data: KtruResult }) => {
      handleUpdateRow(data);
      inputRef.current?.blur();
      setNkmiValue(data.nkmiId);
    },
    [handleUpdateRow],
  );

  const handleInputChange = useCallback((value: DebounceInputNumberValueType) => {
    setSearchString(value ?? undefined);
  }, []);

  return (
    <AutoComplete
      options={options}
      popupMatchSelectWidth={options.length ? 500 : 250}
      value={nkmiValue}
      onSelect={onSelect}
      className={styles.autocompleteWrapper}
      notFoundContent={notFoundContent}
    >
      <DebounceInputNumber
        className={styles.input}
        handleValueChange={handleInputChange}
        suffix={isLoading ? <Spin size="small" /> : null}
        controls={false}
        ref={inputRef}
      />
    </AutoComplete>
  );
});

NkmiAutocomplete.displayName = 'NkmiAutocomplete';
