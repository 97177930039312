import { DirectContract } from '@/api/reestrDirectApi/reestrDirectApiTypes';

type DataForColumnsSettingsType = {
  title: string;
  dataIndex: Columns;
  key: string;
};
export type TotalRow = Partial<DirectContract>;

export enum Columns {
  ITEM_NAME = 'itemName',
  CONCLUSION_DATE = 'conclusionDate',
  EXEC_DATE = 'execDate',
  SUPPLIER = 'supplier',
  CONTRACT_NUMBER = 'contractNumber',
  PRICE = 'price',
  KBK = 'kbk',
  CAUSE = 'cause',
  COMMENT = 'comment',
  COPY = 'copy',
  EDIT = 'edit',
  DELETE = 'delete',
}

export const resultsTableColumns: DataForColumnsSettingsType[] = [
  {
    title: 'Предмет контракта',
    dataIndex: Columns.ITEM_NAME,
    key: Columns.ITEM_NAME,
  },
  {
    title: 'Дата заключения',
    dataIndex: Columns.CONCLUSION_DATE,
    key: Columns.CONCLUSION_DATE,
  },
  {
    title: 'Срок исполнения',
    dataIndex: Columns.EXEC_DATE,
    key: Columns.EXEC_DATE,
  },
  {
    title: 'Поставщик',
    dataIndex: Columns.SUPPLIER,
    key: Columns.SUPPLIER,
  },
  {
    title: '№ контракта',
    dataIndex: Columns.CONTRACT_NUMBER,
    key: Columns.CONTRACT_NUMBER,
  },
  {
    title: 'Цена, ₽',
    dataIndex: Columns.PRICE,
    key: Columns.PRICE,
  },
  {
    title: 'КБК',
    dataIndex: Columns.KBK,
    key: Columns.KBK,
  },
  {
    title: 'Основание заключения',
    dataIndex: Columns.CAUSE,
    key: Columns.CAUSE,
  },
  {
    title: 'Комментарий',
    dataIndex: Columns.COMMENT,
    key: Columns.COMMENT,
  },
  {
    title: '',
    dataIndex: Columns.COPY,
    key: Columns.COPY,
  },
  {
    title: '',
    dataIndex: Columns.EDIT,
    key: Columns.EDIT,
  },
  {
    title: '',
    dataIndex: Columns.DELETE,
    key: Columns.DELETE,
  },
];
