'use client';

import { memo } from 'react';
import { SmpReportDisplay } from '@/widgets/SmpReport/components';

export const SmpReportPage = memo(() => {
  return (
    <div>
      <SmpReportDisplay />
    </div>
  );
});

SmpReportPage.displayName = 'SmpReportPage';
