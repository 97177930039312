import { Spin } from 'antd';

import styles from './NmckGridContractsNotFound.module.scss';

type Props = {
  isContractsLoading: boolean;
  code?: string;
};
export const NmckGridContractsNotFound = ({ isContractsLoading, code }: Props) => {
  return (
    <div className={styles.contractsNotFound}>
      {isContractsLoading ? (
        <Spin className={styles.spin} />
      ) : (
        <div>
          {code ? (
            <>
              <p>Не найдено ни одной спецификации.</p>
              <p>Повторите поиск с другим названием, кодом ОКПД2 или выберите другие параметры.</p>
            </>
          ) : (
            <p>Выберите ОКПД / КТРУ, чтобы найти контракты</p>
          )}
        </div>
      )}
    </div>
  );
};
