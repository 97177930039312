import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Flex, Select, Spin } from 'antd';
import moment, { Moment } from 'moment';
import { debounce } from 'lodash';
import { SearchContext } from '@/widgets/Docs/context/context';
import { actions } from '@/widgets/Docs/context/actions';
import { DebounceInput, UiDatePicker } from '@/components';
import { useGetCustomers } from '@/api/docsApi/docsApi';
import { getSelectDataMap } from '@/widgets/Docs/utils/utils';
import { useSearchParams } from 'next/navigation';
import Vicon from '/public/icons/v.svg';
import DotsIcon from '/public/icons/cross.svg';
import styles from './Header.module.scss';
import cx from 'classnames';
import { RegionsTreeSelect } from '@/components/RegionsTreeSelect/RegionsTreeSelect';

type MomentDate = Moment | null;

const clearIcon = {
  clearIcon: <DotsIcon className={styles.inputIcon} />,
};

type HeaderProps = {
  reset: VoidFunction;
};

export const Header = memo(({ reset }: HeaderProps) => {
  const searchParams = useSearchParams();
  const code = searchParams.get('code');
  const { state, dispatch } = useContext(SearchContext);

  const [searchString, setSearchString] = useState('');
  const [inputValue, setInputValue] = useState(state.code);
  const [customersInput, setCustomersInput] = useState('');

  const { data: customers, isLoading: isCustomersLoading } = useGetCustomers(searchString);

  const handleOkpdInputChange = useCallback(
    (value: string) => {
      reset();
      setInputValue(value);
      dispatch(actions.setOKPD(value));
    },
    [dispatch, reset],
  );

  const [dateButtonsState, setDateButtonsState] = useState({
    isSixMonthDisabled: false,
    isOneYearDisabled: false,
  });

  const periodChange = useCallback(
    (period: number) => {
      const endDate = moment();
      const startDate = moment(endDate).subtract(period, 'months');
      reset();

      dispatch(actions.setStartDate(startDate.format('YYYY-MM-DD')));
      dispatch(actions.setEndDate(endDate.format('YYYY-MM-DD')));

      setDateButtonsState({
        isSixMonthDisabled: period === 6,
        isOneYearDisabled: period === 12,
      });
    },
    [dispatch, reset],
  );

  const handleHalfYearClick = useCallback(() => {
    periodChange(6);
  }, [periodChange]);

  const handleYearClick = useCallback(() => {
    periodChange(12);
  }, [periodChange]);

  useEffect(() => {
    if (!state.startDate || !state.endDate) {
      setDateButtonsState({
        isSixMonthDisabled: false,
        isOneYearDisabled: false,
      });
      return;
    }
  }, [state.startDate, state.endDate]);

  const handleSelectChange = useCallback(
    (value: number[]) => {
      reset();
      dispatch(actions.setCustomers(value));
    },
    [dispatch, reset],
  );

  const handleTreeChange = useCallback(
    (value: number[]) => {
      reset();
      dispatch(actions.setRegion(value));
    },
    [dispatch, reset],
  );

  const debouncedSetSearchString = useMemo(
    () =>
      debounce((setSearchString, value) => {
        setSearchString(value);
      }, 500),
    [],
  );

  const handleSelectSearchChange = useCallback(
    (value: string) => {
      setCustomersInput(value);
      debouncedSetSearchString(setSearchString, value);
    },
    [debouncedSetSearchString],
  );

  const customersSelectMap = useMemo(() => customers && getSelectDataMap(customers), [customers]);

  const resetButtonState = useCallback(() => {
    setDateButtonsState({
      isSixMonthDisabled: false,
      isOneYearDisabled: false,
    });
  }, []);

  const handleDateFromChange = useCallback(
    (value: MomentDate) => {
      reset();

      dispatch(actions.setStartDate(value ? value.format('YYYY-MM-DD') : ''));

      resetButtonState();
    },
    [dispatch, reset, resetButtonState],
  );

  const handleDateToChange = useCallback(
    (value: MomentDate) => {
      reset();

      dispatch(actions.setEndDate(value ? value.format('YYYY-MM-DD') : ''));

      resetButtonState();
    },
    [dispatch, reset, resetButtonState],
  );

  useEffect(() => {
    if (code) {
      reset();
      dispatch(actions.setOKPD(code));
      setInputValue(code);
    }
  }, [code, dispatch, reset]);

  return (
    <div className={styles.wrapper}>
      <Flex
        gap={32}
        className={styles.header}
      >
        <div className={styles.title}>Поиск документации</div>
        <DebounceInput
          value={inputValue}
          handleValueChange={handleOkpdInputChange}
          className={cx('input-line', styles.input)}
          variant="borderless"
          placeholder="ОКПД2/КТРУ"
          allowClear={clearIcon}
        />
      </Flex>
      <Flex gap={32}>
        <Flex
          gap={8}
          className={styles.dates}
        >
          <p className={styles.dateText}> Дата публикации: </p>
          <UiDatePicker
            placeholder="Дата от..."
            className={styles.date}
            value={state.startDate ? moment(state.startDate, 'YYYY-MM-DD') : null}
            onChange={handleDateFromChange}
          />
          <p className={styles.dateText}> — </p>
          <UiDatePicker
            placeholder="Дата до..."
            className={styles.date}
            value={state.endDate ? moment(state.endDate, 'YYYY-MM-DD') : null}
            onChange={handleDateToChange}
          />
          <div className={styles.choosePeriod}>
            <Button
              disabled={dateButtonsState.isSixMonthDisabled}
              type="text"
              onClick={handleHalfYearClick}
            >
              <div
                className={
                  dateButtonsState.isSixMonthDisabled ? styles.periodDisabled : styles.period
                }
              >
                Полгода
              </div>
            </Button>
            <Button
              disabled={dateButtonsState.isOneYearDisabled}
              type="text"
              onClick={handleYearClick}
            >
              <div
                className={
                  dateButtonsState.isOneYearDisabled ? styles.periodDisabled : styles.period
                }
              >
                Год
              </div>
            </Button>
          </div>
        </Flex>
        <RegionsTreeSelect
          placeholder={<p className={styles.treePlaceholder}>Все регионы</p>}
          className={styles.treeSelect}
          onRegionsChange={handleTreeChange}
        />
        <Select
          options={customersSelectMap}
          placeholder={<p className={styles.treePlaceholder}>Все заказчики</p>}
          searchValue={customersInput}
          loading={isCustomersLoading}
          notFoundContent={
            isCustomersLoading ? (
              <div className={styles.spin}>
                <Spin size="large" />
              </div>
            ) : undefined
          }
          mode="multiple"
          className={styles.treeSelect}
          filterOption={false}
          suffixIcon={<Vicon className="v-icon" />}
          popupMatchSelectWidth={800}
          placement="bottomLeft"
          maxTagCount="responsive"
          onChange={handleSelectChange}
          onSearch={handleSelectSearchChange}
        />
      </Flex>
    </div>
  );
});

Header.displayName = 'Header';
