import { TokenBasedPaginationStateWithPageSize } from '@/api/commonApi/commonApiTypes';
import { Popover, PopoverProps, TableProps, Typography } from 'antd';
import { ContractItem } from '@/api/nmckApi/nmckApiTypes';
import { ColumnType } from 'antd/es/table/interface';
import { Columns } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/constants';
import styles from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/NmckGridContractsTable.module.scss';
import { Characteristic } from '@/components/NmckGrid/NmckGridContracts/components/types';
import { formatNumber } from '@/utils/formatNumber';
import Link from 'next/link';
import { formatDate } from '@/utils';
import Copy from 'public/icons/copy.svg';

export const getScroll = (
  pagination: TokenBasedPaginationStateWithPageSize,
): TableProps['scroll'] => {
  return {
    x: 'max-content',
    y: pagination.pageSize === -1 ? 400 : undefined,
    scrollToFirstRowOnChange: true,
  };
};

const getParagraphEllipsis = (tooltip?: string) => ({
  rows: 2,
  tooltip,
});

const copyable = {
  tooltips: ['Нажмите чтобы скопировать', 'Скопировано'],
  icon: [
    <Copy
      key="copy-icon"
      className={styles.copyIcon}
    />,
  ],
};

const getCopyableWithText = (text: string) => ({ ...copyable, text });

export const rowClassName: TableProps['rowClassName'] = () => {
  return styles.row;
};

export const resultsTableColumnsWithRender = (
  priceIncreasingFactor: boolean,
  selectedColumns: TableProps<ContractItem>['columns'],
  showAll: boolean[],
  setShowAll: React.Dispatch<React.SetStateAction<boolean[]>>,
): TableProps<ContractItem>['columns'] => {
  return selectedColumns?.map((column: ColumnType<ContractItem>) => {
    if (column.dataIndex === Columns.NAME) {
      return {
        ...column,
        render: (_: unknown, { name }: ContractItem) => {
          return (
            <Typography.Paragraph
              ellipsis={getParagraphEllipsis(name)}
              className={styles.name}
            >
              <div>{name}</div>
            </Typography.Paragraph>
          );
        },
      };
    }
    if (column.dataIndex === Columns.OKPD2) {
      return {
        ...column,
        render: (_: unknown, record: ContractItem) => {
          const code = record.okpd?.code || record.ktru?.code;
          return <div className={styles.okpd2}> {code}</div>;
        },
      };
    }
    if (column.dataIndex === Columns.CHARACTERISTICS) {
      return {
        ...column,
        render: (_: unknown, record: ContractItem, index: number) => {
          const characteristicsToShow = showAll[index]
            ? record.characteristics
            : record.characteristics.slice(0, 1);
          return (
            <div className={styles.characteristics}>
              {characteristicsToShow.length
                ? characteristicsToShow.map((item: Characteristic, index) => (
                    <div
                      key={index}
                      className={styles.characteristicItem}
                    >
                      <div className={styles.characteristicName}>{item.name}:</div>
                      <Typography.Paragraph
                        ellipsis={getParagraphEllipsis(item.value)}
                        className={styles.characteristicValue}
                      >
                        {item.value}
                      </Typography.Paragraph>
                    </div>
                  ))
                : '—'}
              {record.characteristics.length > 1 && (
                <div
                  className={styles.showAllCharacteristics}
                  onClick={() => {
                    const newShowAllArray = [...showAll];
                    newShowAllArray[index] = !newShowAllArray[index];
                    setShowAll(newShowAllArray);
                  }}
                >
                  {showAll[index] ? 'Скрыть' : 'Ещё...'}
                </div>
              )}
            </div>
          );
        },
      };
    }
    if (column.dataIndex === Columns.PRICE) {
      return {
        ...column,
        render: (_: unknown, record: ContractItem) => {
          return (
            <div className={styles.price}>
              {priceIncreasingFactor
                ? formatNumber(record.priceWithAppliedKoeff)
                : formatNumber(record.unitPrice)}
              {priceIncreasingFactor && (
                <Popover content={coefficientPopOverContent}>
                  <div className={styles.coefficients}>
                    <div className={styles.maxIncreasePriceRatioCoeff}>
                      {record.maxIncreasePriceRatioCoeff}%
                    </div>

                    <div className={styles.maxIncreasePricePeriodRatioCoeff}>
                      {record.maxIncreasePricePeriodRatioCoeff}%
                    </div>
                  </div>
                </Popover>
              )}
            </div>
          );
        },
      };
    }
    if (column.dataIndex === Columns.COUNT) {
      return {
        ...column,
        render: (_: unknown, record: ContractItem) => {
          return <div className={styles.count}>{record.count}</div>;
        },
      };
    }
    if (column.dataIndex === Columns.SUPPLIER) {
      return {
        ...column,
        render: (_: unknown, record: ContractItem) => {
          return record.contract.supplier?.name ? (
            <Typography.Paragraph
              ellipsis={getParagraphEllipsis(record.contract.supplier.name)}
              className={styles.supplier}
            >
              <div>{record.contract.supplier.name}</div>
            </Typography.Paragraph>
          ) : (
            '—'
          );
        },
      };
    }
    if (column.dataIndex === Columns.REGION_SUPPLIER) {
      return {
        ...column,
        render: (_: unknown, record: ContractItem) => {
          return (
            <div className={styles.region}>{record.contract.supplier?.region?.name ?? '—'}</div>
          );
        },
      };
    }
    if (column.dataIndex === Columns.REGION_CONSUMER) {
      return {
        ...column,
        render: (_: unknown, record: ContractItem) => {
          return (
            <div className={styles.region}>{record.contract.consumer?.region?.name ?? '—'}</div>
          );
        },
      };
    }
    if (column.dataIndex === Columns.EMAIL) {
      return {
        ...column,
        render: (_: unknown, record: ContractItem) => {
          return (
            <Link
              href={`mailto:${record.contract.supplier?.email}`}
              className={styles.email}
            >
              {record.contract.supplier?.email}
            </Link>
          );
        },
      };
    }
    if (column.dataIndex === Columns.PHONE) {
      return {
        ...column,
        render: (_: unknown, record: ContractItem) => {
          return (
            <Link
              href={`tel:${record.contract.supplier?.phoneNumber}`}
              className={styles.phone}
            >
              {record.contract.supplier?.phoneNumber}
            </Link>
          );
        },
      };
    }
    if (column.dataIndex === Columns.REESTR_NUMBER) {
      return {
        ...column,
        render: (_: unknown, { contract: { reestrNumber } }: ContractItem) => {
          return (
            <Typography.Paragraph
              className={styles.copyLink}
              copyable={getCopyableWithText(reestrNumber)}
            >
              <Link
                href={`https://zakupki.gov.ru/epz/contract/contractCard/common-info.html?reestrNumber=${reestrNumber}`}
                target="_blank"
                className={styles.reestrNumber}
              >
                {reestrNumber}
              </Link>
            </Typography.Paragraph>
          );
        },
      };
    }
    if (column.dataIndex === Columns.COUNTRY) {
      return {
        ...column,
        render: (_: unknown, record: ContractItem) => {
          return <div className={styles.country}>{record.contract.supplier?.country || '—'}</div>;
        },
      };
    }
    if (column.dataIndex === Columns.DATE) {
      return {
        ...column,
        render: (_: unknown, record: ContractItem) => {
          return <div>{formatDate(record.contract.conclusionDate)}</div>;
        },
      };
    }
    if (column.dataIndex === Columns.METHOD) {
      return {
        ...column,
        width: 200,
        render: (_: unknown, record: ContractItem) => {
          return record.contract.purchaseMethod?.name ? (
            <Typography.Paragraph
              ellipsis={getParagraphEllipsis(record.contract.purchaseMethod.name)}
            >
              <div>{record.contract.purchaseMethod.name}</div>
            </Typography.Paragraph>
          ) : (
            '—'
          );
        },
      };
    }
    if (column.dataIndex === Columns.SMP) {
      return {
        ...column,
        render: (_: unknown, record: ContractItem) => {
          return <div className={styles.smp}> {record.smp ? 'Да' : 'Нет'}</div>;
        },
      };
    }
    return {
      ...column,
    };
  });
};

export const coefficientPopOverContent: PopoverProps['content'] = (
  <div className={styles.coefficientPopOverContent}>
    <div>
      Коэф. по способу (максимальный размер): для аукционов - 13% для конкурсов - 10% для запросов
      предложений или запросов котировок - 17%
    </div>
    <div>
      Коэф. по периоду рассчитан для цен старше 6 месяцев в соответствии с рекомендациями п.3.18
      Приказа №567
    </div>
  </div>
);

export const contentPopOver: PopoverProps['content'] = (
  <div className={styles.contentPopOver}>
    Заказчик имеет право применить повышающие коэффициенты к ценам контрактов из ЕИС (
    <Link
      href="https://normativ.kontur.ru/document?moduleId=1&documentId=220720&rangeId=6001267"
      target="_blank"
    >
      рекомендации, утвержденные п.3.16, п.3.18 Приказа №567
    </Link>
    )
  </div>
);
