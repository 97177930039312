import { OptionWithNumberValue } from '@/types/types';
import { ColumnsType } from 'antd/lib/table';

export enum Columns {
  NAME = 'name',
  OKPD2 = 'okpd2',
  CHARACTERISTICS = 'characteristics',
  PRICE = 'price',
  UNIT = 'unitMeasure',
  NDS = 'nds',
  COUNT = 'count',
  SUPPLIER = 'supplier',
  REGION_SUPPLIER = 'regionSupplier',
  REGION_CONSUMER = 'regionConsumer',
  EMAIL = 'email',
  PHONE = 'phone',
  REESTR_NUMBER = 'reestrNumber',
  COUNTRY = 'country',
  DATE = 'date',
  METHOD = 'method',
  SMP = 'smp',
}

export const resultsTableColumns: ColumnsType = [
  {
    title: 'Наименование',
    dataIndex: Columns.NAME,
    key: 'name',
  },
  {
    title: 'ОКПД2/КТРУ',
    dataIndex: Columns.OKPD2,
    key: 'okpd2',
  },
  {
    title: 'Характеристики',
    dataIndex: Columns.CHARACTERISTICS,
    key: 'characteristics',
  },
  {
    title: 'Цена, ₽',
    dataIndex: Columns.PRICE,
    key: 'price',
    align: 'right',
  },
  {
    title: 'Ед. изм.',
    dataIndex: Columns.UNIT,
    key: 'unitMeasure',
  },
  {
    title: 'НДС, %',
    dataIndex: Columns.NDS,
    key: 'nds',
    align: 'right',
  },
  {
    title: 'Кол-во',
    dataIndex: Columns.COUNT,
    key: 'count',
    align: 'right',
  },
  {
    title: 'Поставщик',
    dataIndex: Columns.SUPPLIER,
    key: 'supplier',
  },
  {
    title: 'Регион П',
    dataIndex: Columns.REGION_SUPPLIER,
    key: 'regionSupplier',
  },
  {
    title: 'Регион З',
    dataIndex: Columns.REGION_CONSUMER,
    key: 'regionConsumer',
  },
  {
    title: 'E-mail П',
    dataIndex: Columns.EMAIL,
    key: 'email',
  },
  {
    title: 'Телефон П',
    dataIndex: Columns.PHONE,
    key: 'phone',
  },
  {
    title: 'Реестровый №',
    dataIndex: Columns.REESTR_NUMBER,
    key: 'reestrNumber',
  },
  {
    title: 'Страна',
    dataIndex: Columns.COUNTRY,
    key: 'country',
  },
  {
    title: 'Дата заключения',
    dataIndex: Columns.DATE,
    key: 'conclusionDate',
  },
  {
    title: 'Способ закупки',
    dataIndex: Columns.METHOD,
    key: 'purchaseMethodName',
  },
  {
    title: 'СМП',
    dataIndex: Columns.SMP,
    key: 'smp',
  },
];

export const showOnPageOptions: OptionWithNumberValue[] = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: -1,
    label: 'Бесконечный список',
  },
];
