import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { ConfigProviderProps, TableColumnsType, TableProps } from 'antd';
import { TableColumnsSettingsRow } from '@/components/Table/TableColumnsSettings/TableColumnsSettingsRow/TableColumnsSettingsRow';
import { DndContextProps } from '@dnd-kit/core';

export const modifiers: DndContextProps['modifiers'] = [restrictToVerticalAxis];

export const scroll: TableProps['scroll'] = { y: 400 };

export const theme: ConfigProviderProps['theme'] = {
  components: {
    Table: {
      headerBg: '#1a6fe0',
      headerColor: '#fff',
    },
  },
};

export const components: TableProps['components'] = {
  body: {
    row: TableColumnsSettingsRow,
  },
};

export const columns: TableColumnsType = [
  {
    title: 'Название колонки',
    dataIndex: 'title',
    key: 'title',
    width: 200,
  },
];
