import React, { memo, useCallback, useMemo } from 'react';

import { Popover } from 'antd';
import DotsIcon from '/public/icons/dots.svg';

import { NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { deleteNmckRow, postCopyNmckRow, useGetNmckById } from '@/api/nmckApi/nmckApi';

import styles from './More.module.scss';
import { MorePopUpContent } from '@/components/NmckGrid/MorePopUpContent/MorePopUpContent';

type Props = {
  record: NmckRow;
  id: string;
};

export const More = memo(({ record, id }: Props) => {
  const { mutate: refreshCalculation, data } = useGetNmckById(id);

  const rows = useMemo(() => data?.rows ?? [], [data]);

  const handleDeleteRow = useCallback(async () => {
    await deleteNmckRow(id, record.id);
    await refreshCalculation();
  }, [id, record.id, refreshCalculation]);

  const handleCopyRow = useCallback(async () => {
    await postCopyNmckRow(id, record.id);
    await refreshCalculation();
  }, [id, record.id, refreshCalculation]);

  return (
    <div className={styles.more}>
      <Popover
        content={
          <MorePopUpContent
            rows={rows}
            record={record}
            handleCopyRow={handleCopyRow}
            handleDeleteRow={handleDeleteRow}
          />
        }
        placement="leftTop"
        className={styles.morePopUp}
      >
        <DotsIcon className={styles.moreIcon} />
      </Popover>
    </div>
  );
});
More.displayName = 'More';
