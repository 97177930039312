import { memo, useMemo } from 'react';
import { KtruDetail } from '@/api/okpdApi/okpdApiTypes';
import styles from './KtruDetailTabsGeneralInfo.module.scss';
import { Flex } from 'antd';
import { formatDate } from '@/utils';
import { OkpdLink } from '@/components/OkpdFlowModal/OkpdLink/OkpdLink';
import { pageRoutes } from '@/app/pageRoutes';
import {
  OkpdKtruPageTypes,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import Link from 'next/link';
import ExportDiagonalIcon from '/public/icons/exportDiagonal.svg';

type Props = {
  ktru: KtruDetail;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const KtruDetailTabsGeneralInfo = memo(({ ktru, setModalPageParams }: Props) => {
  const additionalCharacteristicsText = (() => {
    if (ktru.additionalCharacteristicsRestricted) {
      return ktru.additionalCharacteristicsRestrictedComment || 'Не разрешено';
    }

    return 'Разрешено';
  })();

  const codeToOkpdClickHandler = useMemo(
    () =>
      [ktru.okpd, ...ktru.additionalOkpds].reduce(
        (acc, { code }) => ({
          ...acc,
          [code]: () =>
            setModalPageParams &&
            setModalPageParams((prev) => ({
              ...prev,
              page: OkpdKtruPageTypes.OKPD,
              code,
              searchString: code,
            })),
        }),
        {} as Record<string, () => void>,
      ),
    [ktru.additionalOkpds, ktru.okpd, setModalPageParams],
  );

  return (
    <Flex
      className={styles.list}
      vertical
      gap={8}
    >
      <Flex className={styles.row}>
        <div className={styles.label}>Обязательна к применению с:</div>
        <div className={styles.value}>{formatDate(ktru.mandatorySince)}</div>
      </Flex>

      <Flex className={styles.row}>
        <div className={styles.label}>ОКПД2:</div>
        <div className={styles.value}>
          <div>
            <OkpdLink
              isLink={!setModalPageParams}
              href={`${pageRoutes.okpd2}/${ktru.okpd.code}`}
              onClick={codeToOkpdClickHandler[ktru.okpd.code]}
            >
              {ktru.okpd.code}
            </OkpdLink>{' '}
            <span>— {ktru.okpd.name}</span>
          </div>
        </div>
      </Flex>

      {/*TODO: Скрываем поле до решения задачи SARK-411 */}
      {/*<Flex className={styles.row}>*/}
      {/*  <div className={styles.label}>Описание:</div>*/}
      {/*  <div className={styles.value}>{ktru.additionalCharacteristicsRestrictedComment ?? '—'}</div>*/}
      {/*</Flex>*/}

      <Flex className={styles.row}>
        <div className={styles.label}>Укрупненная:</div>
        <div className={styles.value}>{ktru.isTemplate ? 'Укрупнённая' : 'Неукрупнённая'}</div>
      </Flex>

      <Flex className={styles.row}>
        <div className={styles.label}>Единица измерения:</div>
        <div className={styles.value}>
          {ktru.okeis.length ? ktru.okeis.map(({ name }) => name).join('; ') : '—'}
        </div>
      </Flex>

      <Flex className={styles.row}>
        <div className={styles.label}>Указание доп. характеристик:</div>
        <div className={styles.value}>{additionalCharacteristicsText}</div>
      </Flex>

      <Flex className={styles.row}>
        <div className={styles.label}>Классификации, НКМИ:</div>
        <div className={styles.value}>
          {ktru.nkmis.length
            ? ktru.nkmis.map(({ code, name }, i) => (
                <div key={code}>
                  {i + 1}. {code} — {name}
                </div>
              ))
            : '—'}
        </div>
      </Flex>

      {!!ktru.reglaments.length && (
        <Flex className={styles.row}>
          <div className={styles.label}>Регламенты и стандарты:</div>
          <div className={styles.value}>
            {ktru.reglaments.map((reglament, i) => (
              <div key={reglament}>
                {i + 1}. {reglament}
              </div>
            ))}
          </div>
        </Flex>
      )}

      <Flex className={styles.row}>
        <div className={styles.label}>Справочно ОКПД2:</div>
        <div className={styles.value}>
          {ktru.additionalOkpds.length
            ? ktru.additionalOkpds.map(({ code, name }) => (
                <div key={code}>
                  <OkpdLink
                    isLink={!setModalPageParams}
                    href={`${pageRoutes.okpd2}/${code}`}
                    onClick={codeToOkpdClickHandler[code]}
                  >
                    {code}
                  </OkpdLink>{' '}
                  <span>— {name}</span>
                </div>
              ))
            : '—'}
        </div>
      </Flex>

      <Flex className={styles.row}>
        <div className={styles.label}>Типовые контракты:</div>
        <div className={styles.value}>
          {ktru.standardContracts.length ? (
            ktru.standardContracts.map(({ first, second }, i) => (
              <div key={first}>
                {i + 1}.{' '}
                <Link
                  href={second}
                  target="_blank"
                  className={styles.standardContractLink}
                >
                  {first} <ExportDiagonalIcon />
                </Link>
              </div>
            ))
          ) : (
            <div>—</div>
          )}
        </div>
      </Flex>
    </Flex>
  );
});

KtruDetailTabsGeneralInfo.displayName = 'KtruDetailTabsGeneralInfo';
