import { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, List, Popover, Typography } from 'antd';
import cx from 'classnames';
import moment from 'moment';

import Calendar from '/public/icons/calendar.svg';
import Copy from '/public/icons/copy.svg';
import CustomWarning from '/public/icons/customWarning.svg';

import { UiDatePicker } from '@/components';
import { postDeadlineCalculation } from '@/api/skpApi/skpApi';
import { DeadlineCalculation, DeadlineCalculationResponse, Stage } from '@/api/skpApi/skpApiTypes';
import { HighlightedDateState } from '../../Procedures';

import styles from './ProceduresItem.module.scss';
import { formatDate } from '@/utils';
import { dateFormat } from '@/constants';

const copyable = {
  tooltips: ['Нажмите чтобы скопировать', 'Скопировано'],
  icon: [
    <Copy
      key="copy-icon"
      className={styles.copyIcon}
    />,
  ],
};
type ProceduresItemProps = {
  stage: Stage;
  currentCalculation: DeadlineCalculation;
  setDeadlineCalculationData: Dispatch<SetStateAction<DeadlineCalculationResponse>>;
  highlightedDateState: HighlightedDateState;
  setHighlightedDateState: Dispatch<SetStateAction<HighlightedDateState>>;
  setVisibleResetCross: Dispatch<SetStateAction<boolean>>;
};

const getPopoverProps = (
  highlightedDateState: HighlightedDateState,
  currentCalculation: DeadlineCalculation,
  stage: Stage,
) => {
  const highlightedFrom = highlightedDateState.highlightedFrom;
  const highlightedDateId = highlightedDateState.highlightedDateId;
  const fromOrTo = highlightedDateState.isFrom ? 'dateFrom' : 'dateTo';
  const title =
    highlightedFrom !== undefined
      ? currentCalculation.stages[highlightedFrom]?.[`${fromOrTo}`].impactingStageMessage
      : '';

  const open =
    highlightedFrom !== undefined &&
    !!currentCalculation.stages[highlightedFrom]?.[`${fromOrTo}`].impactingStageMessage &&
    highlightedDateId === stage.id;

  return { title, open };
};

export const ProceduresItem = memo(
  ({
    stage,
    currentCalculation,
    setDeadlineCalculationData,
    highlightedDateState,
    setHighlightedDateState,
    setVisibleResetCross,
  }: ProceduresItemProps) => {
    const [date, setDate] = useState(stage.date.date ? formatDate(stage.date.date) : null);

    useEffect(() => {
      setDate(stage.date.date ? formatDate(stage.date.date) : null);
    }, [stage]);

    const handleDateChange = useCallback(
      async (newDate: moment.Moment | null) => {
        if (newDate) {
          const formattedDateString = formatDate(newDate, 'YYYY-MM-DD');
          setDate(formatDate(newDate));

          const postResponse: DeadlineCalculation = await postDeadlineCalculation({
            changedStage: { ...stage, date: { date: formattedDateString } },
            currentCalculation,
          });

          setDeadlineCalculationData((prevData) =>
            prevData.map((item) => (item.name === postResponse.name ? postResponse : item)),
          );

          setVisibleResetCross(true);
        }
      },
      [stage, currentCalculation, setDate, setDeadlineCalculationData, setVisibleResetCross],
    );

    const { title, open } = useMemo(
      () => getPopoverProps(highlightedDateState, currentCalculation, stage),
      [currentCalculation, highlightedDateState, stage],
    );

    const handleMouseEnterFrom = useCallback(
      () =>
        setHighlightedDateState({
          highlightedDateId: stage.dateFrom.impactingStageId,
          highlightedFrom: stage.id,
          isFrom: true,
        }),
      [setHighlightedDateState, stage.dateFrom.impactingStageId, stage.id],
    );

    const handleMouseLeave = useCallback(
      () =>
        setHighlightedDateState({
          highlightedDateId: undefined,
          highlightedFrom: undefined,
          isFrom: false,
        }),
      [setHighlightedDateState],
    );

    const handleMouseEnterTo = useCallback(
      () =>
        setHighlightedDateState({
          highlightedDateId: stage.dateTo.impactingStageId,
          highlightedFrom: stage.id,
          isFrom: false,
        }),
      [setHighlightedDateState, stage.dateTo.impactingStageId, stage.id],
    );

    return (
      <List.Item className={styles.listItem}>
        <div className={styles.grid}>
          <Typography.Text
            copyable={copyable}
            className={styles.stage}
          >
            {stage.name}
          </Typography.Text>
          <div>
            {stage.date.isEditable && (
              <Popover
                title={title}
                open={open}
                placement="left"
              >
                <UiDatePicker
                  allowClear={stage.date.canBeCleared}
                  minDate={moment(stage.dateFrom.date)}
                  maxDate={moment(stage.dateTo.date)}
                  onChange={handleDateChange}
                  value={date ? moment(date, dateFormat.frontFormat) : null}
                  className={styles.datePicker}
                  suffixIcon={<Calendar />}
                  placeholder="Указать дату"
                />
              </Popover>
            )}
          </div>
          <div className={cx(styles.dates, { [styles.error]: stage.errorFact })}>
            <div
              className={cx(styles.date, {
                [styles.dateHidden]: !stage.dateFrom.date,
              })}
              onMouseEnter={handleMouseEnterFrom}
              onMouseLeave={handleMouseLeave}
            >
              с {formatDate(stage.dateFrom.date || '')}
            </div>
            <Popover
              title={title}
              open={open && !stage.date.isEditable}
              placement="left"
            >
              <div
                className={cx(styles.date, {
                  [styles.dateHidden]: !stage.dateTo.date,
                })}
                onMouseEnter={handleMouseEnterTo}
                onMouseLeave={handleMouseLeave}
              >
                по {formatDate(stage.dateTo.date || '')}
              </div>
            </Popover>
          </div>
          <Flex
            gap={8}
            className={cx(styles.law, { [styles.error]: stage.errorFact })}
          >
            {stage.errorFact && <CustomWarning className={styles.warning} />}
            {stage.errorFact ? stage.errorText : stage.law}
          </Flex>
        </div>
      </List.Item>
    );
  },
);

ProceduresItem.displayName = 'ProceduresItem';
