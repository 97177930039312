import { TokenBasedPageableResponse } from '@/api/commonApi/commonApiTypes';
import { DependencyList, useEffect, useState } from 'react';
import { SWRResponse } from 'swr';

type Props<TEntity, TResponse extends TokenBasedPageableResponse<TEntity>> = {
  response: SWRResponse<TResponse>;
  resetDeps: DependencyList;
};

export const useTokenBasedDataList = <
  TEntity,
  TResponse extends TokenBasedPageableResponse<TEntity>,
>({
  response,
  resetDeps,
}: Props<TEntity, TResponse>) => {
  const [list, setList] = useState<TEntity[]>(() => []);

  const { data: { results, token = null, hasMoreResults = false } = {}, isLoading } = response;

  useEffect(() => {
    setList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, resetDeps);

  useEffect(() => {
    if (results) {
      setList((prev) => [...prev, ...results]);
    }
  }, [results]);

  return {
    list,
    token,
    hasMoreResults,
    isLoading,
    originalData: response.data,
  };
};
