import { memo } from 'react';
import { List } from 'antd';
import { DeadlineCalculationContracts, Step } from '@/api/skpApi/skpApiTypes';
import { dateFormat } from '@/constants';
import { formatDate } from '@/utils';
import { StageItem } from '../StageItem/StageItem';
import styles from './Stage.module.scss';

type StageProps = {
  steps: Step[];
  executionFinish: string;
  index: number;
  stage: DeadlineCalculationContracts;
};

export const Stage = memo(({ steps, executionFinish, index, stage }: StageProps) => {
  return (
    <div className={styles.stage}>
      <div className={styles.title}>
        <h3 className={styles.stageName}>Этап {index}</h3>
        <div className={styles.endDate}>
          Дата окончания исполнения контракта (этапа контракта):{' '}
          {formatDate(executionFinish, dateFormat.frontFormat)}
        </div>
      </div>
      <div className={styles.grid}>
        <div>Наименование</div>
        <div>Норма закона</div>
        <div>Дни</div>
        <div>Сроки</div>
        <div>Дата</div>
      </div>
      <List itemLayout="vertical">
        {steps.map((step) => (
          <StageItem
            key={step.id}
            step={step}
            stage={stage}
          />
        ))}
      </List>
    </div>
  );
});

Stage.displayName = 'Stage';
