import { memo, useEffect, useState } from 'react';
import {
  updatePurchaseCompatibilityCell,
  useGetCurrentPurchaseCompatibility,
} from '@/api/planningCurrentApi/planningCurrentApi';
import { useParams } from 'next/navigation';
import { CompatibilityTable } from '@/components/Compatibility/CompatibilityTable/CompatibilityTable';
import { Flex } from 'antd';
import { CompatibilityFilter } from '@/components/Compatibility/CompatibilityFilter/CompatibilityFilter';
import { useCompatibility } from '@/components/Compatibility/useCompatibility';
import { NmckCompatibilityByIdResponse } from '@/api/nmckApi/nmckApiTypes';

export const CompatibilityTab = memo(() => {
  const params = useParams();
  const id = params.id as string;
  const [compatibility, setCompatibility] = useState<NmckCompatibilityByIdResponse>();
  const {
    rows,
    rawRows,
    header,
    activeTab,
    showEmptyRows,
    isNationalProjects,
    setActiveTab,
    setShowEmptyRows,
    setIsNationalProjects,
  } = useCompatibility({ data: compatibility });

  const { data, isLoading, mutate } = useGetCurrentPurchaseCompatibility(
    id,
    !showEmptyRows,
    isNationalProjects,
  );

  useEffect(() => {
    setCompatibility(data);
  }, [data]);

  return (
    <div>
      <Flex gap={80}>
        <CompatibilityFilter
          activeKey={activeTab}
          setActiveKey={setActiveTab}
          rows={rawRows}
          isNationalProjects={isNationalProjects}
          showEmptyRows={showEmptyRows}
          setIsNationalProjects={setIsNationalProjects}
          setShowEmptyRows={setShowEmptyRows}
        />
      </Flex>
      <CompatibilityTable
        id={id}
        rows={rows}
        header={header}
        isLoading={isLoading}
        refreshTableData={mutate}
        updateCell={updatePurchaseCompatibilityCell}
      />
    </div>
  );
});

CompatibilityTab.displayName = 'CompatibilityTab';
