import { Actions, ActionType } from './actions';
import { TokenBasedPaginationStateWithPageSize } from '@/api/commonApi/commonApiTypes';

export const nmckGridReducer = (
  state: TokenBasedPaginationStateWithPageSize,
  action: Actions,
): TokenBasedPaginationStateWithPageSize => {
  switch (action.type) {
    case ActionType.PAGE_SIZE_UPDATE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case ActionType.TOKEN_UPDATE:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};
