import { DescriptionTableDataSource } from '@/widgets/PlanningCurrent/components/DescriptionTab/types';
import { PurchaseByIdResponse } from '@/api/planningCurrentApi/planningCurrentApiTypes';

export const descriptionTableDataSource: DescriptionTableDataSource[] = [
  {
    name: 'Номер заявки',
    key: 'number',
    fieldType: 'INPUT_NUMBER',
  },
  {
    name: 'Статус заявки',
    key: 'status',
    fieldType: 'INPUT',
  },
  {
    name: 'Дата создания',
    key: 'created',
    fieldType: 'DATE_PICKER',
  },
  {
    name: 'Источник финансирования',
    key: 'financeSource',
    fieldType: 'INPUT',
  },
  {
    name: 'Наименование подразделения',
    key: 'divisionName',
    fieldType: 'INPUT',
  },
  {
    name: 'Наименование товара, работы, услуги',
    key: 'name',
    fieldType: 'INPUT',
  },
  {
    name: 'Исполнитель',
    key: 'responsible',
    fieldType: 'INPUT',
  },
  {
    name: 'Сумма по заявке, ₽',
    key: 'amount',
    fieldType: 'INPUT_NUMBER',
  },
  {
    name: 'Срок поставки',
    key: 'supplyDate',
    fieldType: 'DATE_PICKER',
  },
  {
    name: 'Примечание',
    key: 'comment',
    fieldType: 'INPUT',
  },
];

export const DESCRIPTION_TABLE_DATE_PICKER_FIELDS: (keyof PurchaseByIdResponse)[] =
  descriptionTableDataSource
    .filter(({ fieldType }) => fieldType === 'DATE_PICKER')
    .map(({ key }) => key);
