import { memo, useCallback, useEffect, useState } from 'react';
import { Flex } from 'antd';
import cx from 'classnames';
import Link from 'next/link';

import { Doc } from '@/api/docsApi/docsApiTypes';

import ExportIcon from '/public/icons/export.svg';

import styles from './DocsRow.module.scss';

type DocsRowProps = {
  items: Doc[];
};

export const DocsRow = memo(({ items }: DocsRowProps) => {
  const [showMore, setShowMore] = useState(items.length < 1);

  useEffect(() => {
    setShowMore(items.length < 1);
  }, [items]);

  const handleClick = useCallback(() => {
    setShowMore(!showMore);
  }, [showMore]);

  return (
    <>
      {items.map((item, id) => {
        return (
          <Link
            key={id}
            href={item.documentationLink}
            className={cx(styles.documentationText, {
              [styles.documentationTextHidden]: id > 0 && !showMore,
            })}
          >
            <Flex
              className={styles.documentationRow}
              gap={8}
            >
              <ExportIcon className={styles.exportIcon} />
              {item.documentationName}
            </Flex>
          </Link>
        );
      })}
      <p
        className={styles.showMore}
        onClick={handleClick}
      >
        {!showMore ? 'Еще...' : 'Свернуть'}
      </p>
    </>
  );
});

DocsRow.displayName = 'DocsRow';
