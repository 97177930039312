import React, { createContext, useReducer } from 'react';
import { searchReducer, initialState, SearchParams } from './reducer';
import { Actions } from './actions';

const SearchContext = createContext<{
  state: SearchParams;
  dispatch: React.Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const SearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(searchReducer, initialState);

  return <SearchContext.Provider value={{ state, dispatch }}>{children}</SearchContext.Provider>;
};

export { SearchContext, SearchProvider };
