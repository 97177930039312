import { PlacementResult, SummaryChart } from '@/api/summaryApi/summaryApiTypes';
import { formatPrice } from '@/utils/formatPrice';
import { ChartData, ChartDataset, TooltipItem } from 'chart.js';

const randomColorGenerator = () => {
  return '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
};

const getDataset = (
  chartsData: SummaryChart[] | PlacementResult[],
): ChartDataset<'doughnut', number[]>[] => {
  return [
    {
      data: chartsData.map((item) => item.count),
      backgroundColor: chartsData.map(randomColorGenerator),
      borderWidth: 0,
    },
  ];
};

export const getChartDataMap = (
  chartsData?: SummaryChart[],
): ChartData<'doughnut', number[], unknown> | null => {
  if (!Array.isArray(chartsData)) return null;
  return {
    labels: chartsData.map((item) => `${item.shortPlacing}: ${item.count} шт.`),
    datasets: getDataset(chartsData),
  };
};

export const getChildChartDataMap = (
  chartsData?: PlacementResult[],
): ChartData<'doughnut', number[], unknown> | null => {
  if (!Array.isArray(chartsData)) return null;
  return {
    labels: chartsData.map((item) => `${item.placementResult}: ${item.count} шт.`),
    datasets: getDataset(chartsData),
  };
};

export const findCurrentData = (parsed: number, label: string, chartsData?: SummaryChart[]) => {
  if (!chartsData) return;
  return chartsData.find((item) => parsed === item.count && label.includes(item.shortPlacing));
};

export const getTooltipLabel = (data: TooltipItem<'doughnut'>, chartsData?: SummaryChart[]) => {
  if (!chartsData) return data.parsed.toString();
  const currentData = findCurrentData(data.parsed, data.label, chartsData);
  if (!currentData) return data.parsed.toString();
  return ` ${data.parsed} шт. (${formatPrice(currentData.sum)} руб.)`;
};

export const getTooltipTitle = (data: TooltipItem<'doughnut'>, chartsData?: SummaryChart[]) => {
  if (!chartsData) return data.label;
  const currentData = findCurrentData(data.parsed, data.label, chartsData);
  if (!currentData) return data.label;
  return currentData.placing;
};

export const getDataChild = (parsed: number, label: string, chartsData?: SummaryChart[]) => {
  const currentData = findCurrentData(parsed, label, chartsData);
  if (!currentData) return null;
  return currentData.placementResults;
};

const findCurrentChildData = (parsed: number, label: string, chartsData?: PlacementResult[]) => {
  if (!chartsData) return;
  return chartsData.find((item) => parsed === item.count && label.includes(item.placementResult));
};

export const getChildTooltipLabel = (
  data: TooltipItem<'doughnut'>,
  chartsData?: PlacementResult[],
) => {
  if (!chartsData) return data.parsed.toString();
  const currentData = findCurrentChildData(data.parsed, data.label, chartsData);
  if (!currentData) return data.parsed.toString();
  return ` ${data.parsed} шт. (${formatPrice(currentData.sum)} руб.)`;
};

export const getChildtTooltipTitle = (
  data: TooltipItem<'doughnut'>,
  chartsData?: PlacementResult[],
) => {
  if (!chartsData) return data.label;
  const currentData = findCurrentChildData(data.parsed, data.label, chartsData);
  if (!currentData) return data.label;
  return currentData.placementResult;
};
