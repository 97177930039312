import { memo, ReactNode, useCallback, useMemo } from 'react';
import { Spin, TreeSelect, TreeSelectProps } from 'antd';
import VIcon from 'public/icons/v.svg';
import { filterTree } from '@/utils/filterOptions';
import { useGetRegions } from '@/api/nmckApi/nmckApi';
import { getTreeDataMap } from '@/utils';
import { saveRegionsToStorage } from '@/components/NmckGrid/NmckGridContracts/helpers';

const { SHOW_PARENT } = TreeSelect;

type Props = {
  onRegionsChange: (newIds: number[]) => void;
  placeholder?: string | ReactNode;
} & Omit<TreeSelectProps, 'placeholder' | 'onChange' | 'onSelect'>;

export const RegionsTreeSelect = memo(
  ({ onRegionsChange, placeholder = 'Выберите регион', ...treeSelectProps }: Props) => {
    const { data: regions = [], isLoading: isRegionsLoading } = useGetRegions();

    const treeData = useMemo(() => getTreeDataMap(regions), [regions]);

    const handleRegionChange = useCallback(
      (newItems: number[]) => {
        onRegionsChange(newItems);
        saveRegionsToStorage(newItems);
      },
      [onRegionsChange],
    );

    return (
      <TreeSelect
        placeholder={placeholder}
        suffixIcon={isRegionsLoading ? <Spin size="small" /> : <VIcon className="v-icon" />}
        treeData={treeData}
        treeCheckable={true}
        showCheckedStrategy={SHOW_PARENT}
        maxTagCount="responsive"
        popupMatchSelectWidth={800}
        placement="bottomLeft"
        showSearch
        allowClear
        filterTreeNode={filterTree}
        {...treeSelectProps}
        onChange={handleRegionChange}
      />
    );
  },
);

RegionsTreeSelect.displayName = 'RegionsTreeSelect';
