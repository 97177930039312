import { memo, PropsWithChildren } from 'react';
import { Flex, Spin } from 'antd';
import cx from 'classnames';

type Props = {
  isLoading: boolean;
  wrapperClassName?: string;
} & PropsWithChildren;

export const LoadingWrapper = memo(({ isLoading, wrapperClassName, children }: Props) => {
  return isLoading ? (
    <Flex
      justify="center"
      className={cx(wrapperClassName)}
    >
      <Spin />
    </Flex>
  ) : (
    children
  );
});

LoadingWrapper.displayName = 'LoadingWrapper';
