export enum ActionType {
  SET_PLANNED_ITEMS_PER_PAGE = ' SET_PLANNED_ITEMS_PER_PAGE',
  SET_PLANNED_CURRENT_PAGE = 'SET_PLANNED_CURRENT_PAGE ',
  SET_PROCESS_ITEMS_PER_PAGE = ' SET_PROCESS_ITEMS_PER_PAGE',
  SET_PROCESS_CURRENT_PAGE = 'SET_PROCESS_CURRENT_PAGE ',
  SET_CONTRACTS_ITEMS_PER_PAGE = ' SET_CONTRACTS_ITEMS_PER_PAGE',
  SET_CONTRACTS_CURRENT_PAGE = 'SET_CONTRACTS_CURRENT_PAGE ',
}

type ActionCreator<T extends ActionType, P> = (payload: P) => {
  type: T;
  payload: P;
};

const actionCreator = <P, T extends ActionType>(type: T) =>
  ((payload: P) => ({
    type,
    payload,
  })) as ActionCreator<T, P>;

export const actions = {
  setPlannedItemsPerPage: actionCreator<number, ActionType.SET_PLANNED_ITEMS_PER_PAGE>(
    ActionType.SET_PLANNED_ITEMS_PER_PAGE,
  ),
  setPlannedCurrentPage: actionCreator<number, ActionType.SET_PLANNED_CURRENT_PAGE>(
    ActionType.SET_PLANNED_CURRENT_PAGE,
  ),
  setProcessItemsPerPage: actionCreator<number, ActionType.SET_PROCESS_ITEMS_PER_PAGE>(
    ActionType.SET_PROCESS_ITEMS_PER_PAGE,
  ),
  setProcessCurrentPage: actionCreator<number, ActionType.SET_PROCESS_CURRENT_PAGE>(
    ActionType.SET_PROCESS_CURRENT_PAGE,
  ),
  setContractsItemsPerPage: actionCreator<number, ActionType.SET_CONTRACTS_ITEMS_PER_PAGE>(
    ActionType.SET_CONTRACTS_ITEMS_PER_PAGE,
  ),
  setContractsCurrentPage: actionCreator<number, ActionType.SET_CONTRACTS_CURRENT_PAGE>(
    ActionType.SET_CONTRACTS_CURRENT_PAGE,
  ),
};

export type Actions = ReturnType<(typeof actions)[keyof typeof actions]>;
