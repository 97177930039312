import { CSSProperties, memo, useCallback, useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { Tooltip } from 'antd';

import { CharacteristicItem, CharacteristicsItems } from '@/widgets/Docs/components';
import { Characteristic } from '@/api/docsApi/docsApiTypes';
import { SearchContext } from '@/widgets/Docs/context/context';
import { actions } from '@/widgets/Docs/context/actions';

import styles from './CharacteristicsRow.module.scss';

const tooltipStyle: CSSProperties = {
  maxWidth: 595,
  width: 'max-content',
  maxHeight: 268,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

type CharacteristicsRowProps = {
  items: Characteristic[];
};

export const CharacteristicsRow = memo(({ items }: CharacteristicsRowProps) => {
  const { dispatch } = useContext(SearchContext);

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [containerRef, setcontainerRef] = useState<HTMLDivElement | null>();

  useEffect(() => {
    if (containerRef) {
      setIsOverflowing(containerRef.scrollHeight > containerRef.clientHeight);
    }
  }, [containerRef]);

  const handleClick = useCallback(() => {
    if (!isOverflowing) return;

    dispatch(actions.setCharacteristic(items));
    dispatch(actions.showModal(true));
  }, [dispatch, isOverflowing, items]);

  return (
    <Tooltip
      title={
        <div
          className={styles.characteristicTooltip}
          ref={setcontainerRef}
        >
          <>
            {items.map((item, index) => (
              <CharacteristicItem
                key={index}
                name={item.name}
                value={item.value}
                className={styles.characteristic}
              />
            ))}
          </>
        </div>
      }
      overlayInnerStyle={tooltipStyle}
    >
      <div
        onClick={handleClick}
        className={cx(styles.characteristics, {
          [styles.characteristicsOverflowed]: isOverflowing,
        })}
      >
        <CharacteristicsItems
          items={items}
          overflowed={isOverflowing}
        />
      </div>
    </Tooltip>
  );
});

CharacteristicsRow.displayName = 'CharacteristicsRow';
