import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import { Modal, Button, Flex } from 'antd';
import Print from '/public/icons/print.svg';
import Export from '/public/icons/export.svg';
import { DeadlineCalculation } from '@/api/skpApi/skpApiTypes';

import { PrintContent } from '../PrintContent/PrintContent';

import styles from './PrintModal.module.scss';
import { getDeadlineCalculationXlsx } from '@/api/skpApi/skpApi';

type PrintModalProps = {
  open: boolean;
  onCancel: () => void;
  currentCalculation: DeadlineCalculation;
};
const styleSheet = `
<style>
 
  @media print {
    body {
      font-family: 'Arial', sans-serif;
    }
    thead {
    font-weight: bold;
    }
    td:nth-child(2) {
      width: 100px;
    }
    td, th {
      vertical-align: top;
      border-bottom: 1px solid #9ba0a7;
      padding: 8px;
    }
    thead {
      text-align: left;
    }
  }
</style>
`;
const iframeStyle = { width: 0, height: 0, border: 0 };

export const PrintModal = memo(({ open, currentCalculation, onCancel }: PrintModalProps) => {
  const printFrameRef = useRef<HTMLIFrameElement>(null);
  const [printContent, setPrintContent] = useState<string>('');

  const htmlContent = useMemo(() => {
    return ReactDOMServer.renderToString(<PrintContent currentCalculation={currentCalculation} />);
  }, [currentCalculation]);

  useEffect(() => {
    const fullHtmlContent = `
        <!DOCTYPE html>
        <html lang="ru">
          <head>
            ${styleSheet}
          </head>
          <body>
            ${htmlContent}
          </body>
        </html>
      `;

    setPrintContent(fullHtmlContent);
  }, [htmlContent]);

  const handlePrint = useCallback(() => {
    if (printFrameRef.current) {
      const printDocument =
        printFrameRef.current.contentDocument || printFrameRef.current.contentWindow?.document;
      if (printDocument) {
        printDocument.open();
        printDocument.write(printContent);
        printDocument.close();
        printFrameRef.current.contentWindow?.focus();
        printFrameRef.current.contentWindow?.print();
      }
    }
  }, [printContent]);

  const handleDownload = useCallback(() => {
    getDeadlineCalculationXlsx(currentCalculation);
  }, [currentCalculation]);

  return (
    <Modal
      className={styles.modal}
      title={
        <Flex
          justify="space-between"
          className={styles.modalHeader}
        >
          <div>Предпросмотр СКП</div>
          <Flex
            gap={20}
            className={styles.btnsWrappes}
          >
            <Button
              icon={<Print />}
              onClick={handlePrint}
              className={styles.btn}
            >
              Распечатать
            </Button>
            <Button
              icon={<Export />}
              onClick={handleDownload}
              className={styles.btn}
            >
              Скачать
            </Button>
          </Flex>
        </Flex>
      }
      open={open}
      width={'100vw'}
      onCancel={onCancel}
      footer={false}
    >
      <PrintContent currentCalculation={currentCalculation} />
      <iframe
        ref={printFrameRef}
        style={iframeStyle}
      />
    </Modal>
  );
});
PrintModal.displayName = 'PrintModal';
