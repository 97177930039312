import { memo } from 'react';
import cx from 'classnames';
import { Button, Pagination, PaginationProps } from 'antd';

import styles from './EisPagination.module.scss';

type EisPaginationType = {
  loadMore?: VoidFunction;
  className?: string;
} & PaginationProps;

export const EisPagination = memo(
  ({ className, loadMore, ...paginationProps }: EisPaginationType) => {
    return (
      <div className={cx(className, styles.paginationContainer)}>
        <p className={styles.paginationText}>Страницы:</p>
        <Pagination
          {...paginationProps}
          showSizeChanger={false}
          className={styles.pagination}
        />
        {loadMore && (
          <Button
            type="text"
            onClick={loadMore}
            className={styles.loadMore}
          >
            Загрузить ещё
          </Button>
        )}
      </div>
    );
  },
);

EisPagination.displayName = 'EisPagination';
