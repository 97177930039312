import { sendGTMEvent } from '@next/third-parties/google';
import { eventsGTM, isLogMetricsEnabled } from '@/metrika';
import { getUserIdFromStorage } from '@/api/userApi/userApiUtils';

type Options = {
  value?: string | number | boolean;
  info?: string;
};

export const sendEventGTM = (
  event: (typeof eventsGTM)[keyof typeof eventsGTM],
  { value, info }: Options = {},
) => {
  if (isLogMetricsEnabled()) {
    return sendGTMEvent({
      event,
      eventSource: 'custom',
      userId: getUserIdFromStorage(),
      ...(value ? { value } : {}),
      ...(info ? { info } : {}),
    });
  }
};
