import { memo, useCallback, useContext } from 'react';

import { Radio, RadioChangeEvent } from 'antd';
import { StatusType } from '@/widgets/ReestrAll/types/types';
import styles from './RadioFilters.module.scss';
import { ReestrAllContext } from '@/widgets/ReestrAll/context/context';
import { actions } from '@/widgets/ReestrAll/context/actions';

const [currentYear, prevYear, nextYear] = [
  String(new Date().getFullYear()),
  String(new Date().getFullYear() - 1),
  String(new Date().getFullYear() + 1),
];
export const RadioFilters = memo(() => {
  const { state, dispatch } = useContext(ReestrAllContext);
  const handleChangeStatus = useCallback(
    (e: RadioChangeEvent) => {
      dispatch(actions.setStatus(e.target.value));
    },
    [dispatch],
  );
  const handleChangeConcluded = useCallback(
    (e: RadioChangeEvent) => {
      dispatch(actions.setConcluded(e.target.value));
    },
    [dispatch],
  );

  return (
    <div className={styles.radios}>
      <div className={styles.status}>
        Статус:
        <Radio.Group
          onChange={handleChangeStatus}
          value={state.status}
        >
          <Radio value={StatusType.ALL}>Все</Radio>
          <Radio value={StatusType.TENDERING}>Проводятся</Radio>
          <Radio value={StatusType.EXECUTION}>На исполнении</Radio>
        </Radio.Group>
      </div>
      <div className={styles.concluded}>
        Показать сумму запланированных и фактических оплат по контракту за:
        <Radio.Group
          onChange={handleChangeConcluded}
          value={state.concluded}
        >
          <Radio value={'ALL'}>Все года</Radio>
          <Radio value={prevYear}>{prevYear}</Radio>
          <Radio value={currentYear}>{currentYear}</Radio>
          <Radio value={nextYear}>{nextYear}</Radio>
        </Radio.Group>
      </div>
    </div>
  );
});
RadioFilters.displayName = 'RadioFilters';
