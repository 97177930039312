import useSWR, { SWRConfiguration } from 'swr';
import {
  CompatibilityUpdateBody,
  CreateCurrentPurchaseItemBody,
  PurchaseByIdResponse,
} from './planningCurrentApiTypes';
import {
  deleteData,
  downloadXlsx,
  fetcher,
  postData,
  putData,
  getData,
} from '@/api/commonApi/commonApi';
import { Key } from 'react';
import { NmckCompatibilityByIdResponse } from '@/api/nmckApi/nmckApiTypes';

export const useGetCurrentPurchaseById = (id: string | string[]) => {
  return useSWR(`/purchase/${id}`, getData<PurchaseByIdResponse>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  } as SWRConfiguration);
};

export const useGetCurrentPurchaseCompatibility = (
  id: string,
  omitEmptyColumns: boolean = false,
  nationalProject: boolean = false,
) => {
  const params = new URLSearchParams();
  params.append('omitEmptyColumns', omitEmptyColumns.toString());
  params.append('nationalProject', nationalProject.toString());

  return useSWR(`/purchase/${id}/compatibility?${params}`, getData<NmckCompatibilityByIdResponse>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  } as SWRConfiguration);
};

export const updatePurchaseCompatibilityCell = (
  purchaseId: string,
  rowId: number,
  body: CompatibilityUpdateBody,
) => {
  return postData(`/purchase/${purchaseId}/row/${rowId}/compatibility-user-selection`, body);
};

export const deleteCurrentPurchaseItem = (body: Key[], id: string | string[]) => {
  return deleteData(`/purchase/${id}/item`, body);
};

export const createCurrentPurchaseItem = (
  body: CreateCurrentPurchaseItemBody,
  id: string | string[],
) => {
  return postData<CreateCurrentPurchaseItemBody>(
    `/purchase/${id}/item`,
    body,
  ) as unknown as Promise<PurchaseByIdResponse>;
};

export const updateCurrentPurchaseItem = (
  id: string | string[],
  itemId: number,
  body: CreateCurrentPurchaseItemBody,
) => {
  return putData<CreateCurrentPurchaseItemBody>(
    `/purchase/${id}/item/${itemId}`,
    body,
  ) as unknown as Promise<PurchaseByIdResponse>;
};

export const getCurrentPurchaseXlsx = async (id: string | string[]) => {
  const response = await fetcher(`/purchase/${id}/xlsx`);
  await downloadXlsx(response, 'Описание объекта закупки');
};
