import { memo, useState } from 'react';
import { Button, Flex, Tabs, TabsProps, Tooltip } from 'antd';
import moment from 'moment';
import Link from 'next/link';

import { SmpReportInfo } from '@/widgets/SmpReport/components';
import { getReportXlsx } from '@/api/spmReportApi/smpRepotApi';

import ExportIcon from '/public/icons/export.svg';
import QuestionRoundIcon from '/public/icons/questionRound.svg';

import styles from './SmpReportDisplay.module.scss';
import { UiSwitch } from '@/components/Ui';
import cx from 'classnames';

const [currentYear, pastYear, nextYear] = [
  moment().year(),
  moment().year() - 1,
  moment().year() + 1,
];

const items: TabsProps['items'] = [
  {
    key: String(pastYear),
    label: `${pastYear} год`,
  },
  {
    key: String(currentYear),
    label: `${currentYear} год`,
  },
  {
    key: String(nextYear),
    label: `${nextYear} год`,
  },
];

const medsTooltip =
  'При определении заказчиками из числа федеральных органов исполнительной власти или органов исполнительной власти субъектов Российской Федерации, подведомственных им государственных учреждений или государственных унитарных предприятий, а также муниципальных медицинских организаций объема закупок, предусмотренного частью 1 статьи 30 настоящего Федерального закона, в расчет совокупного годового объема закупок не включаются закупки лекарственных препаратов для медицинского применения и медицинских изделий ';

const medsLink =
  'https://base.garant.ru/70353464/ea54c1918750348cf1860e01a0121200/#:~:text=71.%20%D0%A3%D1%81%D1%82%D0%B0%D0%BD%D0%BE%D0%B2%D0%B8%D1%82%D1%8C%2C%20%D1%87%D1%82%D0%BE,%D0%B8%20%D0%BC%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D1%81%D0%BA%D0%B8%D1%85%20%D0%B8%D0%B7%D0%B4%D0%B5%D0%BB%D0%B8%D0%B9.';

export const SmpReportDisplay = memo(() => {
  const [activeTab, setActiveTab] = useState(String(currentYear));
  const [showMeds, setShowMeds] = useState(false);

  return (
    <div className={styles.wrapper}>
      <Flex
        gap={48}
        className={styles.header}
        align="center"
      >
        <div className={styles.title}>
          Отчет об объёме закупок у субъектов малого предпринимательства и социально ориентированных
          некоммерческих организаций
        </div>
        <div className={styles.tabs}>
          <Tabs
            activeKey={activeTab}
            items={items}
            onChange={setActiveTab}
            className={styles.tab}
          />
        </div>
        <Button
          type="text"
          icon={<ExportIcon />}
          className={styles.btn}
          onClick={getReportXlsx}
        >
          Скачать отчёт
        </Button>

        <UiSwitch
          value={showMeds}
          onChange={setShowMeds}
        >
          <span>СГОЗ для СМП закупки лекарств и медицинских изделий</span>
          <Tooltip
            title={
              <div>
                {medsTooltip}
                <Link
                  href={medsLink}
                  target="_blank"
                >
                  ч.71 ст.112 44-ФЗ
                </Link>
              </div>
            }
          >
            <QuestionRoundIcon className={cx('hint-icon', styles.hintIcon)} />
          </Tooltip>
        </UiSwitch>
      </Flex>
      <SmpReportInfo
        year={activeTab}
        excludeMed={showMeds}
      />
    </div>
  );
});

SmpReportDisplay.displayName = 'SmpReportDisplay';
