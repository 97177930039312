'use client';
import { memo } from 'react';
import { ConfigProvider, ConfigProviderProps, Table, TableProps } from 'antd';
import { NmckGridHeader } from '@/components/NmckGrid/NmckGridHeader/NmckGridHeader';
import { NmckGridFooter } from '@/components/NmckGrid/NmckGridFooter/NmckGridFooter';
import styles from './CalculationGrid.module.scss';
import colors from '@/styles/colors.module.scss';
import { pageRoutes } from '@/app/pageRoutes';
import { useNmckGrid } from '@/components/NmckGrid/useNmckGrid/useNmckGrid';

const tableScroll: TableProps['scroll'] = { x: 'max-content' };

const theme: ConfigProviderProps['theme'] = {
  components: {
    Table: {
      cellPaddingInline: 5,
      rowExpandedBg: colors.gray,
    },
  },
};

type Props = {
  id: string;
};

export const CalculationGrid = memo(({ id }: Props) => {
  const { columns, list, isLoading, rowClassName, expandable } = useNmckGrid({
    nmckId: id,
  });

  return (
    <ConfigProvider theme={theme}>
      <NmckGridHeader
        id={id}
        newCalculationLink={pageRoutes.nmckAnalysis}
      >
        <div className={styles.title}>Расчёт НМЦК</div>
      </NmckGridHeader>
      <Table
        rowKey="id"
        className={styles.table}
        columns={columns}
        rowClassName={rowClassName}
        scroll={tableScroll}
        expandable={expandable}
        dataSource={list}
        pagination={false}
        loading={isLoading}
      />
      <NmckGridFooter id={id} />
    </ConfigProvider>
  );
});
CalculationGrid.displayName = 'CalculationGrid';
