import { memo, useCallback } from 'react';
import { NmckRow } from '@/api/nmckApi/nmckApiTypes';
import styles from './MorePopUpContent.module.scss';

export type MorePopUpContentProps = {
  record: NmckRow;
  rows: NmckRow[];
  handleDeleteRow: (key: React.Key) => void;
  handleCopyRow: (record: NmckRow) => void;
};

export const MorePopUpContent = memo(
  ({ record, handleDeleteRow, handleCopyRow, rows }: MorePopUpContentProps) => {
    const isDeleteButtonShown = rows.length > 1;

    const handleRowDelete = useCallback(() => {
      handleDeleteRow(record.id);
    }, [handleDeleteRow, record.id]);

    const handleRowCopy = useCallback(() => {
      handleCopyRow(record);
    }, [handleCopyRow, record]);
    return (
      <div className={styles.popUpContent}>
        {isDeleteButtonShown && (
          <div
            className={styles.popUpText}
            onClick={handleRowDelete}
          >
            Удалить
          </div>
        )}
        <div
          className={styles.popUpText}
          onClick={handleRowCopy}
        >
          Скопировать
        </div>
      </div>
    );
  },
);
MorePopUpContent.displayName = 'MorePopUpContent';
