import { memo, ReactNode, useCallback, useMemo } from 'react';
import Vicon from 'public/icons/v.svg';
import { TreeSelect, TreeSelectProps } from 'antd';
import { useGetPurchaseMethod } from '@/api/nmckApi/nmckApi';
import { getTreeDataMap } from '@/utils';
import { filterTree } from '@/utils/filterOptions';

const { SHOW_PARENT } = TreeSelect;

type Props = {
  onPurchaseMethodsChange: (values: number[]) => void;
  placeholder?: string | ReactNode;
} & Omit<TreeSelectProps, 'placeholder' | 'onChange' | 'onSelect'>;

export const PurchaseMethodsTreeSelect = memo(
  ({
    onPurchaseMethodsChange,
    placeholder = 'Выберите способ закупки',
    ...treeSelectProps
  }: Props) => {
    const { data: purchaseMethods = [], isLoading: isPurchaseMethodsLoading } =
      useGetPurchaseMethod();

    const treeData = useMemo(() => getTreeDataMap(purchaseMethods), [purchaseMethods]);

    const handlePurchaseMethodChange = useCallback(
      (newIds: number[]) => {
        onPurchaseMethodsChange(newIds);
      },
      [onPurchaseMethodsChange],
    );

    return (
      <TreeSelect
        suffixIcon={<Vicon className="v-icon" />}
        treeData={treeData}
        treeCheckable={true}
        showCheckedStrategy={SHOW_PARENT}
        maxTagCount="responsive"
        popupMatchSelectWidth={800}
        loading={isPurchaseMethodsLoading}
        placeholder={placeholder}
        allowClear
        showSearch
        filterTreeNode={filterTree}
        {...treeSelectProps}
        onChange={handlePurchaseMethodChange}
      />
    );
  },
);

PurchaseMethodsTreeSelect.displayName = 'PurchaseMethodsTreeSelect';
