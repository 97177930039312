import { memo, useCallback, useMemo } from 'react';
import { Flex, Form, FormProps, Tooltip } from 'antd';
import styles from './OkpdKtruSuppliersFilter.module.scss';
import { Moment } from 'moment';
import QuestionIcon from '/public/icons/questionRound.svg';
import { DatePeriodPicker } from '@/components/DatePeriodPicker/DatePeriodPicker';
import { DatePeriodPickerType } from '@/components/DatePeriodPicker/types';
import Link from 'next/link';
import { UiSwitch } from '@/components/Ui';
import { RegionsTreeSelect } from '@/components/RegionsTreeSelect/RegionsTreeSelect';

type Props = {
  filterParams: SuppliersFilter;
  onFilterParamsChange: (params: Partial<SuppliersFilter>) => void;
};

export type SuppliersFilter = {
  region: number[];
  contractDateFrom?: Moment;
  contractDateTo?: Moment;
  smp: boolean;
  prom: boolean;
  hasPenalty: boolean;
  okpdCode?: string;
  ktruCode?: string;
};

const formLabelCol: FormProps['labelCol'] = { span: 4 };

export const OkpdKtruSuppliersFilter = memo(({ filterParams, onFilterParamsChange }: Props) => {
  const filterPropToHandler = useMemo(
    () =>
      Object.keys(filterParams).reduce(
        (acc, field) => ({
          ...acc,
          [field]: (value: unknown) => onFilterParamsChange({ [field]: value }),
        }),
        {} as { [K in keyof SuppliersFilter]: (value: SuppliersFilter[K]) => void },
      ),
    [filterParams, onFilterParamsChange],
  );

  const handleDatesChange = useCallback(
    ({ startDate, endDate }: DatePeriodPickerType['updatedDates']) => {
      onFilterParamsChange({
        contractDateFrom: startDate,
        contractDateTo: endDate,
      });
    },
    [onFilterParamsChange],
  );

  return (
    <Form
      className={styles.form}
      labelAlign="left"
      labelCol={formLabelCol}
      layout="horizontal"
    >
      <Form.Item label="Регион поставщика">
        <RegionsTreeSelect
          className={styles.select}
          placeholder="Не указан"
          onRegionsChange={filterPropToHandler['region']}
        />
      </Form.Item>
      <Form.Item label="Заключен">
        <DatePeriodPicker
          onDatesChange={handleDatesChange}
          startDate={filterParams.contractDateFrom}
          endDate={filterParams.contractDateTo}
        />
      </Form.Item>
      <Form.Item>
        <Flex
          align="center"
          gap={32}
        >
          <UiSwitch
            value={filterParams.smp}
            onChange={filterPropToHandler['smp']}
          >
            <span>Входит в реестр СМП</span>
          </UiSwitch>
          <UiSwitch
            value={filterParams.prom}
            onChange={filterPropToHandler['prom']}
          >
            <span>Поставляет пром. товары, произведённые в РФ/ЕАЭС</span>
            <Tooltip title="Побеждал в закупках, в которых установлен запрет на допуск иностранных промышленных товаров в соответствии с ПП 616">
              <QuestionIcon className="hint-icon" />
            </Tooltip>
          </UiSwitch>
          <UiSwitch
            value={filterParams.hasPenalty}
            onChange={filterPropToHandler['hasPenalty']}
          >
            <span>Контракты исполнены без неустоек</span>
            <Tooltip
              title={
                <div>
                  {' '}
                  Согласно{' '}
                  <Link
                    href="https://www.garant.ru/products/ipo/prime/doc/70373958/"
                    target="_blank"
                  >
                    п. 3.9 Метод. рекомендаций (утв. приказом Минэкономразвития РФ от 02.10.2013
                    №567)
                  </Link>{' '}
                  запрос о предоставлении КП рекомендуется направлять поставщикам, исполнявшим в
                  течение последних 3 лет аналогичные контракты без применения к поставщику неустоек
                  (штрафов, пеней).
                </div>
              }
            >
              <QuestionIcon className="hint-icon" />
            </Tooltip>
          </UiSwitch>
        </Flex>
      </Form.Item>
    </Form>
  );
});

OkpdKtruSuppliersFilter.displayName = 'OkpdKtruSuppliersFilter';
