import { NmckCompatibilityByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import { useMemo, useState } from 'react';

type Props = {
  data?: NmckCompatibilityByIdResponse;
};

const filterByPurchaseNumber = (
  data: NmckCompatibilityByIdResponse | undefined,
  purchaseNumber: number,
): NmckCompatibilityByIdResponse | undefined => {
  if (!data) return undefined;
  if (purchaseNumber === 0) {
    return data;
  }

  return {
    ...data,
    rows: data.rows.filter((row) => row.purchaseNumber === purchaseNumber),
  };
};

export const useCompatibility = ({ data }: Props) => {
  const [activeTab, setActiveTab] = useState('0');
  const [showEmptyRows, setShowEmptyRows] = useState(true);
  const [isNationalProjects, setIsNationalProjects] = useState(false);

  const filteredData = useMemo(
    () => filterByPurchaseNumber(data, parseInt(activeTab)),
    [activeTab, data],
  );

  const rows = useMemo(() => filteredData?.rows ?? [], [filteredData]);
  const header = useMemo(() => filteredData?.header ?? [], [filteredData]);

  return {
    rows,
    rawRows: data?.rows ?? [],
    header,

    activeTab,
    showEmptyRows,
    isNationalProjects,
    setActiveTab,
    setShowEmptyRows,
    setIsNationalProjects,
  };
};
