import { Button, Flex } from 'antd';
import { KtruCharacteristic, KtruCharacteristicSelectionType } from '@/api/okpdApi/okpdApiTypes';
import { memo, useCallback, useMemo, useState } from 'react';
import { pluralize } from '@/utils';
import styles from './Characteristics.module.scss';
import { uniq } from 'lodash';

type CharacteristicsProps = {
  characteristics: KtruCharacteristic[];
};

type KtruCharacteristicWithStringValues = Omit<KtruCharacteristic, 'values'> & {
  values: string[];
};

const generateList = (
  chars: KtruCharacteristic[],
  isExpand: boolean,
): {
  list: KtruCharacteristicWithStringValues[];
  otherLength: number;
} => {
  const list: KtruCharacteristicWithStringValues[] = [...chars].map((item) => ({
    ...item,
    values: item.values.map(({ value }) => value),
  }));

  if (chars.length) {
    const units = uniq(chars.map(({ unit }) => unit).filter(Boolean));
    units.length &&
      list.unshift({
        name: 'Единицы измерения',
        values: units,
        unit: '',
        kind: '',
        type: '',
        isMandatory: false,
        selection: KtruCharacteristicSelectionType.SINGLE,
      });
  }

  const computedList: KtruCharacteristicWithStringValues[] =
    list.length <= 4 || isExpand ? list : list.slice(0, 4);

  return {
    list: computedList,
    otherLength: list.length - computedList.length,
  };
};

export const Characteristics = memo(({ characteristics }: CharacteristicsProps) => {
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const { list, otherLength } = useMemo(
    () => generateList(characteristics, isExpand),
    [characteristics, isExpand],
  );

  const handleExpandClick = useCallback(() => setIsExpand(true), []);

  return (
    <Flex
      vertical
      gap={8}
    >
      {list.map(({ name, values }) => (
        <div key={name}>
          <span className={styles.label}>{name}: </span>
          <span className={styles.value}>{values.join('; ')}</span>
        </div>
      ))}
      {!!otherLength && (
        <Button
          className={styles.button}
          type="link"
          onClick={handleExpandClick}
          size="small"
        >
          Остальные {otherLength}{' '}
          {pluralize(otherLength, ['характеристика', 'характеристики', 'характеристик'])}
        </Button>
      )}
    </Flex>
  );
});

Characteristics.displayName = 'Characteristics';
