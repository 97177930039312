import { MailBodyParams } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/types';
import { isClientRender } from '@/utils/isClientRender';

const STORAGE_USER_EMAIL_KEY = 'user-email-key';

export const saveUserEmailToStorage = (email: string) => {
  localStorage.setItem(STORAGE_USER_EMAIL_KEY, email);
};

export const getUserEmailFromStorage = (): string =>
  isClientRender() ? (localStorage.getItem(STORAGE_USER_EMAIL_KEY) ?? '') : '';

export const openMailTo = (
  sender: string,
  receivers: string[],
  mailTemplateParams: MailBodyParams,
) => {
  const emails = receivers.join(';');
  const subject = encodeURIComponent('Запрос КП');
  const body = encodeURIComponent(getMailBody(mailTemplateParams));
  const mailLink = getMailLinkByEmailDomain(sender, { emails, body, subject });

  window.open(mailLink, '_blank');
};

const getMailLinkByEmailDomain = (
  sender: string,
  { emails, body, subject }: { emails: string; subject: string; body: string },
) => {
  const domain = sender.split('@')[1].toLowerCase();

  if (domain.includes('yandex') || domain.includes('ya')) {
    return `https://mail.yandex.com/compose?to=${emails}&subject=${subject}&body=${body}`;
  }
  if (
    domain.includes('mail') ||
    domain.includes('bk') ||
    domain.includes('inbox') ||
    domain.includes('list') ||
    domain.includes('internet')
  ) {
    return `https://e.mail.ru/compose?to=${emails}&subject=${subject}&body=${body}`;
  }

  return `mailto:${emails}?subject=${subject}&body=${body}`;
};

const getMailBody = ({ inn, kpp, unit, name, code, count }: MailBodyParams) =>
  `Добрый день!

Прошу предоставить цены на следующие товары/работы/услуги для нужд заказчика:

ИНН/КПП: ${inn} / ${kpp}

Наименование: ${name} 
ОКПД2/КТРУ: ${code}
Ед. изм.: ${unit}
Кол-во: ${count}`;
