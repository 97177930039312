import { memo, useCallback, useMemo, useState } from 'react';

import { OkpdDetailBreadcrumbs } from '@/widgets/OkpdDetail/components';
import { Button, ConfigProvider, Flex, List, Popover } from 'antd';
import { pageRoutes } from '@/app/pageRoutes';
import Link from 'next/link';
import Cross from '/public/icons/cross.svg';

import { useGetAllNpa, useGetOkpdInitialList } from '@/api/okpdApi/okpdApi';
import styles from './InitialPage.module.scss';
import colors from '@/styles/colors.module.scss';
import {
  OkpdKtruModalPageParams,
  OkpdKtruPageTypes,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import { OkpdLink } from '@/components/OkpdFlowModal/OkpdLink/OkpdLink';

type Props = {
  modalPageParams?: OkpdKtruModalPageParams;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

const theme = {
  components: {
    Breadcrumb: {
      linkColor: colors.light_blue,
    },
    List: {
      itemPadding: '5px 0px',
    },
  },
};

export const InitialPage = memo(({ setModalPageParams }: Props) => {
  const [isNpaOpen, setNpaOpen] = useState(false);
  const { data: initialContent, isLoading: isInitialContentLoading } = useGetOkpdInitialList();
  const { data: npa, isLoading: isNpaLoading } = useGetAllNpa();

  const npaOpenChange = useCallback((newOpen: boolean) => {
    setNpaOpen(newOpen);
  }, []);
  const hideNpa = useCallback(() => {
    setNpaOpen(false);
  }, []);

  const popoverTitle = useMemo(
    () => (
      <Flex
        gap={44}
        className={styles.popoverTitleWrapper}
      >
        <div className={styles.popoverTitle}>Выполняется проверка кода по следующим НПА:</div>
        <div>
          <Cross
            className={styles.cross}
            onClick={hideNpa}
          />
        </div>
      </Flex>
    ),
    [hideNpa],
  );

  const popoverContent = useMemo(
    () => (
      <List
        className={styles.npaList}
        loading={isNpaLoading}
        dataSource={npa}
        renderItem={(item) => (
          <List.Item className={styles.listItem}>
            <Link
              href={item.href}
              target="_blank"
              className={styles.link}
            >
              {item.name}
            </Link>
          </List.Item>
        )}
      ></List>
    ),
    [isNpaLoading, npa],
  );

  const handleCodeClick = useCallback(
    (code: string) => () =>
      setModalPageParams &&
      setModalPageParams((prev) => ({
        ...prev,
        page: OkpdKtruPageTypes.OKPD,
        code,
      })),
    [setModalPageParams],
  );

  return (
    <ConfigProvider theme={theme}>
      <div className={styles.initialContent}>
        <Flex
          gap={20}
          vertical
        >
          <div>
            <OkpdDetailBreadcrumbs
              parents={[]}
              setModalPageParams={setModalPageParams}
            />
            <div className={styles.subtitle}>
              Классификатор ОКПД 2 с проверкой на{' '}
              <Popover
                open={isNpaOpen}
                onOpenChange={npaOpenChange}
                overlayClassName={styles.popover}
                arrow={false}
                placement="bottom"
                content={popoverContent}
                title={popoverTitle}
                trigger="click"
              >
                <Button type="link">постановления</Button>
              </Popover>
            </div>
          </div>
          <List
            loading={isInitialContentLoading}
            dataSource={initialContent}
            renderItem={(item) => (
              <List.Item className={styles.listItem}>
                <OkpdLink
                  isLink={!setModalPageParams}
                  href={`${pageRoutes.okpd2}/${item.code}`}
                  className={styles.link}
                  onClick={handleCodeClick(item.code)}
                >
                  {item.title}
                </OkpdLink>
              </List.Item>
            )}
          ></List>
        </Flex>
      </div>
    </ConfigProvider>
  );
});
InitialPage.displayName = 'InitialPage';
