import { Breadcrumb } from 'antd';
import styles from './OkpdDetailBreadcrumbs.module.scss';
import { useCallback } from 'react';
import { OkpdLink } from '@/components/OkpdFlowModal/OkpdLink/OkpdLink';
import {
  OkpdKtruPageTypes,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import { pageRoutes } from '@/app/pageRoutes';

type BreadcrumbsProps = {
  parents: string[];
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const OkpdDetailBreadcrumbs = ({ parents, setModalPageParams }: BreadcrumbsProps) => {
  const handleBreadcrumbItemClick = useCallback(
    (code: string) => () =>
      setModalPageParams &&
      setModalPageParams((prev) => ({
        ...prev,
        page: code ? OkpdKtruPageTypes.OKPD : OkpdKtruPageTypes.LISTS,
        code,
      })),
    [setModalPageParams],
  );

  const renderBreadcrumbItem = useCallback(
    (title: string, code: string) => (
      <OkpdLink
        isLink={!setModalPageParams}
        onClick={handleBreadcrumbItemClick(code)}
        href={`${pageRoutes.okpd2}/${code}`}
        className={styles.link}
      >
        {title}
      </OkpdLink>
    ),
    [handleBreadcrumbItemClick, setModalPageParams],
  );

  const items = [
    { title: renderBreadcrumbItem('ОКПД 2', '') },
    ...parents.map((item) => ({ title: renderBreadcrumbItem(item, item) })),
  ];

  return <Breadcrumb items={items} />;
};
