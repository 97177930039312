import { DatePicker } from 'antd';
import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { memo } from 'react';
import { dateFormat } from '@/constants';
import { UiDatePickerProps } from '@/components/Ui/UiDatePicker/types';

const _DatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);
export const UiDatePicker = memo((props: UiDatePickerProps) => (
  <_DatePicker
    format={dateFormat.frontFormat}
    {...props}
  />
));

UiDatePicker.displayName = 'UiDatePicker';
