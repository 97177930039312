'use client';

import { Flex } from 'antd';
import { memo, useCallback, useState } from 'react';
import cx from 'classnames';
import styles from './OkpdKtruDetailHeader.module.scss';
import { useRouter } from 'next/navigation';
import { KtruDetail, OkpdDetail } from '@/api/okpdApi/okpdApiTypes';
import {
  OkpdKtruPageTypes,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import { DebounceInput } from '@/components';
import { pageRoutes } from '@/app/pageRoutes';
import { OkpdKtruDetailTRU } from '@/components/OkpdKtruDetail/OkpdKtruDetailTRU/OkpdKtruDetailTRU';

type HeaderProps = {
  code: string;
  okpd?: OkpdDetail;
  ktru?: KtruDetail;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const OkpdKtruDetailHeader = memo(
  ({ code, okpd, ktru, setModalPageParams }: HeaderProps) => {
    const router = useRouter();
    const [searchValue, setSearchValue] = useState<string>(code);

    const onSearchChange = useCallback(
      (value: string) => {
        const searchString = value.trim();
        setSearchValue(value);

        if (setModalPageParams) {
          setModalPageParams((prev) => ({
            ...prev,
            searchString,
            page: OkpdKtruPageTypes.LISTS,
            code: '',
          }));
        } else {
          router.push(`${pageRoutes.okpd2}/?search=${searchString}`);
        }
      },
      [router, setModalPageParams],
    );

    return (
      <div className={styles.wrapper}>
        <Flex gap={32}>
          <div className={styles.title}>ОКПД 2 / КТРУ</div>
          <DebounceInput
            value={searchValue}
            handleValueChange={onSearchChange}
            className={cx('input-line', styles.input)}
            variant="borderless"
          />
          {(okpd || ktru) && (
            <OkpdKtruDetailTRU
              okpd={okpd}
              ktru={ktru}
            />
          )}
        </Flex>
      </div>
    );
  },
);

OkpdKtruDetailHeader.displayName = 'OkpdKtruDetailHeader';
