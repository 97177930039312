import { OptionWithNumberValue } from '@/types/types';
import colors from '@/styles/colors.module.scss';

export const dropdownStyle = {
  width: 'max-content',
};

export const theme = {
  components: {
    Table: {
      headerBg: colors.white,
      headerColor: colors.light_gray,
      fontWeightStrong: 500,
      borderColor: colors.gray,
      rowExpandedBg: colors.gray,
      footerBg: colors.white,
    },
  },
};

export const showOnPageOptions: OptionWithNumberValue[] = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
];

export const reestrLink =
  'https://zakupki.gov.ru/epz/contract/contractCard/common-info.html?reestrNumber=';
export const regNumberLink =
  'https://zakupki.gov.ru/epz/order/notice/ea20/view/common-info.html?regNumber=';
