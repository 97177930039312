import { memo, useCallback, useContext } from 'react';
import { ConfigProvider, Pagination, Select, Table, TableProps } from 'antd';
import Link from 'next/link';
import moment from 'moment';

import { formatPrice } from '@/utils/formatPrice';
import { CurrentContext } from '@/widgets/Current/context/context';
import { actions } from '@/widgets/Current/context/actions';
import {
  dropdownStyle,
  reestrLink,
  showOnPageOptions,
  theme,
} from '@/widgets/Current/constants/constants';
import { ContractExecution, ContractExecutionResponse } from '@/api/currentApi/currentApiTypes';
import { dateFormat } from '@/constants';

import Vicon from '/public/icons/v.svg';
import ExportDiagonalIcon from '/public/icons/exportDiagonal.svg';

import styles from './ContractsTable.module.scss';

const columns: TableProps<ContractExecution>['columns'] = [
  {
    title: 'Предмет контракта',
    dataIndex: 'predmet',
    key: 'predmet',
    width: 762,
    render: (_, { predmet, regnum }) => (
      <Link
        href={reestrLink + regnum}
        className={styles.title}
        target="_blank"
      >
        <ExportDiagonalIcon className={styles.diagonal} />
        <span className={styles.titleText}>{predmet}</span>
      </Link>
    ),
  },
  {
    title: 'Дата заключения',
    dataIndex: 'signDate',
    key: 'signDate',
    width: 162,
    render: (_, { signDate }) => moment(signDate).format(dateFormat.frontFormat),
  },
  {
    title: 'Поставщик',
    dataIndex: 'supplier',
    key: 'supplier',
    width: 252,
    render: (_, { supplier }) => <p className={styles.supplier}>{supplier}</p>,
  },
  {
    title: 'Цена контракта, ₽',
    dataIndex: 'contractPrice',
    key: 'contractPrice',
    width: 160,
    className: styles.priceRow,
    render: (_, { contractPrice }) => formatPrice(contractPrice),
  },
  {
    title: 'Окончание этапа',
    dataIndex: 'stageEndDate',
    key: 'stageEndDate',
    width: 170,
    render: (_, { stageEndDate, expired }) => {
      return (
        <p className={expired ? styles.expired : undefined}>
          {moment(stageEndDate).format(dateFormat.frontFormat)}
        </p>
      );
    },
  },
  {
    title: 'Оплачено на сумму, ₽',
    dataIndex: 'paidSum',
    key: 'paidSum',
    width: 190,
    className: styles.priceRow,
    render: (_, { paidSum }) => formatPrice(paidSum),
  },
  {},
];

type ContractsTableProps = {
  data: ContractExecutionResponse;
  loading: boolean;
};

export const ContractsTable = memo(({ data, loading }: ContractsTableProps) => {
  const { state, dispatch } = useContext(CurrentContext);

  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(actions.setContractsCurrentPage(page));
    },
    [dispatch],
  );

  const handleShowOnPageSelectChange = useCallback(
    (value: number) => {
      dispatch(actions.setContractsItemsPerPage(value));
    },
    [dispatch],
  );

  const getFooter: TableProps<ContractExecution>['footer'] = useCallback(() => {
    return (
      <div className={styles.tableFooter}>
        <div className={styles.paginationContainer}>
          <p className={styles.paginationText}>Страницы:</p>
          <Pagination
            current={state.contractsCurrentPage}
            total={data.count}
            pageSize={state.contractsItemsPerPage}
            showSizeChanger={false}
            onChange={handlePageChange}
            className={styles.pagination}
          />
        </div>
        <div className={styles.pageSize}>
          Показывать на странице:
          <Select
            dropdownStyle={dropdownStyle}
            variant="borderless"
            suffixIcon={<Vicon className={styles.iconV} />}
            value={state.contractsItemsPerPage}
            onChange={handleShowOnPageSelectChange}
            options={showOnPageOptions}
          />
        </div>
      </div>
    );
  }, [
    data.count,
    handlePageChange,
    handleShowOnPageSelectChange,
    state.contractsCurrentPage,
    state.contractsItemsPerPage,
  ]);

  return (
    <div className={styles.wrapper}>
      <ConfigProvider theme={theme}>
        <Table
          rowKey={'predmet'}
          loading={loading}
          columns={columns}
          dataSource={data.results}
          className={styles.table}
          pagination={false}
          footer={getFooter}
        />
      </ConfigProvider>
    </div>
  );
});

ContractsTable.displayName = 'ContractsTable';
