import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import {
  DeadlineCalculation,
  DeadlineCalculationBody,
  DeadlineCalculationContracts,
  DeadlineCalculationResponse,
} from './skpApiTypes';
import { downloadXlsx, fetcher, postData, getData } from '../commonApi/commonApi';
import { DeadlineType } from '@/widgets';

export const useGetDeadlineCalculation = (type: string) => {
  const url = `/deadline-calculation?type=${type}`;
  return useSWR(
    () => (type !== DeadlineType.CONTRACTS44 ? url : null),
    getData<DeadlineCalculationResponse>,
  );
};

export const postDeadlineCalculation = <T>(body: DeadlineCalculationBody): Promise<T> => {
  return postData<T>('/deadline-calculation/update', body);
};

export const getDeadlineCalculationXlsx = async (body: DeadlineCalculation) => {
  const response = await fetcher('/deadline-calculation/xlsx', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  await downloadXlsx(response, 'Расчёт СКП');
};

export const useGetDeadlineCalculationContracts = () => {
  const url = '/deadline-calculation/contract-stages-calculation';
  return useSWRImmutable(url, getData<DeadlineCalculationContracts>);
};

export const updateDeadlineCalculationContracts = (body: DeadlineCalculationContracts) => {
  return postData<DeadlineCalculationContracts>(
    '/deadline-calculation/contract-stages-calculation/update',
    body,
  );
};
