'use client';

import React, { memo } from 'react';
import { SummaryInfo } from '@/widgets/Summary/components';

export const SummaryPage = memo(() => {
  return (
    <div>
      <SummaryInfo />
    </div>
  );
});

SummaryPage.displayName = 'SummaryPage';
