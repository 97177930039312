import useSWR, { SWRConfiguration } from 'swr';
import { GuardResponse, GuardUpdateBody, GuardUpdateResponse } from './nmckGuardApiTypes';
import { downloadXlsx, fetcher, postData, putData, getData } from '../commonApi/commonApi';

export const postGuard = async (): Promise<GuardResponse> => {
  return postData<GuardResponse>('/nmck/guard');
};

export const putGuard = async (id: number, body: GuardUpdateBody): Promise<GuardUpdateResponse> => {
  const url = `/nmck/guard/${id}`;
  const response = await putData<GuardUpdateBody>(url, body);
  return response as unknown as GuardUpdateResponse;
};

export const getGuard = async (id: number): Promise<GuardUpdateResponse> => {
  return getData<GuardUpdateResponse>(`/nmck/guard/${id}`);
};

export const usePostGuard = () => {
  return useSWR('/nmck/guard', postGuard, {
    revalidateOnFocus: false,
  } as SWRConfiguration);
};

export const useGetGuard = (id: number) => {
  return useSWR(`/nmck/guard/${id}`, () => getGuard(id), {
    revalidateOnFocus: false,
  } as SWRConfiguration);
};

export const getGuardXlsx = async (id: number, nmckName?: string) => {
  const response = await fetcher(`/nmck/guard/${id}/xlsx`);

  const fileName = 'НМЦК_Новый расчет услуги охраны';
  await downloadXlsx(response, nmckName ? `${fileName} ${nmckName}` : fileName);
};
