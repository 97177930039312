import { KtruCharacteristic, KtruCharacteristicSelectionType } from '@/api/okpdApi/okpdApiTypes';
import { KtruCharacteristicRow } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/types';
import { columns } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/constants';

export const getMappedCharacteristicsList = (
  list: KtruCharacteristic[],
): KtruCharacteristicRow[] => {
  return list.map((item) => ({
    ...item,
    values: item.values.map(({ value, id }) => ({
      id,
      label: value,
      checked: item.selection === KtruCharacteristicSelectionType.ALWAYS_SELECTED,
    })),
  }));
};

export const copyKtruCharacteristics = (
  data: KtruCharacteristicRow[] | KtruCharacteristicRow,
  onlySelected: boolean = false,
) => {
  const isList = Array.isArray(data);
  let text = '';

  if (isList) {
    text += columns.map((c) => c.title).join('\t') + '\n';
    const requiredList = onlySelected
      ? getSelectedRows(getRowsByMandatory(data, true))
      : getRowsByMandatory(data, true);
    const optionalList = onlySelected
      ? getSelectedRows(getRowsByMandatory(data, false))
      : getRowsByMandatory(data, false);

    if (requiredList.length) {
      text += 'ОБЯЗАТЕЛЬНЫЕ ХАРАКТЕРИСТИКИ \n';
    }
    text += getRowsText(requiredList, onlySelected);

    if (optionalList.length) {
      text += 'НЕОБЯЗАТЕЛЬНЫЕ ХАРАКТЕРИСТИКИ \n';
    }
    text += getRowsText(optionalList, onlySelected);
  } else {
    text += getRowsText([data], onlySelected);
  }

  navigator.clipboard.writeText(text);
};

const getRowsText = (rows: KtruCharacteristicRow[], onlySelected: boolean) => {
  let text = '';
  rows.forEach(
    (row) =>
      (text +=
        row.name +
        '\t' +
        row.kind +
        '\t' +
        row.type +
        '\n' +
        row.values.reduce((acc, { label, checked }) => {
          if (onlySelected) {
            checked && (acc += label + '\n');
          } else {
            acc += label + '\n';
          }
          return acc;
        }, '')),
  );

  return text;
};

export const getRowsByMandatory = (rows: KtruCharacteristicRow[], onlyMandatory: boolean) => {
  if (onlyMandatory) {
    return rows.filter(({ isMandatory }) => isMandatory);
  }

  return rows.filter(({ isMandatory }) => !isMandatory);
};

export const getSelectedRows = (rows: KtruCharacteristicRow[]) => {
  return rows.filter((row) => row.values.some(({ checked }) => checked));
};
