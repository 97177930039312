import {
  BDUIFilterMultiselect,
  BDUIFilterParam,
  BDUIFilterRangeValue,
  BDUIFilterSingleValue,
} from '@/components/BDUIFilter/types';
import { isNumber, isString } from 'lodash';

export const setURLParamsByBDUIFilters = (
  urlParams: URLSearchParams,
  filters: BDUIFilterParam[],
) => {
  filters.forEach((param) => {
    if (param.value?.type === 'single') {
      return setSingleValueURLParams(urlParams, param as BDUIFilterParam<BDUIFilterSingleValue>);
    }
    if (param.value?.type === 'range') {
      return setRangeValueURLParams(urlParams, param as BDUIFilterParam<BDUIFilterRangeValue>);
    }
    if (param.value?.type === 'multiselect') {
      return setMultiselectValueURLParams(
        urlParams,
        param as BDUIFilterParam<BDUIFilterMultiselect>,
      );
    }
  });
};

const setSingleValueURLParams = (
  urlParams: URLSearchParams,
  {
    queryParamName,
    value: { value } = { type: 'single' },
    emptyValues: { emptyValue } = {},
  }: BDUIFilterParam<BDUIFilterSingleValue>,
) => {
  if (!queryParamName) {
    return;
  }
  if (isString(value) || isNumber(value)) {
    return urlParams.append(queryParamName, String(value));
  }
  if (emptyValue) {
    return urlParams.append(queryParamName, emptyValue);
  }
};

const setMultiselectValueURLParams = (
  urlParams: URLSearchParams,
  {
    queryParamName,
    value: { values } = { type: 'multiselect' },
    emptyValues: { emptyValue } = {},
  }: BDUIFilterParam<BDUIFilterMultiselect>,
) => {
  if (!queryParamName) {
    return;
  }
  if (values?.length) {
    return values.forEach((key) => {
      urlParams.append(queryParamName, String(key));
    });
  }
  if (emptyValue) {
    return urlParams.append(queryParamName, emptyValue);
  }
};

const setRangeValueURLParams = (
  urlParams: URLSearchParams,
  {
    queryParamNameTo,
    queryParamNameFrom,
    value: { min, max } = { type: 'range' },
    emptyValues: { emptyFromValue, emptyToValue } = {},
  }: BDUIFilterParam<BDUIFilterRangeValue>,
) => {
  if (queryParamNameFrom) {
    if (min) {
      urlParams.append(queryParamNameFrom, min);
    } else if (emptyFromValue) {
      urlParams.append(queryParamNameFrom, emptyFromValue);
    }
  }

  if (queryParamNameTo) {
    if (max) {
      urlParams.append(queryParamNameTo, max);
    } else if (emptyToValue) {
      urlParams.append(queryParamNameTo, emptyToValue);
    }
  }
};
