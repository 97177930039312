import { memo, ReactNode, useCallback } from 'react';
import { Button, Flex } from 'antd';
import {
  TokenBasedPaginationState,
  TokenBasedPaginationTokenValue,
} from '@/api/commonApi/commonApiTypes';
import { PageSizeSelect } from '@/components/Table/TablePagination/PageSizeSelect/PageSizeSelect';

type Props<T extends TokenBasedPaginationState> = {
  pagination: T;
  pageSize: number;
  onLoadMoreClick: (token: TokenBasedPaginationTokenValue) => void;
  onPageSizeChange: (pageSize: number) => void;
  isDataLoading: boolean;
};

const TableTokenBasedPaginationComponent = <T extends TokenBasedPaginationState>({
  pagination,
  pageSize,
  onPageSizeChange,
  onLoadMoreClick,
  isDataLoading,
}: Props<T>) => {
  const isLoadMoreButtonDisabled = !pagination.hasMoreResults || isDataLoading;

  const handleLoadMoreButtonClick = useCallback(
    () => onLoadMoreClick(pagination.token),
    [onLoadMoreClick, pagination.token],
  );

  return (
    <Flex
      gap={42}
      align="center"
    >
      <Button
        disabled={isLoadMoreButtonDisabled}
        loading={isDataLoading}
        onClick={handleLoadMoreButtonClick}
      >
        Загрузить ещё
      </Button>
      <PageSizeSelect
        onPageSizeChange={onPageSizeChange}
        pageSize={pageSize}
      />
    </Flex>
  );
};

export const TableTokenBasedPagination = memo(TableTokenBasedPaginationComponent) as <
  T extends TokenBasedPaginationState,
>(
  props: Props<T>,
) => ReactNode;
