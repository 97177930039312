import { memo, useCallback, useMemo, useState } from 'react';
import { updateNmckRow, useGetNmckById } from '@/api/nmckApi/nmckApi';
import { NmckRow } from '@/api/nmckApi/nmckApiTypes';

import { Input } from 'antd';
import styles from './AmountInput.module.scss';
import { debounce } from 'lodash';

type AmountInputProps = {
  record: NmckRow;
  id: string;
};

export const AmountInput = memo(({ record, id }: AmountInputProps) => {
  const { mutate: refreshCalculation } = useGetNmckById(id);
  const [amount, setAmount] = useState(record.amount);

  const debouncedUpdateSearch = useMemo(
    () =>
      debounce(async (value: number) => {
        if (value !== record.amount) {
          await updateNmckRow(id, { ...record, amount: value });
          refreshCalculation();
        }
      }, 500),
    [id, record, refreshCalculation],
  );

  const handleAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAmount(Number(e.target.value));
      debouncedUpdateSearch(Number(e.target.value));
    },
    [debouncedUpdateSearch],
  );

  return (
    <Input
      type="number"
      min={0}
      value={amount}
      onChange={handleAmountChange}
      className={styles.amount}
    />
  );
});
AmountInput.displayName = 'AmountInput';
