import { Characteristic } from '@/api/docsApi/docsApiTypes';

export enum ActionType {
  SET_OKPD = 'SET_OKPD',
  SET_START_DATE = 'SET_START_DATE',
  SET_END_DATE = 'SET_END_DATE',
  SET_REGIONS = 'SET_REGION',
  SET_CUSTOMERS = 'SET_CUSTOMERS',
  SET_ITEMS_PER_PAGE = 'SET_ITEMS_PER_PAGE',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_EIS = 'SET_EIS',
  SET_RANK = 'SET_RANK',
  SET_CHARACTERISTICS = 'SET_CHARACTERISTICS',
  SHOW_MODAL = 'SHOW_MODAL',
}

type ActionCreator<T extends ActionType, P> = (payload: P) => {
  type: T;
  payload: P;
};

const actionCreator = <P, T extends ActionType>(type: T) =>
  ((payload: P) => ({
    type,
    payload,
  })) as ActionCreator<T, P>;

export const actions = {
  setOKPD: actionCreator<string, ActionType.SET_OKPD>(ActionType.SET_OKPD),
  setStartDate: actionCreator<string, ActionType.SET_START_DATE>(ActionType.SET_START_DATE),
  setEndDate: actionCreator<string, ActionType.SET_END_DATE>(ActionType.SET_END_DATE),
  setRegion: actionCreator<number[], ActionType.SET_REGIONS>(ActionType.SET_REGIONS),
  setCustomers: actionCreator<number[], ActionType.SET_CUSTOMERS>(ActionType.SET_CUSTOMERS),
  setItemsPerPage: actionCreator<number, ActionType.SET_ITEMS_PER_PAGE>(
    ActionType.SET_ITEMS_PER_PAGE,
  ),
  setCurrentPage: actionCreator<number, ActionType.SET_CURRENT_PAGE>(ActionType.SET_CURRENT_PAGE),
  setEis: actionCreator<number | undefined, ActionType.SET_EIS>(ActionType.SET_EIS),
  setRank: actionCreator<string | undefined, ActionType.SET_RANK>(ActionType.SET_RANK),
  setCharacteristic: actionCreator<Characteristic[] | undefined, ActionType.SET_CHARACTERISTICS>(
    ActionType.SET_CHARACTERISTICS,
  ),
  showModal: actionCreator<boolean, ActionType.SHOW_MODAL>(ActionType.SHOW_MODAL),
};

export type Actions = ReturnType<(typeof actions)[keyof typeof actions]>;
