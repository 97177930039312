import { Dispatch, memo, SetStateAction, useCallback, useMemo } from 'react';
import styles from './KtruDetailTabsCharacteristicsHeader.module.scss';
import commonStyles from '../KtruDetailTabsCharacteristics.module.scss';
import { Button } from 'antd';
import CopyIcon from '/public/icons/copy.svg';
import CrossIcon from '/public/icons/cross.svg';
import { columns } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/constants';
import cx from 'classnames';
import { KtruCharacteristicRow } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/types';
import {
  copyKtruCharacteristics,
  getSelectedRows,
} from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/utils';
import { eventsGTM, sendEventGTM } from '@/metrika';

type Props = {
  list: KtruCharacteristicRow[];
  setList: Dispatch<SetStateAction<KtruCharacteristicRow[]>>;
};

export const KtruDetailTabsCharacteristicsHeader = memo(({ list, setList }: Props) => {
  const hasSelectedCharacteristics = useMemo(() => getSelectedRows(list).length > 0, [list]);

  const handleClearAllSelected = useCallback(() => {
    setList((prev) =>
      prev.map((row) => ({
        ...row,
        values: row.values.map((value) => ({ ...value, checked: false })),
      })),
    );
  }, [setList]);

  const handleCopyAll = useCallback(() => {
    sendEventGTM(eventsGTM.copyCharacteristicsAll);
    copyKtruCharacteristics(list);
  }, [list]);

  const handleCopySelected = useCallback(() => {
    sendEventGTM(eventsGTM.copyCharacteristicsSelected);
    copyKtruCharacteristics(list, true);
  }, [list]);

  return (
    <div className={styles.header}>
      <div className={cx(styles.columns, commonStyles.columnGrid)}>
        {columns.map((column) => (
          <div
            key={column.key}
            className={styles.column}
          >
            {column.title}
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <Button
          icon={<CopyIcon />}
          type="link"
          className={styles.button}
          onClick={handleCopyAll}
        >
          Скопировать все
        </Button>
        <Button
          icon={<CopyIcon />}
          type="link"
          className={styles.button}
          disabled={!hasSelectedCharacteristics}
          onClick={handleCopySelected}
        >
          Скопировать выбранные
        </Button>
        <Button
          icon={<CrossIcon />}
          type="link"
          className={styles.button}
          disabled={!hasSelectedCharacteristics}
          onClick={handleClearAllSelected}
        >
          Снять отметки
        </Button>
      </div>
    </div>
  );
});

KtruDetailTabsCharacteristicsHeader.displayName = 'KtruDetailTabsCharacteristicsHeader';
