'use client';
import { memo, useContext } from 'react';
import { ReestrAllPageHeader, ReestrAllPageResults } from '@/widgets/ReestrAll/components';
import { useGetEisAllContracts } from '@/api/reestrAllApi/reestrAllApi';
import { ReestrAllContext } from '@/widgets/ReestrAll/context/context';

export const ReestrAllPage = memo(() => {
  const { state } = useContext(ReestrAllContext);
  const { data: contracts, isLoading: isContractsLoading } = useGetEisAllContracts(state);
  return (
    <>
      <ReestrAllPageHeader
        filtersResponse={contracts?.filters}
        isContractsLoading={isContractsLoading}
      />
      <ReestrAllPageResults
        contracts={contracts}
        isContractsLoading={isContractsLoading}
      />
    </>
  );
});
ReestrAllPage.displayName = 'ReestrAllPage';
