import { memo, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { splitNmckById, updateNmck, useGetNmckById } from '@/api/nmckApi/nmckApi';
import { useRouter } from 'next/navigation';
import { BadgeType } from '@/api/nmckApi/nmckApiTypes';
import { setSplittedNmckIds } from '@/utils';
import { pageRoutes } from '@/app/pageRoutes';
import styles from './NmckGridHeader.module.scss';
import { Button, Popover, Tooltip } from 'antd';
import cx from 'classnames';
import Link from 'next/link';
import New from '/public/icons/new.svg';
import Export from '/public/icons/export.svg';
import Question from '/public/icons/questionRound.svg';
import Warning from '/public/icons/warning.svg';
import Cross from '/public/icons/cross.svg';
import { UiSwitch } from '@/components/Ui';
import { IncompatibleModal } from '@/components/NmckGrid/IncompatibleModal/IncompatibleModal';
import { DebounceInput } from '@/components';
import { eventsGTM, sendEventGTM } from '@/metrika';

type Props = {
  id: string;
  newCalculationLink: (typeof pageRoutes)['nmckAnalysis'] | (typeof pageRoutes)['nmckMedDevices'];
} & PropsWithChildren;

export const NmckGridHeader = memo(({ id, children, newCalculationLink }: Props) => {
  const { data, mutate } = useGetNmckById(id);
  const [name, setName] = useState(data?.name ?? '');
  const [responsible, setResponsible] = useState(data?.responsible ?? '');
  const [undeterminedAmount, setUndeterminedAmount] = useState(false);
  const [incompatibleModalOpen, setIncompatibleModalOpen] = useState(false);
  const [modalKey, setModalKey] = useState(0);

  const router = useRouter();

  useEffect(() => {
    setName(data?.name ?? '');
    setResponsible(data?.responsible ?? '');
  }, [data]);

  const nameChange = useCallback(
    async (value: string) => {
      setName(value);
      if (data) {
        sendEventGTM(eventsGTM.nmckNameChange, { value });
        await updateNmck({ ...data, name: value });
        await mutate();
        setModalKey(Math.random());
      }
    },
    [data, mutate],
  );

  const responsibleChange = useCallback(
    async (value: string) => {
      setResponsible(value);
      if (data) {
        sendEventGTM(eventsGTM.nmckResponsibleChange, { value });
        await updateNmck({ ...data, responsible: value });
        await mutate();
        setModalKey(Math.random());
      }
    },
    [data, mutate],
  );

  const handleModal = useCallback(() => {
    setIncompatibleModalOpen(!incompatibleModalOpen);
  }, [incompatibleModalOpen]);

  const hasIncompatibleCode = data?.badge === BadgeType.HAS_INCOMPATIBLE_CODES;

  const handleSplit = useCallback(async () => {
    const splitIds = await splitNmckById(id);
    setSplittedNmckIds(splitIds);
    router.push(pageRoutes.nmckHistory);
  }, [id, router]);

  const handleChangeUndeterminedAmount = useCallback((value: boolean) => {
    setUndeterminedAmount(value);
    sendEventGTM(eventsGTM.nmckChangeUndeterminedAmount, { value });
  }, []);

  const handleImportClick = useCallback(() => {
    sendEventGTM(eventsGTM.nmckImportClick);
  }, []);

  const handleNewCalculationClick = useCallback(() => {
    sendEventGTM(eventsGTM.nmckNewCalculationClick);
  }, []);
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <div className={styles.title}>{children}</div>
          <Tooltip
            arrow={false}
            title={name}
            placement="bottom"
          >
            <DebounceInput
              value={name}
              handleValueChange={nameChange}
              className={cx('input-line', styles.nameInput)}
              placeholder="Название"
              variant="borderless"
              disabled={!data}
            />
          </Tooltip>
          <Tooltip
            arrow={false}
            title={responsible}
            placement="bottom"
          >
            <DebounceInput
              value={responsible}
              handleValueChange={responsibleChange}
              className={cx('input-line', styles.responsibleInput)}
              placeholder="Ответственный"
              variant="borderless"
              disabled={!data}
            />
          </Tooltip>
        </div>
        <div className={styles.right}>
          {id && (
            <Link
              href={newCalculationLink}
              onClick={handleNewCalculationClick}
            >
              <Button
                icon={<New />}
                className={styles.newCalculation}
              >
                Новый расчёт
              </Button>
            </Link>
          )}
          <Button
            type="link"
            icon={<Export />}
            className={styles.import}
            onClick={handleImportClick}
          >
            Импортировать
          </Button>
          <UiSwitch
            value={undeterminedAmount}
            onChange={handleChangeUndeterminedAmount}
          >
            <div>Неопределённое кол-во ТРУ</div>
            <Popover
              placement="bottom"
              arrow={false}
              content="Расчёт НМЦК для закупок с неопределенным объемом согласно ч. 24 ст. 22 44-ФЗ"
            >
              <Question className="hint-icon" />
            </Popover>
          </UiSwitch>
        </div>
      </div>
      {data && data.badge !== BadgeType.NO_BADGE && (
        <>
          <div
            className={cx(styles.warning, {
              [styles.warningBorder]: hasIncompatibleCode,
            })}
          >
            <div className={styles.warningText}>
              <Warning className={!hasIncompatibleCode ? styles.warningIcon : undefined} />
              {hasIncompatibleCode
                ? 'Несовместимые коды ОКПД2/КТРУ.'
                : 'В закупке содержатся коды ОКПД2/КТРУ, которые попадают под ограничения и запреты.'}
            </div>
            <div
              className={styles.more}
              onClick={handleModal}
            >
              Подробнее
            </div>
            {hasIncompatibleCode && (
              <div
                className={styles.split}
                onClick={handleSplit}
              >
                Разделить закупку
              </div>
            )}
            <Cross className={styles.cross} />
          </div>
          <IncompatibleModal
            nmckId={id}
            modalKey={modalKey}
            open={incompatibleModalOpen}
            name={name}
            onCancel={handleModal}
          />
        </>
      )}
    </>
  );
});

NmckGridHeader.displayName = 'NmckGridHeader';
