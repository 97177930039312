'use client';

import React, { memo } from 'react';
import styles from './PenaltiesCalcPage.module.scss';
import { PenaltiesFormula, PenaltiesCalculator } from '@/widgets/PenaltiesCalc/components';
import { Flex } from 'antd';

export const PenaltiesCalcPage = memo(() => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Калькулятор пеней, начисляемых поставщику по 44-Ф3</p>
      <Flex className={styles.main}>
        <PenaltiesCalculator />
        <PenaltiesFormula />
      </Flex>
    </div>
  );
});

PenaltiesCalcPage.displayName = 'PenaltiesCalcPage';
