import { Actions, ActionType } from './actions';

export interface CurrentParams {
  plannedItemsPerPage: number;
  plannedCurrentPage: number;
  processItemsPerPage: number;
  processCurrentPage: number;
  contractsItemsPerPage: number;
  contractsCurrentPage: number;
}

export const initialState: CurrentParams = {
  plannedItemsPerPage: 10,
  plannedCurrentPage: 1,
  processItemsPerPage: 10,
  processCurrentPage: 1,
  contractsItemsPerPage: 10,
  contractsCurrentPage: 1,
};

export const currentReducer = (
  state: CurrentParams = initialState,
  action: Actions,
): CurrentParams => {
  switch (action.type) {
    case ActionType.SET_PLANNED_ITEMS_PER_PAGE:
      return { ...state, plannedItemsPerPage: action.payload };
    case ActionType.SET_PLANNED_CURRENT_PAGE:
      return { ...state, plannedCurrentPage: action.payload };
    case ActionType.SET_PROCESS_ITEMS_PER_PAGE:
      return { ...state, processItemsPerPage: action.payload };
    case ActionType.SET_PROCESS_CURRENT_PAGE:
      return { ...state, processCurrentPage: action.payload };
    case ActionType.SET_CONTRACTS_ITEMS_PER_PAGE:
      return { ...state, contractsItemsPerPage: action.payload };
    case ActionType.SET_CONTRACTS_CURRENT_PAGE:
      return { ...state, contractsCurrentPage: action.payload };
    default:
      return state;
  }
};
