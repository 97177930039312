import React, { createContext, useReducer } from 'react';
import { currentReducer, initialState, CurrentParams } from './reducer';
import { Actions } from './actions';

const CurrentContext = createContext<{
  state: CurrentParams;
  dispatch: React.Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const CurrentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(currentReducer, initialState);

  return <CurrentContext.Provider value={{ state, dispatch }}>{children}</CurrentContext.Provider>;
};

export { CurrentContext, CurrentProvider };
