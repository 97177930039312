import { memo } from 'react';

import styles from './CharacteristicItem.module.scss';

import cx from 'classnames';

type CharacteristicItemProps = {
  name: string;
  value: string;
  overflowed?: boolean;
  className?: string;
};
export const CharacteristicItem = memo(
  ({ overflowed, className, name, value }: CharacteristicItemProps) => {
    return (
      <p className={cx(className, styles.characteristic)}>
        <span
          className={cx(styles.characteristicName, {
            [styles.overflowed]: overflowed,
          })}
        >
          {name}:{' '}
        </span>
        <span
          className={cx(styles.characteristicValue, {
            [styles.overflowed]: overflowed,
          })}
        >
          {value}
        </span>
      </p>
    );
  },
);

CharacteristicItem.displayName = 'CharacteristicItem';
