import useSWR from 'swr';
import { GpbTokenUpdateBody, TokenUpdateResponse, User } from '@/api/userApi/userApiTypes';
import { postData, getData } from '@/api/commonApi/commonApi';
import { saveGPBToken, saveUserIdToStorage } from '@/api/userApi/userApiUtils';
import { saveLogMetricsFlagToStorage } from '@/metrika';

export const useUserInfo = () =>
  useSWR('/current-user-info', getData<User>, {
    revalidateIfStale: false,
  });

export const updateToken = async (body: GpbTokenUpdateBody) => {
  const { token, logMetrics, userId } = await postData<TokenUpdateResponse>(
    '/token-from-gpb-creds',
    body,
  );
  saveGPBToken(token);
  saveLogMetricsFlagToStorage(logMetrics);
  saveUserIdToStorage(userId);
};
