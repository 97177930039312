import { memo, useCallback, useState, useEffect, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useParams } from 'next/navigation';
import { omit, uniqueId } from 'lodash';
import PlusIcon from '/public/icons/plus.svg';

import {
  Characteristic,
  CharacteristicType,
  PurchaseItem,
} from '@/api/planningCurrentApi/planningCurrentApiTypes';
import { updateCurrentPurchaseItem } from '@/api/planningCurrentApi/planningCurrentApi';
import { CharsItem } from './components/CharsItem/CharsItem';

import styles from './AddCharsModal.module.scss';

type AddCharsModalProps = {
  isOpenModal: boolean;
  closeModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
  record: PurchaseItem;
  refreshPurchaseById: () => void;
};

export const AddCharsModal = memo(
  ({ isOpenModal, record, closeModal, refreshPurchaseById }: AddCharsModalProps) => {
    const params = useParams();

    const initialState: Characteristic[] = useMemo(
      () => [
        {
          id: Number(uniqueId()),
          name: '',
          type: CharacteristicType.TEXT,
          textValue: null,
          numValue: 0,
          leftRange: 0,
          rightRange: 0,
          leftRangeSign: null,
          rightRangeSign: null,
        },
      ],
      [],
    );

    const [chars, setChars] = useState(record.chars.length ? record.chars : initialState);

    useEffect(() => {
      record.chars.length ? setChars(record.chars) : setChars(initialState);
    }, [record, initialState]);

    const save = useCallback(
      async (e: React.MouseEvent<HTMLButtonElement>) => {
        const updateBody = omit(record, ['id']);
        await updateCurrentPurchaseItem(params.id, record.id, {
          ...updateBody,
          chars: chars,
        });
        closeModal(e);
        refreshPurchaseById();
      },
      [closeModal, params.id, record, chars, refreshPurchaseById],
    );

    const addCharItem = useCallback(() => {
      setChars([
        ...chars,
        {
          id: Number(uniqueId()),
          name: '',
          type: CharacteristicType.TEXT,
          textValue: null,
          numValue: 0,
          leftRange: 0,
          rightRange: 0,
          leftRangeSign: null,
          rightRangeSign: null,
        },
      ]);
    }, [chars]);

    const deleteChar = useCallback((id: number) => {
      setChars((prevChars) => prevChars.filter((char) => char.id !== id));
    }, []);

    const updateChar = useCallback((id: number, updatedChar: Characteristic) => {
      setChars((prevChars) => prevChars.map((char) => (char.id === id ? updatedChar : char)));
    }, []);

    return (
      <Modal
        destroyOnClose
        title={'Характеристики'}
        open={isOpenModal}
        onCancel={closeModal}
        centered
        wrapClassName={styles.modal}
        width={1536}
        footer={[
          <Button
            className={styles.cancelBtn}
            key="cancel"
            onClick={closeModal}
          >
            Отмена
          </Button>,
          <Button
            className={styles.submitBtn}
            key="submit"
            type="primary"
            onClick={save}
          >
            Сохранить
          </Button>,
        ]}
      >
        <div className={styles.content}>
          <div className={styles.wrapper}>
            <div className={styles.text}>
              <div className={styles.name}>Наименование</div>
              <div className={styles.type}>Тип</div>
              <div className={styles.value}>Значение</div>
            </div>
            {chars.map((char) => (
              <CharsItem
                key={char.id}
                char={char}
                updateChar={updateChar}
                deleteChar={deleteChar}
              />
            ))}
            <Button
              className={styles.addCharItemButton}
              icon={<PlusIcon />}
              onClick={addCharItem}
            >
              Добавить
            </Button>
          </div>
        </div>
      </Modal>
    );
  },
);
AddCharsModal.displayName = 'AddCharsModal';
