'use client';
import { memo, useEffect } from 'react';

import { usePostGuard } from '@/api/nmckGuardApi/nmckGuardApi';
import { useRouter } from 'next/navigation';
import Vicon from '/public/icons/v.svg';
import VUpicon from '/public/icons/vUp.svg';
import { pageRoutes } from '@/app/pageRoutes';

export const controls = {
  upIcon: <VUpicon />,
  downIcon: <Vicon />,
};

export const GuardPage = memo(() => {
  const { data: guardId } = usePostGuard();
  const router = useRouter();
  useEffect(() => {
    guardId && router.push(`${pageRoutes.nmckGuard}/${guardId.id}`);
  }, [guardId, router]);

  return null;
});
GuardPage.displayName = 'GuardPage';
