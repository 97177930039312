import { KtruCharacteristicsColumnType } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/types';

export const columns: KtruCharacteristicsColumnType[] = [
  {
    title: 'Название',
    key: 'name',
  },
  {
    title: 'Вид',
    key: 'valuesCount',
  },
  {
    title: 'Тип',
    key: 'type',
  },
  {
    title: 'Значения',
    key: 'values',
  },
];
