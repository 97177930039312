'use client';
import { memo, useContext } from 'react';

import { PlanningAllPageHeader, PlanningAllPageResults } from '@/widgets/PlanningAll/components';
import { useGetPlanningPurchases } from '@/api/planningAllApi/planningAllApi';
import { PlanningAllContext } from '../context/context';

export const PlanningAllPage = memo(() => {
  const { state } = useContext(PlanningAllContext);
  const {
    data: purchases,
    isLoading: isPurchasesLoading,
    mutate: refreshPurchases,
  } = useGetPlanningPurchases(state);

  return (
    <>
      <PlanningAllPageHeader
        filtersResponse={purchases?.filters}
        isPurchasesLoading={isPurchasesLoading}
        refreshPurchases={refreshPurchases}
      />
      <PlanningAllPageResults
        purchases={purchases}
        isPurchasesLoading={isPurchasesLoading}
        refreshPurchases={refreshPurchases}
      />
    </>
  );
});
PlanningAllPage.displayName = 'PlanningAllPage';
