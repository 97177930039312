import { RangeSign } from '@/api/planningCurrentApi/planningCurrentApiTypes';

export const getRangeSign = (textSign: string) => {
  switch (textSign) {
    case RangeSign.LESS:
      return '<';
    case RangeSign.MORE:
      return '>';
    case RangeSign.MORE_EQUAL:
      return '≥';
    default:
      return '≤';
  }
};
