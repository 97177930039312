import {
  NmckCompatibilityCell,
  NmckCompatibilityRow,
  NPACellType,
} from '@/api/nmckApi/nmckApiTypes';
import { memo, useCallback, useMemo } from 'react';
import { Flex, Tooltip } from 'antd';
import cx from 'classnames';

import CheckRound from '/public/icons/checkRoundNotFilled.svg';
import Question from '/public/icons/questionRound.svg';
import Vicon from '/public/icons/v.svg';
import RestrictIcon from '/public/icons/restrictNotFilled.svg';

import styles from './CompatibilityTableCell.module.scss';

type Props = {
  cell: NmckCompatibilityCell;
  record: NmckCompatibilityRow;
  onClick: (record: NmckCompatibilityRow, cell: NmckCompatibilityCell) => void;
  currentExpandRow?: number;
  currentExpandCell?: NmckCompatibilityCell;
};

export const CompatibilityTableCell = memo(
  ({ cell, record, onClick, currentExpandRow, currentExpandCell }: Props) => {
    const type = cell.type;

    const isCellHasExpandContent = useMemo(
      () => !!(cell.text && [NPACellType.SELECT, NPACellType.APPLIES_WITH_DETAILS].includes(type)),
      [cell.text, type],
    );
    const isCellApplied = useMemo(
      () => [NPACellType.APPLIES, NPACellType.SELECT_ACCEPTED].includes(type),
      [type],
    );
    const isCellRejected = type === NPACellType.SELECT_REJECTED;

    const handleClick = useCallback(() => {
      onClick(record, cell);
    }, [record, cell, onClick]);

    if (type === NPACellType.EMPTY) return null;

    const active =
      currentExpandRow === record.id && currentExpandCell?.columnCode === cell.columnCode;

    return (
      <>
        {isCellHasExpandContent && (
          <Tooltip
            title={<span className={styles.toooltipText}>{'Показать применение НПА'}</span>}
            trigger={'hover'}
            placement="bottom"
          >
            <Flex
              onClick={handleClick}
              align="center"
              gap={8}
              className={cx(styles.show, {
                [styles.showActive]: active,
              })}
            >
              <Question />
              <Vicon className={active ? styles.viconActive : undefined} />
            </Flex>
          </Tooltip>
        )}
        {isCellApplied && (
          <Flex align="center">
            <CheckRound
              className={cx({
                [styles.checkApplied]: type === NPACellType.APPLIES,
                [styles.checkAppliedWithSelection]: type === NPACellType.SELECT_ACCEPTED,
              })}
            />
          </Flex>
        )}
        {isCellRejected && (
          <Flex align="center">
            <RestrictIcon className={styles.rejectWithSelection} />
          </Flex>
        )}
      </>
    );
  },
);

CompatibilityTableCell.displayName = 'CompatibilityTableCell';
