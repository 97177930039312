import { Dispatch, memo, SetStateAction, useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import { Flex, List, Tooltip } from 'antd';
import Cross from '/public/icons/cross.svg';
import { ProceduresItem } from './components/ProceduresItem/ProceduresItem';
import { DeadlineCalculation, DeadlineCalculationResponse, Stage } from '@/api/skpApi/skpApiTypes';

import styles from './Procedures.module.scss';

const crossTitle = 'Сбросить выбранные';
type ProceduresProps = {
  currentCalculation: DeadlineCalculation;
  setDeadlineCalculationData: Dispatch<SetStateAction<DeadlineCalculationResponse>>;
  initialCalculationData?: DeadlineCalculationResponse;
};
export type HighlightedDateState = {
  highlightedDateId: number | undefined;
  highlightedFrom: number | undefined;
  isFrom: boolean;
};

export const Procedures = memo(
  ({ currentCalculation, setDeadlineCalculationData, initialCalculationData }: ProceduresProps) => {
    const [highlightedDateState, setHighlightedDateState] = useState<HighlightedDateState>({
      highlightedDateId: undefined,
      highlightedFrom: undefined,
      isFrom: true,
    });
    const [isVisibleResetCross, setVisibleResetCross] = useState(false);

    const resetCalculation = useCallback(() => {
      setVisibleResetCross(false);
      setDeadlineCalculationData(initialCalculationData!);
    }, [initialCalculationData, setDeadlineCalculationData]);

    const visibleStages = useMemo(() => {
      return currentCalculation.stages.reduce<React.ReactNode[]>((acc, stage: Stage) => {
        if (stage.visibility) {
          acc.push(
            <ProceduresItem
              key={stage.id}
              stage={stage}
              currentCalculation={currentCalculation}
              setDeadlineCalculationData={setDeadlineCalculationData}
              highlightedDateState={highlightedDateState}
              setHighlightedDateState={setHighlightedDateState}
              setVisibleResetCross={setVisibleResetCross}
            />,
          );
        }
        return acc;
      }, []);
    }, [currentCalculation, highlightedDateState, setDeadlineCalculationData]);

    return (
      <div className={styles.procedures}>
        <div className={styles.grid}>
          <div>Этап</div>
          <Flex
            align="center"
            gap={8}
          >
            <div>Дата</div>
            {isVisibleResetCross && (
              <Tooltip
                arrow={false}
                title={crossTitle}
                placement="bottom"
              >
                <div
                  className={cx(styles.icon, styles.crossIcon)}
                  onClick={resetCalculation}
                >
                  <Cross />
                </div>
              </Tooltip>
            )}
          </Flex>
          <Flex
            align="center"
            gap={8}
          >
            <div>Допустимый период </div>
          </Flex>
          <div>Норма закона</div>
        </div>
        <List>{visibleStages}</List>
      </div>
    );
  },
);
Procedures.displayName = 'Procedures';
