import { memo, useCallback, useState } from 'react';
import { Button, Flex } from 'antd';
import moment, { Moment } from 'moment';

import { UiDatePicker } from '@/components';
import { dateFormat } from '@/constants';
import { SgosDisplay, ChartsInfo } from '@/widgets/Summary/components';

import styles from './SummaryInfo.module.scss';

const [currentYear, pastYear] = [moment().year(), moment().year() - 1];

export const SummaryInfo = memo(() => {
  const [startDate, setStartDate] = useState<Moment | null>(moment().startOf('year'));
  const [endDate, setEndDate] = useState<Moment | null>(moment().endOf('year'));

  const [dateButtonsState, setDateButtonsState] = useState({
    isPastYearDisabled: false,
    isCurrentYearDisable: true,
    isLastHalfDisabled: false,
  });

  const resetButtonState = useCallback(() => {
    setDateButtonsState({
      isPastYearDisabled: false,
      isCurrentYearDisable: false,
      isLastHalfDisabled: false,
    });
  }, []);

  const handleDateFromChange = useCallback(
    (value: Moment) => {
      setStartDate(value);

      resetButtonState();
    },
    [resetButtonState],
  );

  const handleDateToChange = useCallback(
    (value: Moment) => {
      setEndDate(value);

      resetButtonState();
    },
    [resetButtonState],
  );

  const periodChange = useCallback((period: number, year?: number) => {
    if (!year) {
      const endDate = moment();
      const startDate = moment(endDate).subtract(period, 'months');

      setStartDate(startDate);
      setEndDate(endDate);

      setDateButtonsState({
        isCurrentYearDisable: false,
        isPastYearDisabled: false,
        isLastHalfDisabled: period === 6,
      });
    } else {
      const startDate =
        year === currentYear
          ? moment().startOf('year')
          : moment().subtract(1, 'year').startOf('year');
      const endDate =
        year === currentYear ? moment().endOf('year') : moment().subtract(1, 'year').endOf('year');

      setStartDate(startDate);
      setEndDate(endDate);

      setDateButtonsState({
        isCurrentYearDisable: year === currentYear,
        isPastYearDisabled: year === pastYear,
        isLastHalfDisabled: false,
      });
    }
  }, []);

  const handlePastYearClick = useCallback(() => {
    periodChange(12, pastYear);
  }, [periodChange]);

  const handleCurrentYearClick = useCallback(() => {
    periodChange(12, currentYear);
  }, [periodChange]);

  const handleLastHalfClick = useCallback(() => {
    periodChange(6);
  }, [periodChange]);

  return (
    <div className={styles.wrapper}>
      <Flex
        gap={64}
        className={styles.header}
      >
        <div className={styles.title}>Сводные данные</div>
        <Flex
          className={styles.dates}
          gap={20}
        >
          <UiDatePicker
            placeholder="Дата от"
            className={styles.date}
            value={startDate}
            onChange={handleDateFromChange}
          />
          <UiDatePicker
            placeholder="Дата до"
            className={styles.date}
            value={endDate}
            onChange={handleDateToChange}
          />
          <div className={styles.choosePeriod}>
            <Button
              disabled={dateButtonsState.isPastYearDisabled}
              type="text"
              onClick={handlePastYearClick}
            >
              <div
                className={
                  dateButtonsState.isPastYearDisabled ? styles.periodDisabled : styles.period
                }
              >
                {pastYear}
              </div>
            </Button>
            <Button
              disabled={dateButtonsState.isCurrentYearDisable}
              type="text"
              onClick={handleCurrentYearClick}
            >
              <div
                className={
                  dateButtonsState.isCurrentYearDisable ? styles.periodDisabled : styles.period
                }
              >
                {currentYear}
              </div>
            </Button>
            <Button
              disabled={dateButtonsState.isLastHalfDisabled}
              type="text"
              onClick={handleLastHalfClick}
            >
              <div
                className={
                  dateButtonsState.isLastHalfDisabled ? styles.periodDisabled : styles.period
                }
              >
                Последние полгода
              </div>
            </Button>
          </div>
        </Flex>
      </Flex>
      <Flex
        gap={32}
        className={styles.main}
      >
        {startDate && endDate && (
          <>
            <SgosDisplay
              dateFrom={startDate.format(dateFormat.serverFormat)}
              dateTo={endDate.format(dateFormat.serverFormat)}
            />
            <ChartsInfo
              dateFrom={startDate.format(dateFormat.serverFormat)}
              dateTo={endDate.format(dateFormat.serverFormat)}
            />
          </>
        )}
      </Flex>
    </div>
  );
});

SummaryInfo.displayName = 'SummaryInfo';
