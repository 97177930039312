import { memo, ReactNode } from 'react';
import { Flex, Pagination } from 'antd';
import styles from './TablePagination.module.scss';
import {
  PageNumberChangeCallbackType,
  PageSizeChangeCallbackType,
  PaginationType,
} from '@/components/Table/TablePagination/types';
import { isPaginationV1 } from '@/components/Table/TablePagination/utils';
import { PageSizeSelect } from '@/components/Table/TablePagination/PageSizeSelect/PageSizeSelect';

type Props<T extends PaginationType> = {
  filter: T;
  onPageNumberChange: PageNumberChangeCallbackType;
  onPageSizeChange: PageSizeChangeCallbackType;
  count: number;
};

const paginationTotalText = () => <div>Страницы: </div>;

const TablePaginationComponent = <T extends PaginationType>({
  filter,
  count,
  onPageNumberChange,
  onPageSizeChange,
}: Props<T>) => {
  return (
    <Flex
      gap={42}
      align="center"
    >
      <Pagination
        className={styles.pagination}
        showTotal={paginationTotalText}
        showSizeChanger={false}
        total={count}
        current={isPaginationV1(filter) ? filter.pageNumber : filter.page}
        pageSize={isPaginationV1(filter) ? filter.pageSize : filter.count}
        onChange={onPageNumberChange}
      />
      <PageSizeSelect
        onPageSizeChange={onPageSizeChange}
        pageSize={isPaginationV1(filter) ? filter.pageSize : filter.count}
      />
    </Flex>
  );
};

export const TablePagination = memo(TablePaginationComponent) as <T extends PaginationType>(
  props: Props<T>,
) => ReactNode;
