import { memo, useCallback, useEffect, useState } from 'react';
import { Stage } from './components/Stage/Stage';
import { Button, Flex } from 'antd';
import Plus from '/public/icons/plus.svg';

import { useGetDeadlineCalculationContracts } from '@/api/skpApi/skpApi';
import { DeadlineCalculationContracts } from '@/api/skpApi/skpApiTypes';

import styles from './Contracts.module.scss';
import { LoadingWrapper } from '@/components/Ui';

export const Contracts = memo(() => {
  const [stages, setStages] = useState<DeadlineCalculationContracts[]>([]);
  const {
    data: stagesData,
    mutate: refreshStagesData,
    isLoading,
  } = useGetDeadlineCalculationContracts();

  useEffect(() => {
    if (stagesData) {
      setStages((prev) => [...prev, stagesData]);
    }
  }, [stagesData]);

  const handleAddStage = useCallback(() => {
    refreshStagesData();
  }, [refreshStagesData]);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Flex
        gap={24}
        vertical
      >
        <Flex
          gap={56}
          vertical
        >
          {stages.map((stage, index) => (
            <Stage
              key={stage.stageNumber}
              steps={stage.steps}
              executionFinish={stage.executionFinish}
              index={index + 1}
              stage={stage}
            />
          ))}
        </Flex>
        <div className={styles.addStage}>
          <Button
            type="text"
            icon={<Plus />}
            className={styles.addBtn}
            onClick={handleAddStage}
          >
            Добавить этап
          </Button>
        </div>
      </Flex>
    </LoadingWrapper>
  );
});
Contracts.displayName = 'Contracts';
