import { updateNmckRow, useGetNmckById } from '@/api/nmckApi/nmckApi';
import { NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { Input } from 'antd';
import { debounce } from 'lodash';
import { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import styles from './NameInput.module.scss';
import { eventsGTM, sendEventGTM } from '@/metrika';

type NameInputProps = {
  record: NmckRow;
  id: string;
};

export const NameInput = memo(({ record, id }: NameInputProps) => {
  const { mutate: refreshCalculation } = useGetNmckById(id);
  const [name, setName] = useState(record.name);

  const debouncedPutData = useMemo(
    () =>
      debounce(async (name: string) => {
        sendEventGTM(eventsGTM.nmckRowNameChange, { value: name });
        await updateNmckRow(id, { ...record, name });
        refreshCalculation();
      }, 500),
    [record, refreshCalculation, id],
  );

  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const searchstring = e.target.value;
      setName(searchstring);
      debouncedPutData(searchstring);
    },

    [debouncedPutData],
  );
  return (
    <div className={styles.nameInput}>
      <Input
        value={name}
        onChange={handleChange}
      />
    </div>
  );
});
NameInput.displayName = 'NameInput';
