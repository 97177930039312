import { Table, Tooltip } from 'antd';
import { memo, useMemo } from 'react';
import styles from './ReestrAllPageResultsTable.module.scss';
import { EisAllContractsResponse, EisContractItem } from '@/api/reestrAllApi/reestrAllApiTypes';
import { TableProps } from 'antd/lib';
import { Columns, TotalRow } from '../data/data';
import { ColumnType } from 'antd/es/table';
import { formatNumber } from '@/utils/formatNumber';
import moment from 'moment';
import Link from 'antd/es/typography/Link';

const scroll = { x: 'max-content' };

const renderTooltip = (text: string | undefined, className: string) => (
  <Tooltip
    title={text}
    arrow={false}
    placement="bottom"
  >
    <div className={className}>{text}</div>
  </Tooltip>
);

const renderFormattedNumber = (value: number | undefined, className: string) => (
  <div className={className}>{formatNumber(value ? value : 0)}</div>
);

const renderFormattedDate = (date: string, name: string | undefined, className: string) =>
  !name?.startsWith('Итого') &&
  date && <div className={className}>{moment(date).format('DD.MM.YYYY')}</div>;

const renderLink = (text: string, href: string, className: string) => (
  <Link
    href={href}
    target="_blank"
    className={className}
  >
    {text}
  </Link>
);

const renderBoolean = (value: boolean, name: string | undefined, className: string) =>
  !name?.startsWith('Итого') && <div className={className}>{value ? 'Да' : 'Нет'}</div>;

const resultsTableColumnsWithRender = (
  selectedColumns: TableProps<EisContractItem>['columns'],
): TableProps<EisContractItem>['columns'] => {
  return selectedColumns?.map((column: ColumnType<EisContractItem>) => {
    switch (column.dataIndex) {
      case Columns.NAME:
        return {
          ...column,
          render: (_: unknown, { name }: EisContractItem) => renderTooltip(name, styles.name),
        };
      case Columns.NMCK:
        return {
          ...column,
          render: (_: unknown, { nmck }: EisContractItem) =>
            renderFormattedNumber(nmck, styles.nmck),
        };
      case Columns.PURCHASE_TYPE:
        return {
          ...column,
          render: (_: unknown, { purchaseType }: EisContractItem) =>
            renderTooltip(purchaseType, styles.purchaseType),
        };
      case Columns.PUBLISHED:
        return {
          ...column,
          render: (_: unknown, { published, name }: EisContractItem) =>
            renderFormattedDate(published, name, styles.published),
        };
      case Columns.RESULT:
        return {
          ...column,
          render: (_: unknown, { result }: EisContractItem) => (
            <div className={styles.result}>{result}</div>
          ),
        };
      case Columns.SIGNED:
        return {
          ...column,
          render: (_: unknown, { signed, name }: EisContractItem) =>
            renderFormattedDate(signed, name, styles.signed),
        };
      case Columns.SUPPLIER:
        return {
          ...column,
          render: (_: unknown, { supplier }: EisContractItem) =>
            renderTooltip(supplier, styles.supplier),
        };
      case Columns.CONTRACT_PRICE:
        return {
          ...column,
          render: (_: unknown, { contractPrice }: EisContractItem) =>
            renderFormattedNumber(contractPrice, styles.contractPrice),
        };
      case Columns.SMP:
        return {
          ...column,
          render: (_: unknown, { smp, name }: EisContractItem) =>
            renderBoolean(smp, name, styles.smp),
        };
      case Columns.CONTRACT_REESTR_NUMBER:
        return {
          ...column,
          render: (_: unknown, { contractReestrNumber, contractHref }: EisContractItem) =>
            renderLink(contractReestrNumber, contractHref, styles.contractReestrNumber),
        };
      case Columns.PLANNED_THIS_YEAR:
        return {
          ...column,
          render: (_: unknown, { plannedThisYear }: EisContractItem) =>
            renderFormattedNumber(plannedThisYear, styles.plannedThisYear),
        };
      case Columns.CONTRACT_NUMBER:
        return {
          ...column,
          render: (_: unknown, { contractNumber }: EisContractItem) => (
            <div className={styles.contractNumber}>{contractNumber}</div>
          ),
        };
      case Columns.NOTIFICATION_NUMBER:
        return {
          ...column,
          render: (_: unknown, { notificationNumber, notificationUrl }: EisContractItem) =>
            renderLink(notificationNumber, notificationUrl, styles.notificationNumber),
        };
      case Columns.PAID:
        return {
          ...column,
          render: (_: unknown, { paid }: EisContractItem) =>
            renderFormattedNumber(paid, styles.paid),
        };
      case Columns.SUPPLIED:
        return {
          ...column,
          render: (_: unknown, { supplied }: EisContractItem) =>
            renderFormattedNumber(supplied, styles.supplied),
        };
      case Columns.PAID_THIS_YEAR:
        return {
          ...column,
          render: (_: unknown, { paidThisYear }: EisContractItem) =>
            renderFormattedNumber(paidThisYear, styles.paidThisYear),
        };
      case Columns.REMAINING_AMOUNT_TO_PAY:
        return {
          ...column,
          render: (_: unknown, { remainingAmountToPay }: EisContractItem) =>
            renderFormattedNumber(remainingAmountToPay, styles.remainingAmountToPay),
        };
      case Columns.ECONOMY:
        return {
          ...column,
          render: (_: unknown, { economy }: EisContractItem) =>
            renderFormattedNumber(economy, styles.economy),
        };
      case Columns.EXECUTION_DATE:
        return {
          ...column,
          render: (_: unknown, { executionDate, name }: EisContractItem) =>
            renderFormattedDate(executionDate, name, styles.executionDate),
        };
      case Columns.IKZ:
        return {
          ...column,
          render: (_: unknown, { ikz }: EisContractItem) => <div className={styles.ikz}>{ikz}</div>,
        };
      case Columns.DRUGS:
        return {
          ...column,
          render: (_: unknown, { drugs, name }: EisContractItem) =>
            renderBoolean(drugs, name, styles.drugs),
        };
      case Columns.MED_DEVICES:
        return {
          ...column,
          render: (_: unknown, { medDevices, name }: EisContractItem) =>
            renderBoolean(medDevices, name, styles.medDevices),
        };
      default:
        return column;
    }
  });
};

const calculateTotals = (contracts: EisAllContractsResponse) => {
  return {
    nmck: contracts.sumNmck,
    contractPrice: contracts.sumContractPrice,
    plannedThisYear: contracts.plannedThisYear,
    paid: contracts.sumPaid,
    supplied: contracts.sumSupplied,
    paidThisYear: contracts.sumPaidThisYear,
    remainingAmountToPay: contracts.sumRemainingAmountToPay,
    economy: contracts.sumEconomy,
  };
};

type ReestrAllPageResultsTableProps = {
  contracts: EisAllContractsResponse | undefined;
  isContractsLoading: boolean;
  selectedColumns: TableProps<EisContractItem>['columns'];
};

export const ReestrAllPageResultsTable = memo(
  ({ contracts, isContractsLoading, selectedColumns }: ReestrAllPageResultsTableProps) => {
    const resultsColumns = useMemo(() => {
      return resultsTableColumnsWithRender(selectedColumns) as ColumnType<
        EisContractItem | TotalRow
      >[];
    }, [selectedColumns]);

    const dataSourceWithTotal = useMemo(() => {
      if (contracts) {
        const totals = calculateTotals(contracts);
        const totalRow: TotalRow = {
          ...totals,
          name: `Итого: ${contracts.count}`,
        };

        return [...contracts.results, totalRow];
      }
      return [];
    }, [contracts]);

    return (
      <div className={styles.wrapper}>
        <Table
          rowKey={'name'}
          scroll={scroll}
          className={styles.table}
          loading={isContractsLoading}
          dataSource={dataSourceWithTotal}
          columns={resultsColumns}
          pagination={false}
        />
      </div>
    );
  },
);
ReestrAllPageResultsTable.displayName = 'ReestrAllPageResultsTable';
