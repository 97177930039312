import useSWR, { SWRConfiguration } from 'swr';
import {
  deleteData,
  downloadXlsx,
  fetcher,
  postData,
  putData,
  getData,
} from '../commonApi/commonApi';
import { ReestrDirectParams } from '@/widgets/ReestrDirect/context/reducer';
import {
  DirectContractCauses,
  DirectContractsResponse,
  DirectContractUpdateBody,
} from './reestrDirectApiTypes';
import { setURLParamsByBDUIFilters } from '@/utils/setURLParamsByBDUIFilters';

export const useGetDirectContracts = (state: ReestrDirectParams) => {
  const params = new URLSearchParams();

  params.append('page', String(state.currentPage));
  params.append('count', String(state.itemsPerPage));
  setURLParamsByBDUIFilters(params, state.filters);

  return useSWR(`/direct-contracts?${params}`, getData<DirectContractsResponse>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  } as SWRConfiguration);
};
export const getXlsx = async () => {
  const response = await fetcher('/direct-contracts/xlsx');
  await downloadXlsx(response, 'Прямые договоры');
};

export const useGetDirectContractCauses = () => {
  return useSWR('/direct-contracts-causes', getData<DirectContractCauses>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  } as SWRConfiguration);
};

export const updateDirectContract = async (
  directContractId: number,
  body: DirectContractUpdateBody,
) => putData<DirectContractUpdateBody>(`/direct-contracts/${directContractId}`, body);

export const createNewDirectContract = async (body: DirectContractUpdateBody) =>
  postData<DirectContractUpdateBody>('/direct-contracts', body);

export const deleteDirectContract = async (id: string) => {
  return deleteData(`/direct-contracts/${id}`);
};
