import { memo, useCallback, useMemo, useState } from 'react';
import { ConfigProvider, List, Select } from 'antd';
import moment, { Moment } from 'moment';

import { DeadlineCalculationContracts, Step } from '@/api/skpApi/skpApiTypes';
import { Option } from '@/types/types';
import { DebounceInput, UiDatePicker } from '@/components';
import { dateFormat } from '@/constants';
import { formatDate } from '@/utils';
import { updateDeadlineCalculationContracts } from '@/api/skpApi/skpApi';

import Vicon from '/public/icons/v.svg';
import Cross from '/public/icons/cross.svg';
import Calendar from '/public/icons/calendar.svg';
import styles from './StageItem.module.scss';
import colors from '@/styles/colors.module.scss';

const theme = {
  components: {
    Select: {
      colorText: colors.black,
    },
  },
};

const clearIcon = { clearIcon: <Cross className={styles.crossIcon} /> };

enum daysOptionsType {
  EXACT_DATE = 'EXACT_DATE',
  WORKING_DAYS = 'WORKING_DAYS',
  CALENDAR_DAYS = 'CALENDAR_DAYS',
}

const allDaysOptions: Option[] = [
  { label: 'Точная дата', value: daysOptionsType.EXACT_DATE },
  { label: 'Рабочие дни', value: daysOptionsType.WORKING_DAYS },
  { label: 'Календарные дни', value: daysOptionsType.CALENDAR_DAYS },
];

type StageItemProps = {
  step: Step;
  stage: DeadlineCalculationContracts;
};

export const StageItem = memo(({ step, stage }: StageItemProps) => {
  const filteredDaysOptions = useMemo(
    () => allDaysOptions.filter((option) => step.dateCalculateMethodChoices.includes(option.value)),
    [step.dateCalculateMethodChoices],
  );
  const [currentDaysType, setCurrentDaysType] = useState(filteredDaysOptions[0].value);
  const [deadlineValue, setDeadlineValue] = useState(step.termInDaysCount);
  const [deadlineDate, setDeadlineDate] = useState<Moment | null>(moment(step.executionDate));

  const daysChange = useCallback(
    async (value: string) => {
      setCurrentDaysType(value);
      const updatedStep = {
        ...step,
        dateCalculateMethod: value,
      };
      const updatedStage = {
        ...stage,
        steps: stage.steps.map((s) => (s.id === step.id ? updatedStep : s)),
      };
      await updateDeadlineCalculationContracts(updatedStage);
    },
    [step, stage],
  );

  const handleDeadlineChange = useCallback(
    async (value: string) => {
      setDeadlineValue(Number(value) || 0);
      const updatedStep = {
        ...step,
        termInDaysCount: Number(value) || 0,
      };
      const updatedStage = {
        ...stage,
        steps: stage.steps.map((s) => (s.id === step.id ? updatedStep : s)),
      };
      await updateDeadlineCalculationContracts(updatedStage);
    },
    [stage, step],
  );

  const handleDeadlineDateChange = useCallback(
    async (value: Moment) => {
      setDeadlineDate(value);
      const updatedStep = {
        ...step,
        executionDate: formatDate(value, dateFormat.serverFormat),
      };
      const updatedStage = {
        ...stage,
        steps: stage.steps.map((s) => (s.id === step.id ? updatedStep : s)),
      };
      await updateDeadlineCalculationContracts(updatedStage);
    },
    [stage, step],
  );

  return (
    <ConfigProvider theme={theme}>
      <List.Item className={styles.listItem}>
        <div className={styles.grid}>
          <div className={styles.name}>{step.name}</div>
          <div>{step.law}</div>
          <div>
            <Select
              value={currentDaysType}
              options={filteredDaysOptions}
              suffixIcon={<Vicon className="v-icon" />}
              className={styles.select}
              onChange={daysChange}
            />
          </div>
          <div>
            {step.dateCalculateMethod === daysOptionsType.EXACT_DATE ? (
              <UiDatePicker
                value={moment(deadlineDate)}
                onChange={handleDeadlineDateChange}
                className={styles.datePicker}
                suffixIcon={<Calendar />}
                allowClear={false}
              />
            ) : (
              <DebounceInput
                min={0}
                type="number"
                allowClear={clearIcon}
                className={styles.dealineInput}
                handleValueChange={handleDeadlineChange}
                value={deadlineValue}
              />
            )}
          </div>
          <div>{formatDate(deadlineDate || '', dateFormat.frontFormat)}</div>
        </div>
      </List.Item>
    </ConfigProvider>
  );
});
StageItem.displayName = 'StageItem';
