'use client';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { TabsProps } from 'antd';
import { Button, ConfigProvider, Select, Tabs } from 'antd';
import Vicon from '/public/icons/v.svg';
import Print from '/public/icons/print.svg';
import { Contracts, Procedures } from './components';
import { PrintModal } from './components/Procedures/components/PrintModal/PrintModal';

import { useGetDeadlineCalculation } from '@/api/skpApi/skpApi';
import { DeadlineCalculation, DeadlineCalculationResponse } from '@/api/skpApi/skpApiTypes';
import { Option } from '@/types/types';

import colors from '@/styles/colors.module.scss';
import styles from './SkpPage.module.scss';
import { LoadingWrapper } from '@/components/Ui';

export enum DeadlineType {
  PROCEDURES44 = 'procedures44',
  PROCEDURES223 = 'procedures223',
  CONTRACTS44 = 'contracts44',
}

const items: TabsProps['items'] = [
  {
    key: DeadlineType.PROCEDURES44,
    label: 'Процедуры (44-Ф3)',
  },
  {
    key: DeadlineType.PROCEDURES223,
    label: 'Процедуры (223-Ф3)',
  },
  {
    key: DeadlineType.CONTRACTS44,
    label: 'Контракты (44-Ф3)',
  },
];

const theme = {
  components: {
    Tabs: {
      horizontalMargin: '0',
    },
    Select: {
      colorText: colors.light_gray,
      showArrowPaddingInlineEnd: 14,
    },
  },
};
const dropdownStyle: React.CSSProperties = {
  width: '300px',
};

export const SkpPage = memo(() => {
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(DeadlineType.PROCEDURES44);
  const [name, setName] = useState<string>();
  const [currentCalculation, setCurrentCalculation] = useState<DeadlineCalculation | undefined>();
  const [deadlineCalculationData, setDeadlineCalculationData] =
    useState<DeadlineCalculationResponse>([]);
  const initialCalculationData = useRef<DeadlineCalculationResponse>();

  const { data: deadlineCalculation, isLoading } = useGetDeadlineCalculation(activeTab);

  useEffect(() => {
    if (deadlineCalculation) {
      setName(deadlineCalculation[0].name);
      setDeadlineCalculationData(deadlineCalculation);

      if (!initialCalculationData.current) {
        initialCalculationData.current = deadlineCalculation;
      }
    }
  }, [deadlineCalculation, initialCalculationData]);

  useEffect(() => {
    if (name) {
      setCurrentCalculation(deadlineCalculationData.find((item) => item.name === name));
    }
  }, [name, deadlineCalculationData]);

  const options: Option[] = useMemo(
    () =>
      deadlineCalculation?.map((item) => ({
        value: item.name,
        label: item.name,
      })) || [],
    [deadlineCalculation],
  );

  const onChange = useCallback((key: string) => {
    setActiveTab(key as DeadlineType);
  }, []);

  const setPrintModalOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <ConfigProvider theme={theme}>
      <div className={styles.headerWrapper}>
        <h1 className={styles.title}>Расчёт сроков </h1>
        <Tabs
          defaultActiveKey="1"
          items={items}
          className={styles.tabs}
          onChange={onChange}
        />
        {activeTab !== DeadlineType.CONTRACTS44 && (
          <>
            <Select
              value={name}
              onChange={setName}
              dropdownStyle={dropdownStyle}
              className={styles.select}
              options={options}
              suffixIcon={<Vicon className="v-icon" />}
            />
            <Button
              type="text"
              icon={<Print />}
              className={styles.printBtn}
              onClick={setPrintModalOpen}
            >
              Печать/просмотр
            </Button>
          </>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {(activeTab === DeadlineType.PROCEDURES44 || activeTab === DeadlineType.PROCEDURES223) && (
          <LoadingWrapper isLoading={isLoading}>
            {currentCalculation && (
              <Procedures
                currentCalculation={currentCalculation}
                setDeadlineCalculationData={setDeadlineCalculationData}
                initialCalculationData={initialCalculationData.current}
              />
            )}
          </LoadingWrapper>
        )}
        {activeTab === DeadlineType.CONTRACTS44 && <Contracts />}
      </div>
      {currentCalculation && (
        <PrintModal
          currentCalculation={currentCalculation}
          open={open}
          onCancel={() => setOpen(false)}
        />
      )}
    </ConfigProvider>
  );
});
SkpPage.displayName = 'SkpPage';
