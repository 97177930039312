import {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Modal, ModalProps } from 'antd';
import { OkpdKtruPage } from '@/widgets/OkpdKtpu/ui/OkpdKtruPage/OkpdKtruPage';
import styles from './OkpdFlowModal.module.scss';
import { OkpdKtruModalPageParams, OkpdKtruPageTypes } from '@/components/OkpdFlowModal/types';
import { OkpdDetailPage } from '@/widgets';
import { updateNmckRow } from '@/api/nmckApi/nmckApi';
import { NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { createCurrentPurchaseItem } from '@/api/planningCurrentApi/planningCurrentApi';
import { KtruDetailPage } from '@/widgets/KtruDetail/KtruDetailPage/KtruDetailPage';
import { eventsGTM, sendEventGTM } from '@/metrika';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  searchString: string;
  id: string;
  row?: NmckRow;
  refreshCalculation: () => void;
};

const modalClassNames: ModalProps['classNames'] = { body: styles.modalBody };

export const OkpdFlowModal = memo(
  ({ isOpen, setIsOpen, searchString, id, row, refreshCalculation }: Props) => {
    const [isAddingToCalculationLoading, setIsAddingToCalculationLoading] = useState(false);
    const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);
    const [pageParams, setPageParams] = useState<OkpdKtruModalPageParams>({
      page: OkpdKtruPageTypes.LISTS,
      searchString,
      code: '',
    });

    const ComputedOkpdPage: ReactNode = useMemo(
      () =>
        ({
          [OkpdKtruPageTypes.LISTS]: (
            <OkpdKtruPage
              modalPageParams={pageParams}
              setModalPageParams={setPageParams}
            />
          ),
          [OkpdKtruPageTypes.OKPD]: (
            <OkpdDetailPage
              code={pageParams.code}
              setModalPageParams={setPageParams}
            />
          ),
          [OkpdKtruPageTypes.KTRU]: (
            <KtruDetailPage
              code={pageParams.code}
              setModalPageParams={setPageParams}
            />
          ),
        })[pageParams.page],
      [pageParams],
    );

    const handleAddToCalculationClick = useCallback(async () => {
      setIsAddingToCalculationLoading(true);
      if (id && row) {
        const isKtru = pageParams.page === OkpdKtruPageTypes.KTRU;
        sendEventGTM(
          isKtru ? eventsGTM.nmckRowKtruChangeByModal : eventsGTM.nmckRowOkpdChangeByModal,
          { value: pageParams.code },
        );
        await updateNmckRow(id, {
          ...row,
          ...(isKtru
            ? { ktruCode: pageParams.code, okpdCode: undefined }
            : { okpdCode: pageParams.code, ktruCode: undefined }),
        });
      } else {
        await createCurrentPurchaseItem(
          {
            name: '',
            okpdCode: pageParams.code,
            chars: [],
            amount: 0,
            unit: '',
          },
          id,
        );
      }
      setIsAddingToCalculationLoading(false);
      refreshCalculation();
      handleClose();
    }, [handleClose, id, pageParams.code, pageParams.page, refreshCalculation, row]);

    const modalFooter = useMemo(
      () => (
        <Button
          className={styles.footerButton}
          size="large"
          type="primary"
          loading={isAddingToCalculationLoading}
          disabled={!pageParams.code}
          onClick={handleAddToCalculationClick}
        >
          Добавить код в расчёт
        </Button>
      ),
      [isAddingToCalculationLoading, handleAddToCalculationClick, pageParams.code],
    );

    useEffect(() => {
      setPageParams((prev) => ({
        ...prev,
        page: OkpdKtruPageTypes.LISTS,
        searchString,
        okpdId: undefined,
        ktruId: undefined,
        code: '',
      }));
    }, [searchString]);

    return (
      <>
        <Modal
          centered
          open={isOpen}
          title="Выбор ОКПД"
          footer={modalFooter}
          onCancel={handleClose}
          width="90%"
          classNames={modalClassNames}
          className={styles.modal}
        >
          {ComputedOkpdPage}
        </Modal>
      </>
    );
  },
);

OkpdFlowModal.displayName = 'OkpdFlowModal';
