import { Option } from '@/types/types';

export type BDUIFilterResponse = {
  type: BDUIComponentType;
  caption: string;
  queryParamName?: string;
  queryParamNameFrom?: string;
  queryParamNameTo?: string;
  values?: BDUIFilterValue[];
  multiselect: boolean;

  selectedValue?: string;
  selectedValuesInt?: number[];
  selectedFromDtValue?: string;
  selectedToDtValue?: string;

  emptyFromValue?: string;
  emptyToValue?: string;
  emptyValue?: string;
};

export type BDUIFilterValue = {
  caption: string;
  value: string;
};

export type BDUIFilterParam<
  TValue = BDUIFilterSingleValue | BDUIFilterRangeValue | BDUIFilterMultiselect,
> = {
  value?: TValue;
  queryParamName?: string;
  queryParamNameFrom?: string;
  queryParamNameTo?: string;
  emptyValues?: BDUIFilterEmptyValues;
};

export type BDUIFilterEmptyValues = {
  emptyFromValue?: string;
  emptyToValue?: string;
  emptyValue?: string;
};

export type BDUIFilterSingleValue = {
  type: 'single';
  value?: string | number;
};

export type BDUIFilterRangeValue = {
  type: 'range';
  min?: string;
  max?: string;
};

export type BDUIFilterMultiselect = {
  type: 'multiselect';
  values?: number[];
};

export type BDUIFilterItemType = {
  id: number;
  name?: Option;
  value?: BDUIFilterSingleValue | BDUIFilterRangeValue | BDUIFilterMultiselect;
  type?: BDUIComponentType;
  emptyValues?: BDUIFilterEmptyValues;
};

export enum BDUIComponentType {
  INPUT = 'INPUT',
  DATE_INTERVAL = 'DATE_INTERVAL',
  PURCHASE_METHOD = 'PURCHASE_METHOD',
  DOUBLE_INTERVAL = 'DOUBLE_INTERVAL',
  SELECT = 'SELECT',
  REGION = 'REGION',
  MEASURE_UNIT = 'MEASURE_UNIT',
  EMPTY = '',
}
