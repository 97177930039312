import { Characteristic } from '@/api/docsApi/docsApiTypes';
import { Flex } from 'antd';
import { memo } from 'react';

import styles from './CharacteristicsItems.module.scss';
import { CharacteristicItem } from '@/widgets/Docs/components';

type CharacteristicsItemProps = {
  items: Characteristic[];
  overflowed?: boolean;
};

export const CharacteristicsItems = memo(({ items, overflowed }: CharacteristicsItemProps) => {
  return (
    <>
      {items.map((item, index) => {
        return (
          <Flex
            key={index}
            className={styles.characteristicItem}
          >
            <CharacteristicItem
              name={item.name}
              value={item.value}
              overflowed={overflowed}
              className={styles.characteristic}
            />
          </Flex>
        );
      })}
    </>
  );
});

CharacteristicsItems.displayName = 'CharacteristicsItems';
