import { Characteristic } from '@/api/docsApi/docsApiTypes';
import { Actions, ActionType } from './actions';

export interface SearchParams {
  code: string;
  startDate: string;
  endDate: string;
  regions: number[];
  customers: number[];
  itemsPerPage: number;
  currentPage: number;
  eis?: number;
  rank?: string;
  characteristics?: Characteristic[];
  modal: boolean;
}

export const initialState: SearchParams = {
  code: '',
  startDate: '',
  endDate: '',
  regions: [],
  customers: [],
  itemsPerPage: 10,
  currentPage: 1,
  modal: false,
};

export const searchReducer = (
  state: SearchParams = initialState,
  action: Actions,
): SearchParams => {
  switch (action.type) {
    case ActionType.SET_OKPD:
      return { ...state, code: action.payload, eis: undefined, rank: undefined };
    case ActionType.SET_START_DATE:
      return { ...state, startDate: action.payload, eis: undefined, rank: undefined };
    case ActionType.SET_END_DATE:
      return { ...state, endDate: action.payload, eis: undefined, rank: undefined };
    case ActionType.SET_REGIONS:
      return { ...state, regions: action.payload, eis: undefined, rank: undefined };
    case ActionType.SET_CUSTOMERS:
      return { ...state, customers: action.payload, eis: undefined, rank: undefined };
    case ActionType.SET_ITEMS_PER_PAGE:
      return { ...state, itemsPerPage: action.payload, eis: undefined, rank: undefined };
    case ActionType.SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload, eis: undefined, rank: undefined };
    case ActionType.SET_EIS:
      return { ...state, eis: action.payload };
    case ActionType.SET_RANK:
      return { ...state, rank: action.payload };
    case ActionType.SET_CHARACTERISTICS:
      return { ...state, characteristics: action.payload };
    case ActionType.SHOW_MODAL:
      return { ...state, modal: action.payload };
    default:
      return state;
  }
};
