import { memo, ReactNode } from 'react';
import styles from './CompatibilityWarning.module.scss';
import Warning from 'public/icons/warning.svg';

type Props = {
  hasRestrictions: boolean;
  content?: string | ReactNode;
};

export const CompatibilityWarning = memo(
  ({
    hasRestrictions,
    content = 'В закупке содержатся несовместимые коды ОКПД2/КТРУ. Необходимо разделить закупку',
  }: Props) => {
    return (
      hasRestrictions && (
        <div className={styles.warning}>
          <Warning />
          <div>{content}</div>
        </div>
      )
    );
  },
);

CompatibilityWarning.displayName = 'CompatibilityWarning';
