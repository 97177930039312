import { isClientRender } from '@/utils/isClientRender';

const STORAGE_TOKEN_NAME = 'gpb-token';
const STORAGE_USER_ID = 'user-id';

export const saveGPBToken = (token: string) => {
  localStorage.setItem(STORAGE_TOKEN_NAME, token);
};

export const getGPBToken = (): string => {
  return localStorage.getItem(STORAGE_TOKEN_NAME) ?? '';
};

export const saveUserIdToStorage = (userId: string) => {
  localStorage.setItem(STORAGE_USER_ID, userId);
};

export const getUserIdFromStorage = (): string => {
  if (isClientRender()) {
    return localStorage.getItem(STORAGE_USER_ID) ?? '';
  }

  return '';
};
