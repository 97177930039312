import { Table, Tag, Tooltip } from 'antd';
import { Dispatch, Key, memo, SetStateAction, useCallback, useMemo } from 'react';
import styles from './PlanningAllPageResultsTable.module.scss';

import { TableProps } from 'antd/lib';
import { Columns } from '../data/data';
import { ColumnType } from 'antd/es/table';
import {
  PlanningPurchase,
  PlanningPurchasesResponse,
} from '@/api/planningAllApi/planningAllApiTypes';
import { formatDate } from '@/utils';
import { formatNumber } from '@/utils/formatNumber';
import { useRouter } from 'next/navigation';
import { pageRoutes } from '@/app/pageRoutes';
type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];

const scroll = { x: 'max-content' };

const renderTooltip = (text: string, className: string) => (
  <Tooltip
    title={text}
    arrow={false}
    placement="bottom"
  >
    <div className={className}>{text}</div>
  </Tooltip>
);
const renderFormattedNumber = (value: number | undefined, className: string) => (
  <div className={className}>{formatNumber(value ? value : 0)}</div>
);

const resultsTableColumnsWithRender = (
  selectedColumns: TableProps<PlanningPurchase>['columns'],
): TableProps<PlanningPurchase>['columns'] => {
  return selectedColumns?.map((column: ColumnType<PlanningPurchase>) => {
    switch (column.dataIndex) {
      case Columns.NUMBER:
        return {
          ...column,
          width: 20,
          render: (_: unknown, { number }: PlanningPurchase) => (
            <div className={styles.number}>{number}</div>
          ),
        };
      case Columns.STATUS:
        return {
          ...column,
          width: 20,
          render: (_: unknown, { status }: PlanningPurchase) => (
            <Tag className={styles.tag}>{status.toUpperCase()}</Tag>
          ),
        };
      case Columns.CREATED:
        return {
          ...column,
          width: 135,
          render: (_: unknown, { created }: PlanningPurchase) => (
            <div className={styles.created}>{formatDate(created)}</div>
          ),
        };
      case Columns.RESPONSIBLE:
        return {
          ...column,
          width: 230,
          render: (_: unknown, { responsible }: PlanningPurchase) =>
            renderTooltip(responsible, styles.responsible),
        };
      case Columns.AMOUNT:
        return {
          ...column,
          width: 100,
          render: (_: unknown, { amount }: PlanningPurchase) =>
            renderFormattedNumber(amount, styles.amount),
        };
      case Columns.NAME:
        return {
          ...column,
          render: (_: unknown, { name }: PlanningPurchase) => renderTooltip(name, styles.name),
        };
      default:
        return column;
    }
  });
};

type PlanningAllPageResultsTableProps = {
  purchases: PlanningPurchasesResponse | undefined;
  isPurchasesLoading: boolean;
  selectedColumns: TableProps<PlanningPurchase>['columns'];
  selectedRowKeys: Key[];
  setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>;
};

export const PlanningAllPageResultsTable = memo(
  ({
    purchases,
    isPurchasesLoading,
    selectedColumns,
    selectedRowKeys,
    setSelectedRowKeys,
  }: PlanningAllPageResultsTableProps) => {
    const router = useRouter();
    const resultsColumns = useMemo(() => {
      return resultsTableColumnsWithRender(selectedColumns) as ColumnType<PlanningPurchase>[];
    }, [selectedColumns]);

    const onSelectChange = (newSelectedRowKeys: Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<PlanningPurchase> = {
      columnWidth: '64px',
      selectedRowKeys,
      onChange: onSelectChange,
    };

    const redirectToCurrentPurchase = useCallback(
      (record: PlanningPurchase) => {
        router.push(`${pageRoutes.planningCurrent}/${record.id}`);
      },
      [router],
    );

    const onRow = useCallback(
      (record: PlanningPurchase) => {
        return {
          onClick: () => redirectToCurrentPurchase(record),
        };
      },
      [redirectToCurrentPurchase],
    );

    return (
      <div className={styles.wrapper}>
        <Table
          onRow={onRow}
          rowKey={'id'}
          scroll={scroll}
          rowClassName={styles.row}
          className={styles.table}
          loading={isPurchasesLoading}
          dataSource={purchases?.results}
          columns={resultsColumns}
          pagination={false}
          rowSelection={rowSelection}
        />
      </div>
    );
  },
);
PlanningAllPageResultsTable.displayName = 'PlanningAllPageResultsTable';
