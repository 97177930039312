'use client';
import { memo, useState } from 'react';
import { PlanningCurrentHeader } from '@/widgets/PlanningCurrent/components';
import { PlanningCurrentTabsContent } from '@/widgets/PlanningCurrent/PlanningCurrentTabsContent/PlanningCurrentTabsContent';
import { PlanningCurrentTabsType } from '@/widgets/PlanningCurrent/types';

export const PlanningCurrentPage = memo(() => {
  const [activeTab, setActiveTab] = useState(PlanningCurrentTabsType.PURCHASE_OBJECT);

  return (
    <>
      <PlanningCurrentHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <PlanningCurrentTabsContent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </>
  );
});
PlanningCurrentPage.displayName = 'PlanningCurrentPage';
