'use client';
import { memo, useContext } from 'react';
import { ReestrDirectPageHeader, ReestrDirectPageResults } from '@/widgets/ReestrDirect/components';
import {
  useGetDirectContractCauses,
  useGetDirectContracts,
} from '@/api/reestrDirectApi/reestrDirectApi';
import { ReestrDirectContext } from '../context/context';

export const ReestrDirectPage = memo(() => {
  const { state } = useContext(ReestrDirectContext);
  const {
    data: contracts,
    isLoading: isContractsLoading,
    mutate: refreshContracts,
  } = useGetDirectContracts(state);
  const { data: causes, isLoading: isCausesLoading } = useGetDirectContractCauses();
  return (
    <>
      <ReestrDirectPageHeader
        filtersResponse={contracts?.filters}
        isContractsLoading={isContractsLoading}
        refreshContracts={refreshContracts}
        causes={causes}
        isCausesLoading={isCausesLoading}
      />
      <ReestrDirectPageResults
        contracts={contracts}
        isContractsLoading={isContractsLoading}
        refreshContracts={refreshContracts}
        causes={causes}
        isCausesLoading={isCausesLoading}
      />
    </>
  );
});
ReestrDirectPage.displayName = 'ReestrDirectPage';
