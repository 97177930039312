export type PurchaseByIdResponse = {
  id: number;
  number: number | null;
  status: string;
  created: string;
  supplyDate: string | null;
  amount: number | null;
  financeSource: string;
  divisionName: string;
  responsible: string;
  name: string;
  comment: string;
  purchaseItems: PurchaseItem[];
  totalSum: null; // TODO: выпилить после бэковой задачи SARK-415. бэк пока падает без этого поля, но его быть не должно
};

export type PurchaseItem = {
  id: number;
  name: string;
  okpdCode: string;
  chars: Characteristic[];
  amount: number;
  unit: string;
  unitCode?: number;
};

export type CreateCurrentPurchaseItemBody = Omit<PurchaseItem, 'id'>;

export type Characteristic = {
  id: number;
  name: string;
  type: CharacteristicType;
  textValue: string | null;
  numValue: number | null;
  leftRange: number | null;
  rightRange: number | null;
  leftRangeSign: RangeSign | null;
  rightRangeSign: RangeSign | null;
};

export type CompatibilityUpdateBody = {
  npaShortName: string; // columnCode
  accepted: boolean;
};

export enum CharacteristicType {
  TEXT = 'TEXT',
  NUMERIC = 'NUMERIC',
  RANGE = 'RANGE',
}
export enum RangeSign {
  LESS = 'LESS',
  MORE = 'MORE',
  MORE_EQUAL = 'MORE_EQUAL',
  LESS_EQUAL = 'LESS_EQUAL',
}
