import { StandardContractType } from '@/api/okpdApi/okpdApiTypes';
import Link from 'next/link';
import styles from './StandardContract.module.scss';
import { memo } from 'react';

type StandardContractProps = {
  standardContract: StandardContractType[];
};

export const StandardContract = memo(({ standardContract }: StandardContractProps) => {
  if (!standardContract.length) {
    return <div className={styles.label}>Типовые контракты не найдены</div>;
  }

  const { second, first } = standardContract[0];

  return (
    <div>
      <span className={styles.label}>Типовые контракты: </span>
      <Link
        href={second}
        target="_blank"
        className={styles.link}
      >
        {first}
      </Link>
    </div>
  );
});

StandardContract.displayName = 'StandardContract';
