import { memo, useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type Props = React.HTMLAttributes<HTMLTableRowElement> & {
  'data-row-key': string;
};

export const TableColumnsSettingsRow = memo((props: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = useMemo(
    () => ({
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      cursor: 'move',
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    }),
    [isDragging, props.style, transform, transition],
  );

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
});
TableColumnsSettingsRow.displayName = 'TableColumnsSettingsRow';
