import { memo } from 'react';
import cx from 'classnames';

import { formatPrice } from '@/utils/formatPrice';

import styles from './SumDisplay.module.scss';

type SumDisplayProps = {
  text?: string;
  sum?: number;
  className?: string;
};

export const SumDisplay = memo(({ text, sum, className }: SumDisplayProps) => {
  return (
    <>
      {text && sum && (
        <div className={cx(styles.wrapper, className)}>
          <p>{text}</p>
          <p className={styles.sum}>{formatPrice(sum)} ₽</p>
        </div>
      )}
    </>
  );
});

SumDisplay.displayName = 'SumDisplay';
