import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ConfigProvider, Popover, Table } from 'antd';
import { ColumnType, Key, TableRowSelection } from 'antd/es/table/interface';
import { resultsTableColumns } from './constants';
import { NmckGridContext } from '@/components/NmckGrid/context/context';
import styles from './NmckGridContractsTable.module.scss';
import { EmptyProposition } from '@/components/NmckGrid/NmckGridContracts/components/types';
import {
  ContractItem,
  ContractItemsResponse,
  NmckRow,
  PropositionType,
} from '@/api/nmckApi/nmckApiTypes';
import {
  updateProposition,
  useGetNmckById,
  usePostProposition as postProposition,
} from '@/api/nmckApi/nmckApi';
import { useParams } from 'next/navigation';
import { putData } from '@/api/commonApi/commonApi';
import { TokenBasedPaginationTokenValue } from '@/api/commonApi/commonApiTypes';
import { ActionType } from '@/components/NmckGrid/context/actions';
import { TableFooter } from '@/components/Table/TableFooter/TableFooter';
import { useSortableTableColumns } from '@/components/Table/TableColumnsSettings/hooks';
import { eventsGTM, sendEventGTM } from '@/metrika';
import {
  contentPopOver,
  getScroll,
  resultsTableColumnsWithRender,
  rowClassName,
} from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/utils';
import { UiSwitch } from '@/components';
import Question from 'public/icons/questionRound.svg';
import { TableColumnsSettings } from '@/components/Table/TableColumnsSettings/TableColumnsSettings';

type Props = {
  isContractsLoading: boolean;
  contracts: ContractItemsResponse;
  record: NmckRow;
};

const theme = {
  components: {
    Table: {
      cellPaddingInline: 6,
    },
  },
};

export const NmckGridContractsTable = memo(({ contracts, isContractsLoading, record }: Props) => {
  const { id } = useParams();
  const calculationId = Array.isArray(id) ? id[0] : id;
  const [priceIncreasingFactor, setPriceIncreasingFactor] = useState(false);
  const [showAll, setShowAll] = useState<boolean[]>(Array(contracts.results.length).fill(false));

  const { data: calculation, mutate: refreshCalculation } = useGetNmckById(calculationId);
  const { state, dispatch } = useContext(NmckGridContext);

  const { orderedColumns, orderedColumnsMap, setOrderedColumnsMap } =
    useSortableTableColumns<ContractItem>({ columns: resultsTableColumns });

  const initialSelectedRowKeys = useMemo(() => {
    if (!calculation) return [];
    const row = calculation.rows.find((row) => row.id === record.id);
    return row ? row.propositions.map((proposition) => proposition.contractId).filter(Boolean) : [];
  }, [calculation, record.id]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>(initialSelectedRowKeys);

  useEffect(() => {
    setSelectedRowKeys(initialSelectedRowKeys);
  }, [initialSelectedRowKeys]);

  const resultsColumns = useMemo(() => {
    return resultsTableColumnsWithRender(
      priceIncreasingFactor,
      orderedColumns,
      showAll,
      setShowAll,
    ) as ColumnType<ContractItem>[];
  }, [priceIncreasingFactor, orderedColumns, showAll]);

  const handlePriceIncreasingFactorChange = useCallback(() => {
    setPriceIncreasingFactor((prev) => !prev);
  }, []);

  const handleDeselect = useCallback(
    (newSelectedRowKeys: Key[]) => {
      const newSelectedRowKeysSet = new Set(newSelectedRowKeys);

      const deselectedRowKeys = selectedRowKeys.filter((key) => !newSelectedRowKeysSet.has(key));

      deselectedRowKeys.forEach(async (key) => {
        const row = calculation?.rows.find((row) => row.id === record.id);
        const proposition = row?.propositions.find((proposition) => proposition.contractId === key);

        if (proposition) {
          const emptyProposition: EmptyProposition = {
            type: PropositionType.KP,
            price: null,
            description: `Поставщик ${proposition.columnNumber}`,
            contractId: null,
            forAllRows: proposition.forAllRows,
          };
          await putData(`/nmck/${id}/row/${record.id}/proposition/${proposition.columnNumber}`, {
            ...emptyProposition,
          });
          await refreshCalculation();
        }
      });
    },
    [calculation?.rows, id, record.id, refreshCalculation, selectedRowKeys],
  );

  const handleSelect = useCallback(
    async (newSelectedRowKeys: Key[]) => {
      const selectedRowKeysSet = new Set(selectedRowKeys);
      const selectedKeys = newSelectedRowKeys.filter((key) => !selectedRowKeysSet.has(key));

      const contractsMap = new Map<Key, ContractItem>(
        contracts.results.map((contract) => [contract.eisId, contract]),
      );

      const selectedRows = selectedKeys.map((key) => contractsMap.get(key));
      for (const item of selectedRows) {
        const row = calculation?.rows.find((row) => row.id === record.id);
        const proposition = row?.propositions.find(
          (proposition) => proposition.price == null || proposition.price === 0,
        );

        if (proposition) {
          await updateProposition(
            calculationId,
            record.id,
            proposition,
            PropositionType.EIS,
            item?.unitPrice || null,
            `ЕИС ${item?.contract.reestrNumber}`,
            proposition.forAllRows,
            item?.eisId || null,
          );
          await refreshCalculation();
        } else if (item) {
          const data = await postProposition(calculationId);
          const updatedRow = data.rows.find((row: NmckRow) => row.id === record.id);
          const updatedProposition = updatedRow?.propositions.find(
            (proposition) => proposition.price == null || proposition.price === 0,
          );
          if (updatedProposition) {
            await updateProposition(
              calculationId,
              record.id,
              updatedProposition,
              PropositionType.EIS,
              item.unitPrice || null,
              `ЕИС ${item.contract.reestrNumber}`,
              updatedProposition.forAllRows,
              item.eisId || null,
            );
            await refreshCalculation();
          }
        }
      }
    },
    [
      selectedRowKeys,
      contracts.results,
      calculation?.rows,
      record.id,
      calculationId,
      refreshCalculation,
    ],
  );
  const onRowSelectionChange = useCallback(
    (newSelectedRowKeys: Key[]) => {
      sendEventGTM(eventsGTM.nmckContractSelect);
      setSelectedRowKeys((prevSelectedRowKeys) => {
        const isDeselecting = newSelectedRowKeys.length < prevSelectedRowKeys.length;

        if (isDeselecting) {
          handleDeselect(newSelectedRowKeys);
        } else {
          handleSelect(newSelectedRowKeys);
        }

        return newSelectedRowKeys;
      });
    },
    [handleDeselect, handleSelect],
  );
  const rowSelection: TableRowSelection<ContractItem> = useMemo(
    () => ({
      selectedRowKeys,
      onChange: onRowSelectionChange,
    }),
    [selectedRowKeys, onRowSelectionChange],
  );

  const handleLoadMoreClick = useCallback(
    (token: TokenBasedPaginationTokenValue) => {
      dispatch({ type: ActionType.TOKEN_UPDATE, payload: token });
    },
    [dispatch],
  );

  const handlePageSizeChange = useCallback(
    (pageSize: number) => {
      dispatch({ type: ActionType.PAGE_SIZE_UPDATE, payload: pageSize });
      dispatch({ type: ActionType.TOKEN_UPDATE, payload: null });
    },
    [dispatch],
  );

  const tableFooter = useCallback(
    () => (
      <TableFooter
        tokenBasedPagination={{
          pagination: contracts,
          pageSize: state.pageSize,
          onLoadMore: handleLoadMoreClick,
          onPageSizeChange: handlePageSizeChange,
          isDataLoading: isContractsLoading,
        }}
        wrapperClassName={styles.tableFooter}
      />
    ),
    [contracts, handleLoadMoreClick, handlePageSizeChange, isContractsLoading, state.pageSize],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.settings}>
        <UiSwitch
          value={priceIncreasingFactor}
          onChange={handlePriceIncreasingFactorChange}
        >
          <span>Повышающий коэффициент цен</span>
          <Popover
            placement="bottom"
            arrow={false}
            content={contentPopOver}
          >
            <Question className="hint-icon" />
          </Popover>
        </UiSwitch>
        <TableColumnsSettings
          orderedColumnMap={orderedColumnsMap}
          setOrderedColumnMap={setOrderedColumnsMap}
          columnsData={orderedColumns}
        />
      </div>
      <ConfigProvider theme={theme}>
        <Table
          loading={isContractsLoading}
          scroll={getScroll(state)}
          rowKey="eisId"
          rowSelection={rowSelection}
          rowClassName={rowClassName}
          className={styles.table}
          columns={resultsColumns}
          dataSource={contracts.results}
          pagination={false}
          footer={tableFooter}
        />
      </ConfigProvider>
    </div>
  );
});
NmckGridContractsTable.displayName = 'NmckGridContractsTable';
