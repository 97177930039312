import { TreeSelectDataResponse } from '@/api/nmckApi/nmckApiTypes';

export const getTreeDataMap = (data: TreeSelectDataResponse) => {
  return data.map((item) => {
    const newItem = {
      ...item,
      label: item.name,
      value: item.id,
    };

    if (item.children) {
      newItem.children = getTreeDataMap(item.children);
    }
    return newItem;
  });
};
