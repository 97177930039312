import { Moment } from 'moment';
import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { Button, Flex, Input } from 'antd';
import Link from 'next/link';

import { PenaltiesResult } from '@/widgets/PenaltiesCalc/components';
import { formatPrice } from '@/utils/formatPrice';
import { calculatePenalties, useGetCurrentCBRate } from '@/api/penaltiesCalcApi/penaltiesCalcApi';
import { CalculatioPenaltiesResponse } from '@/api/penaltiesCalcApi/penaltiesCalcApiTypes';
import { UiDatePicker } from '@/components';

import Cross from '/public/icons/cross.svg';
import ExportDiagonal from '/public/icons/exportDiagonal.svg';

import styles from './PenaltiesCalculator.module.scss';

export const PenaltiesCalculator = memo(() => {
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [contractPrice, setContractPrice] = useState('');
  const [actuallyFulfilled, setActuallyFulfilled] = useState('');
  const [buttonsState, setButtonsState] = useState({
    isDateClearDisabled: true,
    isContractPriceClearDisabled: true,
    isActuallyFulfilledClearDisabled: true,
    isClearAllDisabled: true,
    isCalculateButtonDisabled: true,
  });
  const [calculations, setCalculations] = useState<CalculatioPenaltiesResponse>();

  const { data: cbRate } = useGetCurrentCBRate();

  const handleDateClear = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
  }, [setEndDate]);

  const handleContractPriceChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setContractPrice(formatPrice(e.target.value));
    },
    [setContractPrice],
  );

  const handleActuallyFulfilledChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setActuallyFulfilled(formatPrice(e.target.value));
    },
    [setActuallyFulfilled],
  );

  const handleContractPriceClear = useCallback(() => {
    setContractPrice('');
  }, [setContractPrice]);

  const handleActuallyFulfilledClear = useCallback(() => {
    setActuallyFulfilled('');
  }, [setActuallyFulfilled]);

  useEffect(() => {
    const isOneDateEmpty = startDate === null && endDate === null;
    const isBothDateEmpty = startDate === null || endDate === null;
    const isContractPriceEmpty = contractPrice === '';
    const isActuallyFulfilledPriceEmpty = actuallyFulfilled === '';

    setButtonsState({
      isDateClearDisabled: isOneDateEmpty,
      isContractPriceClearDisabled: isContractPriceEmpty,
      isActuallyFulfilledClearDisabled: isActuallyFulfilledPriceEmpty,
      isClearAllDisabled: isOneDateEmpty && isContractPriceEmpty && isActuallyFulfilledPriceEmpty,
      isCalculateButtonDisabled:
        isBothDateEmpty || isContractPriceEmpty || isActuallyFulfilledPriceEmpty,
    });
  }, [startDate, endDate, contractPrice, actuallyFulfilled]);

  const handleCalculate = useCallback(async () => {
    if (!startDate || !endDate) return;
    const calculations = await calculatePenalties({
      contractPrice: Number(contractPrice),
      actuallyFulfilled: Number(actuallyFulfilled),
      fromDate: startDate.format('YYYY-MM-DD'),
      toDate: endDate.format('YYYY-MM-DD'),
    });

    setCalculations(calculations);
  }, [startDate, endDate, contractPrice, actuallyFulfilled]);

  const handleClearAll = useCallback(() => {
    handleDateClear();
    handleContractPriceClear();
    handleActuallyFulfilledClear();
    setCalculations(undefined);
  }, [handleDateClear, handleContractPriceClear, handleActuallyFulfilledClear]);

  return (
    <Flex
      className={styles.wrapper}
      gap={64}
    >
      <div className={styles.calc}>
        <div className={styles.field}>
          Просрочка:
          <Flex
            className={styles.dates}
            gap={20}
          >
            <UiDatePicker
              placeholder="Дата начала"
              className={styles.date}
              value={startDate}
              onChange={setStartDate}
            />
            <UiDatePicker
              placeholder="Дата конца"
              className={styles.date}
              value={endDate}
              onChange={setEndDate}
            />
            <Button
              disabled={buttonsState.isDateClearDisabled}
              type="text"
              onClick={handleDateClear}
              className={styles.clearButton}
            >
              <Cross
                className={cx(styles.clear, {
                  [styles.clearDisabled]: buttonsState.isDateClearDisabled,
                })}
              />
            </Button>
          </Flex>
        </div>
        <div className={styles.field}>
          Цена контракта/этапа, ₽:
          <Flex
            gap={20}
            align="center"
          >
            <Input
              value={contractPrice}
              onChange={handleContractPriceChange}
              className={styles.input}
              placeholder="Не указано"
            />
            <Button
              disabled={buttonsState.isContractPriceClearDisabled}
              type="text"
              onClick={handleContractPriceClear}
              className={styles.clearButton}
            >
              <Cross
                className={cx(styles.clear, {
                  [styles.clearDisabled]: buttonsState.isContractPriceClearDisabled,
                })}
              />
            </Button>
          </Flex>
        </div>
        <div className={styles.field}>
          Фактически исполнено, ₽:
          <Flex
            gap={20}
            align="center"
          >
            <Input
              value={actuallyFulfilled}
              onChange={handleActuallyFulfilledChange}
              className={styles.input}
              placeholder="Не указано"
            />
            <Button
              disabled={buttonsState.isActuallyFulfilledClearDisabled}
              type="text"
              onClick={handleActuallyFulfilledClear}
              className={styles.clearButton}
            >
              <Cross
                className={cx(styles.clear, {
                  [styles.clearDisabled]: buttonsState.isActuallyFulfilledClearDisabled,
                })}
              />
            </Button>
          </Flex>
        </div>
        <Flex
          className={styles.rate}
          gap={8}
          align="center"
        >
          Ставка центрального банка: {cbRate?.currentRate}%
          {cbRate?.href && (
            <Link
              href={cbRate.href}
              className={styles.cbHref}
              target="_blank"
            >
              <ExportDiagonal />
            </Link>
          )}
        </Flex>
        <Flex
          gap={20}
          className={styles.buttons}
          align="center"
        >
          <Button
            type="text"
            className={cx(styles.btn, {
              [styles.btnDisabled]: buttonsState.isCalculateButtonDisabled,
            })}
            onClick={handleCalculate}
            disabled={buttonsState.isCalculateButtonDisabled}
          >
            Рассчитать пени
          </Button>
          <Button
            disabled={buttonsState.isClearAllDisabled}
            type="text"
            onClick={handleClearAll}
          >
            <Flex
              align="center"
              gap={8}
              className={cx(styles.clear, {
                [styles.clearDisabled]: buttonsState.isClearAllDisabled,
              })}
            >
              <Cross />
              Сбросить всё
            </Flex>
          </Button>
        </Flex>
      </div>
      <PenaltiesResult
        penalties={calculations?.penalties}
        amountOfUnfulfilledObligations={calculations?.unfulfilled}
        overdueDaysCount={calculations?.overdueDaysCount}
      />
    </Flex>
  );
});

PenaltiesCalculator.displayName = 'PenaltiesCalculator';
