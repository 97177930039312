import { Actions, ActionType } from './actions';
import { BDUIFilterParam } from '@/components/BDUIFilter/types';

export type PlanningAllParams = {
  currentPage: number;
  itemsPerPage: number;
  filters: BDUIFilterParam[];
};

export const initialState: PlanningAllParams = {
  currentPage: 1,
  itemsPerPage: 10,
  filters: [],
};

export const planningAllReducer = (
  state: PlanningAllParams = initialState,
  action: Actions,
): PlanningAllParams => {
  switch (action.type) {
    case ActionType.UPDATE_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case ActionType.UPDATE_ITEMS_PER_PAGE:
      return { ...state, itemsPerPage: action.payload };
    case ActionType.FILTERS_UPDATE:
      return { ...state, filters: action.payload };
    default:
      return state;
  }
};
