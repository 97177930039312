'use client';

import { memo, MouseEvent, useCallback, useEffect, useState } from 'react';
import VirtualList from 'rc-virtual-list';
import { Flex, List, Popconfirm, Select, Spin, Tooltip } from 'antd';
import Vicon from '/public/icons/v.svg';
import Copy from '/public/icons/copy.svg';
import Export from '/public/icons/export.svg';
import Repeat from '/public/icons/new.svg';
import Delete from '/public/icons/trash.svg';
import { showOnPageOptions } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/constants';
import { deleteNmck, getXlsx, postNewNmck, useGetNmcks } from '@/api/nmckApi/nmckApi';
import { Nmck, NmckType } from '@/api/nmckApi/nmckApiTypes';
import { formatNumber } from '@/utils/formatNumber';
import styles from './CalculationHistory.module.scss';
import { useRouter } from 'next/navigation';
import { formatDate, getSplittedNmckIds, resetSplittedNmckIds } from '@/utils';
import { pageRoutes } from '@/app/pageRoutes';
import { postNewNmckDrugs } from '@/api/nmckDrugsApi/nmckDrugsApi';
import { DebounceInput } from '@/components';

const dropdownStyle = {
  width: 'max-content',
};
const ContainerHeight = 600;

const nmckData: Record<NmckType, { label: string; route: string }> = {
  [NmckType.MARKET]: { label: 'Анализ рынка', route: pageRoutes.nmckAnalysis },
  [NmckType.DRUGS]: { label: 'Лекарства', route: pageRoutes.nmckDrugs },
  [NmckType.GUARD]: { label: 'Охрана', route: pageRoutes.nmckGuard },
  [NmckType.MED_DEVICES]: {
    label: 'Медицинские изделия',
    route: pageRoutes.nmckMedDevices,
  },
};

export const CalculationHistory = memo(() => {
  const router = useRouter();
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<Nmck[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [loadingExport, setLoadingExport] = useState<number | null>(null);
  const [loadingCopy, setLoadingCopy] = useState<number | null>(null);
  const [newNmckIds, setNewNmckIds] = useState<number[] | undefined>(undefined);

  const { data: nmcks, isLoading: isNmcksLoading } = useGetNmcks({ page, size, searchString });

  useEffect(() => {
    if (nmcks) {
      if (page === 1 || size !== -1) {
        setData(nmcks.results);
      } else {
        setData((prev) => [...prev, ...nmcks.results]);
      }
      setLoading(false);
    }
  }, [nmcks, page, size]);

  useEffect(() => {
    if (!isNmcksLoading) {
      setLoading(false);
    }
  }, [isNmcksLoading]);

  const handleSearchChange = useCallback((value: string) => {
    setSearchString(value);
  }, []);

  const onScroll = useCallback(
    (e: React.UIEvent<HTMLElement, UIEvent>) => {
      if (
        Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1 &&
        !loading &&
        nmcks?.results.length
      ) {
        setLoading(true);
        setPage((prevPage) => prevPage + 1);
      }
    },
    [loading, nmcks?.results.length],
  );

  const handleExport = useCallback(
    async (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, id: number, name: string) => {
      setLoadingExport(id);
      e.stopPropagation();
      await getXlsx(id, name);
      setLoadingExport(null);
    },
    [],
  );

  const handleDelete = useCallback((id: number) => {
    const element = document.getElementById(`nmck-${id}`);
    if (element) {
      element.classList.add(styles.removed);
      setTimeout(() => {
        setData((prevData) => prevData.filter((item) => item.id !== id));
        deleteNmck(id);
      }, 1000);
    }
  }, []);

  const handleLoad = useCallback(() => {
    const ids = getSplittedNmckIds();
    setNewNmckIds(ids);
    if (!newNmckIds) return;
    for (const newNmckId of newNmckIds) {
      const element = document.getElementById(`nmck-${newNmckId}`);
      if (element) {
        element.classList.add(styles.highlighted);
        setTimeout(() => {
          element.classList.remove(styles.highlighted);
        }, 1000);
      }
    }

    resetSplittedNmckIds();
  }, [newNmckIds]);

  useEffect(() => {
    if (nmcks && !isNmcksLoading) {
      handleLoad();
    }
  }, [handleLoad, isNmcksLoading, nmcks]);

  const copyFromNmck = useCallback(
    async (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, { id, type }: Nmck) => {
      e.stopPropagation();
      setLoadingCopy(id);
      let newNmck: Nmck;

      if (type === NmckType.DRUGS) {
        newNmck = await postNewNmckDrugs({ copyFromId: id });
      } else {
        newNmck = await postNewNmck({ copyFromId: id });
      }
      setData((prevData) => [newNmck, ...prevData]);

      setTimeout(() => {
        const element = document.getElementById(`nmck-${newNmck.id}`);
        if (element) {
          element.classList.add(styles.highlighted);
          setTimeout(() => {
            element.classList.remove(styles.highlighted);
          }, 1000);
        }
      }, 4);

      setLoadingCopy(null);
    },
    [],
  );

  return (
    <>
      <div className={styles.header}>
        <Flex
          align="center"
          gap={32}
        >
          <div className={styles.title}>История расчётов НМЦК</div>
          <DebounceInput
            className={styles.searchInput}
            placeholder="Поиск расчёта"
            variant="borderless"
            handleValueChange={handleSearchChange}
          />
        </Flex>
      </div>
      <div className={styles.results}>
        <div className={styles.listHeader}>
          <Flex
            justify="space-between"
            align="center"
          >
            <Flex gap={273}>
              <Flex gap={59}>
                <div className={styles.date}>Дата</div>
                <div>Название расчёта</div>
              </Flex>
              <Flex gap={64}>
                <div>Метод закупки</div>
                <div>Итого</div>
              </Flex>
            </Flex>

            <div className={styles.size}>
              Показывать на странице:
              <Select
                dropdownStyle={dropdownStyle}
                title="Показывать на странице:"
                variant="borderless"
                suffixIcon={<Vicon className="v-icon" />}
                value={size}
                onChange={setSize}
                options={showOnPageOptions}
              />
            </div>
          </Flex>
        </div>
        <div className={styles.resultItems}>
          <List loading={isNmcksLoading}>
            <VirtualList
              data={data}
              itemKey="id"
              onScroll={onScroll}
              height={size === -1 ? ContainerHeight : undefined}
            >
              {(nmck: Nmck) => (
                <Flex
                  align="center"
                  justify="space-between"
                  onClick={() => {
                    router.push(`${nmckData[nmck.type].route}/${nmck.id}`);
                  }}
                  className={styles.resultItem}
                  id={`nmck-${nmck.id}`}
                  key={nmck.id}
                >
                  <Flex justify="space-between">
                    <Flex gap={16}>
                      <Flex gap={24}>
                        <div className={styles.date}>{formatDate(nmck.modified)}</div>
                        <Tooltip
                          placement="bottom"
                          arrow={false}
                          title={
                            nmck.generatedName
                              ? `${nmck.name} (${nmck.generatedName})`
                              : `${nmck.name}`
                          }
                        >
                          <div className={styles.name}>
                            {nmck.generatedName
                              ? `${nmck.name} (${nmck.generatedName})`
                              : `${nmck.name}`}
                          </div>
                        </Tooltip>
                      </Flex>
                      <Flex gap={26}>
                        <div className={styles.method}>{nmckData[nmck.type].label}</div>
                        <div className={styles.total}>{formatNumber(nmck.nmckTotal)} ₽</div>
                      </Flex>
                    </Flex>

                    <Flex gap={32}>
                      <div
                        className={styles.copy}
                        onClick={(e) => copyFromNmck(e, nmck)}
                      >
                        {loadingCopy === nmck.id ? <Spin size="small" /> : <Copy />}
                      </div>
                      <div
                        className={styles.export}
                        onClick={(e) => handleExport(e, nmck.id, nmck.name)}
                      >
                        {loadingExport === nmck.id ? <Spin size="small" /> : <Export />}
                        Excel
                      </div>
                      {nmck.type === NmckType.DRUGS && (
                        <div className={styles.repeat}>
                          <Repeat />
                          Повторный расчёт по приказу 1064н п.12
                        </div>
                      )}
                    </Flex>
                  </Flex>
                  <Popconfirm
                    title="Удаление расчёта"
                    description="Вы уверены что хотите удалить этот расчёт?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => handleDelete(nmck.id)}
                    onPopupClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className={styles.delete}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Delete />
                    </div>
                  </Popconfirm>
                </Flex>
              )}
            </VirtualList>
          </List>
        </div>
      </div>
    </>
  );
});

CalculationHistory.displayName = 'CalculationHistory';
