import useSWR, { SWRConfiguration } from 'swr';
import { getData } from '@/api/commonApi/commonApi';
import {
  ContractExecutionResponse,
  PurchasePlanResponse,
  PurchasesInProcessResponse,
} from './currentApiTypes';

export const useGetPurchasePlanItems = (pageNumber: number, pageSize: number) => {
  const params = new URLSearchParams();
  params.append('pageNumber', String(pageNumber));
  params.append('pageSize', String(pageSize));
  return useSWR(`/purchase-plan-items?${params}`, getData<PurchasePlanResponse>, {
    keepPreviousData: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  } as SWRConfiguration);
};

export const useGetPurchasesInProcess = (pageNumber: number, pageSize: number) => {
  const params = new URLSearchParams();
  params.append('pageNumber', String(pageNumber));
  params.append('pageSize', String(pageSize));
  return useSWR(`/purchases-in-process?${params}`, getData<PurchasesInProcessResponse>, {
    keepPreviousData: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  } as SWRConfiguration);
};

export const useGetContractsExecution = (pageNumber: number, pageSize: number) => {
  const params = new URLSearchParams();
  params.append('pageNumber', String(pageNumber));
  params.append('pageSize', String(pageSize));
  return useSWR(`/current-contract-execution?${params}`, getData<ContractExecutionResponse>, {
    keepPreviousData: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  } as SWRConfiguration);
};
