import React, { createContext, useReducer } from 'react';
import { planningAllReducer, initialState, PlanningAllParams } from './reducer';
import { Actions } from './actions';

const PlanningAllContext = createContext<{
  state: PlanningAllParams;
  dispatch: React.Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const PlanningAllProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(planningAllReducer, initialState);

  return (
    <PlanningAllContext.Provider value={{ state, dispatch }}>
      {children}
    </PlanningAllContext.Provider>
  );
};

export { PlanningAllContext, PlanningAllProvider };
