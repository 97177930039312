import React, { memo } from 'react';
import { Flex } from 'antd';
import cx from 'classnames';

import { TooltipLabel } from '@/widgets/Summary/components';
import { formatPrice } from '@/utils/formatPrice';

import styles from './SgosSubrow.module.scss';

type TooltipLabelProps = {
  label: string;
  tooltip: string;
  amount: number;
  percentage: number;
  className?: string;
};

export const SgosSubrow = memo(
  ({ label, amount, percentage, tooltip, className }: TooltipLabelProps) => {
    return (
      <div className={cx(styles.wrapper, className)}>
        <TooltipLabel
          label={label}
          tooltip={tooltip}
        />
        <div className={styles.amount}>
          <Flex
            gap={10}
            justify="space-between"
            className={styles.price}
            align="center"
          >
            {formatPrice(amount)} <span>₽</span>
          </Flex>
          <span>{percentage} %</span>
        </div>
      </div>
    );
  },
);

SgosSubrow.displayName = 'SgosSubrow';
