import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { Option, OptionWithNumberValue } from '@/types/types';

export const filterOption = (input: string, option?: Option | OptionWithNumberValue) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export const filterTree = (inputValue: string, treeNode: DefaultOptionType) => {
  const lowerCaseInputValue = inputValue.toLowerCase();

  if (treeNode.name.toLowerCase().includes(lowerCaseInputValue)) {
    return true;
  }

  if (treeNode.children) {
    return treeNode.children.some((child) => {
      return child.name.toLowerCase().includes(lowerCaseInputValue);
    });
  }

  return false;
};
