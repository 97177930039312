'use client';

import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { NmckRow, Proposition, PropositionType } from '@/api/nmckApi/nmckApiTypes';

import { Checkbox, Input, Popover, Tooltip } from 'antd';
import Trash from '/public/icons/trash.svg';
import Cross from '/public/icons/cross.svg';
import { debounce } from 'lodash';
import { updateProposition, useGetNmckById } from '@/api/nmckApi/nmckApi';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { formatPrice, parsePrice } from '@/utils/formatPrice';

import styles from './InputWithSupplier.module.scss';
import Link from 'next/link';
import { deleteData } from '@/api/commonApi/commonApi';
import { eventsGTM, sendEventGTM } from '@/metrika';

type Props = {
  proposition: Proposition;
  record: NmckRow;
  id: string;
};

export const InputWithSupplier = memo(({ proposition, record, id }: Props) => {
  const { mutate: refreshCalculation } = useGetNmckById(id);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isSameNameForColumn, setSameNameForColumn] = useState(false);
  const [inputPrice, setInputPrice] = useState(formatPrice(String(proposition.price)));
  const [inputDescription, setInputDescription] = useState(
    proposition.description || `Поставщик ${proposition.columnNumber}`,
  );
  const isEis = useMemo(() => proposition.type === PropositionType.EIS, [proposition.type]);

  useEffect(() => {
    setInputPrice(formatPrice(String(proposition.price)));
  }, [proposition.price]);

  const debouncedPutData = useMemo(
    () =>
      debounce(async (newPrice: number | null, newDescription: string, newForAllRows: boolean) => {
        sendEventGTM(eventsGTM.nmckRowPriceChange);
        await updateProposition(
          id,
          record.id,
          proposition,
          PropositionType.KP,
          newPrice,
          newDescription,
          newForAllRows,
          null,
        );
        await refreshCalculation();
      }, 500),
    [record, refreshCalculation, proposition, id],
  );
  const getDescription = useCallback(
    () => inputDescription || `Поставщик ${proposition.columnNumber}`,
    [inputDescription, proposition.columnNumber],
  );

  const handleOpenChange = useCallback(
    async (newOpen: boolean) => {
      !isEis && setPopupOpen(newOpen);
      setInputDescription(proposition.description);
      if (!newOpen && inputDescription !== proposition.description) {
        await debouncedPutData(parsePrice(inputPrice), getDescription(), isSameNameForColumn);
      }
    },
    [
      debouncedPutData,
      inputDescription,
      inputPrice,
      isSameNameForColumn,
      proposition.description,
      getDescription,
      isEis,
    ],
  );

  const handlePriceChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputPrice = e.target.value;
      const formattedPrice = formatPrice(inputPrice);
      setInputPrice(formattedPrice);
      if (parsePrice(formattedPrice) !== proposition.price && !formattedPrice.endsWith(',')) {
        await debouncedPutData(parsePrice(formattedPrice), getDescription(), isSameNameForColumn);
      }
    },
    [debouncedPutData, isSameNameForColumn, getDescription, proposition.price],
  );

  const handleDeleteProposition = useCallback(async () => {
    await deleteData(`/nmck/${id}/proposition/${proposition.columnNumber}`);
    await refreshCalculation();
  }, [refreshCalculation, proposition.columnNumber, id]);

  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        inputDescription !== proposition.description &&
          debouncedPutData(parsePrice(inputPrice), getDescription(), isSameNameForColumn);
        setPopupOpen(false);
      }
    },
    [
      debouncedPutData,
      inputDescription,
      inputPrice,
      isSameNameForColumn,
      proposition.description,
      getDescription,
    ],
  );

  const handleInputDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    setInputDescription(newName);
  }, []);

  const handleChange = useCallback((e: CheckboxChangeEvent) => {
    setSameNameForColumn(e.target.checked);
  }, []);

  const popoverSupplierContent = useMemo(
    () => (
      <div className={styles.popupContent}>
        <div className={styles.popupHeader}>
          <Input
            className={styles.popupInput}
            onKeyDown={handleInputKeyDown}
            onChange={handleInputDescriptionChange}
            value={inputDescription}
          />
          <Trash
            className={styles.trash}
            onClick={handleDeleteProposition}
          />
        </div>
        <div className={styles.change}>
          <Checkbox
            checked={isSameNameForColumn}
            onChange={handleChange}
          />
          <p>Изменить для всех значений “Цена {proposition.columnNumber}”</p>
        </div>
      </div>
    ),
    [
      handleInputKeyDown,
      handleInputDescriptionChange,
      handleDeleteProposition,
      isSameNameForColumn,
      handleChange,
      proposition.columnNumber,
      inputDescription,
    ],
  );
  const clearProposition = useCallback(() => {
    debouncedPutData(null, `Поставщик ${proposition.columnNumber}`, isSameNameForColumn);
    setInputPrice('');
  }, [debouncedPutData, proposition.columnNumber, isSameNameForColumn]);
  return (
    <>
      <div className={styles.wrapper}>
        <Input
          disabled={isEis}
          placeholder="Цена"
          value={inputPrice}
          onChange={handlePriceChange}
          variant="borderless"
          className={styles.priceInput}
        />

        {inputPrice && (
          <Tooltip
            title={proposition.description}
            placement="bottom"
            arrow={false}
          >
            <Popover
              arrow={false}
              placement="bottom"
              open={isPopupOpen}
              onOpenChange={handleOpenChange}
              trigger="click"
              content={popoverSupplierContent}
            >
              {isEis ? (
                <Link
                  href={`https://zakupki.gov.ru/epz/contract/contractCard/common-info.html?reestrNumber=${
                    proposition.description.split(' ')[1]
                  }`}
                  target="_blank"
                  className={styles.supplierDisabled}
                >
                  {proposition.description}
                </Link>
              ) : (
                <div className={styles.supplier}>{proposition.description}</div>
              )}
            </Popover>
          </Tooltip>
        )}
        <div
          className={styles.crossIcon}
          onClick={clearProposition}
        >
          <Cross />
        </div>
      </div>
    </>
  );
});
InputWithSupplier.displayName = 'InputWithSupplier';
