import { Modal } from 'antd';
import { CSSProperties, memo } from 'react';

import { Characteristic } from '@/api/docsApi/docsApiTypes';

import styles from './CharacteristicsModal.module.scss';

const modalStyle: Record<string, CSSProperties> = {
  body: { overflowY: 'auto', maxHeight: 'calc(100vh - 196px)' },
};

type CharacteristicModalProps = {
  items: Characteristic[];
  open?: boolean;
  onCancel?: VoidFunction;
};

export const CharacteristicsModal = memo(({ items, open, onCancel }: CharacteristicModalProps) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      width={'95%'}
      height={'95%'}
      styles={modalStyle}
      footer={null}
      className={styles.modal}
      title={'Характеристики'}
      centered
    >
      <ul className={styles.characteristicList}>
        {items.map((item, id) => {
          return (
            <li
              key={id}
              className={styles.characteristicItem}
            >
              <span className={styles.characteristicName}>{item.name}:</span>
              <span className={styles.characteristicValue}>{item.value}</span>
            </li>
          );
        })}
      </ul>
    </Modal>
  );
});

CharacteristicsModal.displayName = 'CharacteristicsModal';
