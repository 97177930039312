import { memo, useMemo } from 'react';
import { Flex, Modal } from 'antd';
import { NPAItem } from '@/api/okpdApi/okpdApiTypes';
import styles from './OkpdKtruDetailNPAModal.module.scss';
import Link from 'next/link';
import cx from 'classnames';

type OkpdDetailTabsNPAModalProps = {
  isOpen: boolean;
  okpdCode: string;
  okpdName: string;
  npa?: NPAItem;
  onClose: () => void;
};

export const OkpdKtruDetailNPAModal = memo(
  ({ isOpen, okpdName, okpdCode, npa, onClose }: OkpdDetailTabsNPAModalProps) => {
    const modalTitleRender = useMemo(
      () => (
        <Flex
          className={styles.header}
          gap={64}
          justify={'space-between'}
        >
          <div className={styles.title}>
            {okpdName} — {okpdCode}
          </div>
          {npa?.garantLink && (
            <Link
              href={npa.garantLink}
              target="_blank"
              className={styles.headerLink}
            >
              Полный текст НПА
            </Link>
          )}
        </Flex>
      ),
      [npa, okpdCode, okpdName],
    );

    return (
      <Modal
        centered
        open={isOpen}
        footer={null}
        title={modalTitleRender}
        classNames={{ body: styles.modalBody }}
        className={styles.modal}
        width="80%"
        onCancel={onClose}
      >
        {npa && (
          <Flex
            vertical
            gap={20}
            className={styles.content}
          >
            {npa.caption && (
              <div className={styles.text}>
                {npa.caption}&nbsp;
                {npa.captionForLink && npa.garantLink && (
                  <Link
                    href={npa.garantLink}
                    target="_blank"
                  >
                    {npa.captionForLink}
                  </Link>
                )}
              </div>
            )}
            {npa.note && (
              <div
                className={cx(styles.note, styles.text)}
                dangerouslySetInnerHTML={{
                  __html: npa.note,
                }}
              />
            )}
            {npa.notes &&
              npa.notes.map((item) => (
                <Flex
                  vertical
                  gap={20}
                  key={item.caption}
                >
                  <div className={cx(styles.caption, styles.text)}>{item.caption}</div>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.text }}
                    className={cx(styles.text, styles.mdText)}
                  />
                </Flex>
              ))}
          </Flex>
        )}
      </Modal>
    );
  },
);

OkpdKtruDetailNPAModal.displayName = 'OkpdKtruDetailNPAModal';
