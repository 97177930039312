import { Flex, Spin } from 'antd';
import { OkpdCard, Pagination } from '@/widgets/OkpdKtpu/components';
import { useOkpdKtruList } from '@/hooks';
import { EntityType, OkpdResult } from '@/api/okpdApi/okpdApiTypes';
import listStyles from '../List.module.scss';
import { memo } from 'react';
import { CharacteristicsParam } from '@/types/types';
import { SetOkpdKtruModalPageParamsType } from '@/components/OkpdFlowModal/types';

type Okp2ListProps = {
  searchString: string;
  characteristics: CharacteristicsParam[];
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const OkpdList = memo(
  ({ searchString, characteristics, setModalPageParams }: Okp2ListProps) => {
    const { list, isListLoading, isMoreLoading, hasMoreResults, handleShowMore } =
      useOkpdKtruList<OkpdResult>({
        searchString,
        listType: EntityType.OKPD,
        characteristics,
      });

    return (
      <div>
        <Flex
          justify="space-between"
          align="center"
          className={listStyles.wrapper}
        >
          <Flex
            gap={16}
            align="center"
          >
            <h2 className={listStyles.title}>ОКПД 2</h2>
            {isListLoading && (
              <Flex justify="center">
                <Spin />
              </Flex>
            )}
          </Flex>
        </Flex>
        {!isListLoading && list.length ? (
          <>
            <Flex
              vertical={true}
              gap={16}
            >
              {list.map((item) => (
                <OkpdCard
                  key={item.id}
                  okpd={item}
                  setModalPageParams={setModalPageParams}
                />
              ))}
            </Flex>
            <Pagination
              isMoreLoading={isMoreLoading}
              hasMoreResults={hasMoreResults}
              handleShowMore={handleShowMore}
            />
          </>
        ) : (
          <div className={listStyles.noData}>Не найдено</div>
        )}
      </div>
    );
  },
);

OkpdList.displayName = 'OkpdList';
