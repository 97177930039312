import { OptionWithNumberValue } from '@/types/types';

export const DEFAULT_PAGE_SIZE = 25;

export const PAGE_SIZE_OPTIONS: OptionWithNumberValue[] = [
  {
    label: '10',
    value: 10,
  },
  {
    label: String(DEFAULT_PAGE_SIZE),
    value: DEFAULT_PAGE_SIZE,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];
