import { memo } from 'react';

import styles from './PenaltiesFormula.module.scss';

export const PenaltiesFormula = memo(() => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Формула расчёта:</p>
      <p className={styles.formula}>П = (Ц - В) × Ставка ЦБ/300 × ДП</p>
      <p className={styles.hint}>
        П — размер пеней;
        <br />Ц — цена контракта (с 1 апреля 2020 года также — отдельного этапа исполнения
        контракта); <br />
        В — стоимость фактически исполненных поставщиком обязательств; <br />
        Ставка ЦБ/300 — размер ставки ЦБ на дату уплаты пеней; <br />
        ДП — количество дней просрочки.
      </p>
    </div>
  );
});

PenaltiesFormula.displayName = 'PenaltiesFormula';
