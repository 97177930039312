import { StatusType } from '@/widgets/ReestrAll/types/types';
import { Actions, ActionType } from './actions';
import { BDUIFilterParam } from '@/components/BDUIFilter/types';

export type ReestrAllParams = {
  search: string;
  status: string;
  concluded: string;
  currentPage: number;
  itemsPerPage: number;
  filters: BDUIFilterParam[];
};

export const initialState: ReestrAllParams = {
  search: '',
  status: StatusType.ALL,
  concluded: String(new Date().getFullYear()),
  currentPage: 1,
  itemsPerPage: 10,
  filters: [],
};

export const reestrAllReducer = (
  state: ReestrAllParams = initialState,
  action: Actions,
): ReestrAllParams => {
  switch (action.type) {
    case ActionType.SET_SEARCH:
      return { ...state, search: action.payload };
    case ActionType.SET_STATUS:
      return { ...state, status: action.payload };
    case ActionType.SET_CONCLUDED:
      return { ...state, concluded: action.payload };
    case ActionType.UPDATE_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case ActionType.UPDATE_ITEMS_PER_PAGE:
      return { ...state, itemsPerPage: action.payload };
    case ActionType.FILTERS_UPDATE:
      return { ...state, filters: action.payload };
    default:
      return state;
  }
};
