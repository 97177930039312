import { NmckType } from '../nmckApi/nmckApiTypes';
import { TokenBasedPageableResponse } from '@/api/commonApi/commonApiTypes';

export type OkpdInitialList = OkpdInitialListItem[];
export type OkpdInitialListItem = {
  code: string;
  title: string;
};

export type AllNpa = Npa[];
export type Npa = {
  name: string;
  href: string;
};

export type SuppliersResponse = TokenBasedPageableResponse<SupplierResult>;

export type SupplierResult = {
  name: string;
  phone: string;
  email: string;
  isSmp: boolean;
  isProm: boolean;
  contractsWithoutFines: boolean;
  inn: number;
  kpp: string;
  address: string;
  lastContractRegNum: number;
  suppliedCodes: string[];
  supplyName: string;
  contractsFinished: number;
  price: number;
};

export enum NPAType {
  NATIONAL = 'national',
  PREF = 'pref',
  QUOT = 'quot',
  OTHER = 'other',
}

export enum KtruCharacteristicSelectionType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
  ALWAYS_SELECTED = 'ALWAYS_SELECTED',
}

type KtruCharacteristicValue = { id: number; value: string };

export type KtruCharacteristic = {
  name: string;
  kind: string;
  type: string;
  values: KtruCharacteristicValue[];
  selection: KtruCharacteristicSelectionType;
  unit: string;
  isMandatory: boolean;
};
export type KtruResult = {
  code: string;
  name: string;
  parent: boolean;
  isTemplate: boolean;
  characteristics: KtruCharacteristic[];
  standardContracts: StandardContractType[];
  npa: NPA;
  nkmiId?: number;
};

export type KtruResponse = TokenBasedPageableResponse<KtruResult>;

export type OkpdResult = {
  id: number;
  code: string;
  name: string;
  ciName?: string;
  hasKtru: boolean;
  standardContracts: StandardContractType[];
  npa: NPA;
};

export type OkpdDetail = OkpdResult & {
  parents: string[];
  active: boolean;
  nmckMethod: {
    name: string;
    type: NmckType;
  };
  ktruCount: number;
  unit?: string;
  countOfContracts: number;
  children: OkpdResult[];
};

type OkpdShortItem = {
  code: string;
  name: string;
};

type Okei = {
  code: number;
  name: string;
};

type Nkmi = {
  code: string;
  name: string;
};

export type KtruDetail = KtruResult & {
  parents: string[];
  active: boolean;
  ktruCount: number;
  countOfContracts: number;
  mandatorySince: string;
  okpd: OkpdShortItem;
  okeis: Okei[];
  additionalOkpds: OkpdShortItem[];
  additionalCharacteristicsRestrictedComment?: string;
  additionalCharacteristicsRestricted: boolean;
  reglaments: string[];
  unit?: string;
  nmckMethod: {
    name: string;
    type: NmckType;
  };
  nkmis: Nkmi[];
};

export type OkpdResponse = TokenBasedPageableResponse<OkpdResult>;

export type TruCountResponse = {
  count: number;
};

export type NPAItem = {
  code: string;
  applicable: boolean;
  caption: string | null;
  captionForLink: string | null;
  garantLink: string | null;
  note: string | null;
  notes:
    | {
        caption: string;
        text: string;
      }[]
    | null;
};

export type NPA = Record<NPAType, NPAItem[]>;

export type StandardContractType = Record<'first' | 'second', string>;

export type CharacteristicForFilter = {
  name: string;
  code: string;
  values: {
    id: number;
    value: string;
  }[];
};

export enum EntityType {
  OKPD = 'OKPD',
  KTRU = 'KTRU',
}
