import React, { createContext, useReducer } from 'react';
import { nmckGridReducer } from './reducer';
import { Actions } from '@/components/NmckGrid/context/actions';
import { TokenBasedPaginationStateWithPageSize } from '@/api/commonApi/commonApiTypes';
import { DEFAULT_PAGE_SIZE } from '@/components/Table/TablePagination/constants';

const initialState: TokenBasedPaginationStateWithPageSize = {
  pageSize: DEFAULT_PAGE_SIZE,
  token: null,
  hasMoreResults: false,
};
const NmckGridContext = createContext<{
  state: TokenBasedPaginationStateWithPageSize;
  dispatch: React.Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const NmckGridProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(nmckGridReducer, initialState);

  return (
    <NmckGridContext.Provider value={{ state, dispatch }}>{children}</NmckGridContext.Provider>
  );
};

export { NmckGridContext, NmckGridProvider };
