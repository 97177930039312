export const formatPrice = (price: string | number) => {
  let value = String(price);
  value = value.replace(/[^\d.,]/g, '');
  value = value.replace(/\./g, ',');
  value = value.replace(/,/g, '.').replace(/\.(?=.*\.)/g, '');
  value = value.replace(/^0+(\d)/, '0');
  const parts = value.split('.');

  if (parts[1] && parts[1].length > 2) {
    parts[1] = parts[1].slice(0, 2);
  }

  if (parts[0]) {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');
  }

  return parts.join(',');
};

export const parsePrice = (value: string) => {
  value = value.replace(/\s/g, '');
  value = value.replace(',', '.');
  if (value === '') return null;
  return parseFloat(value);
};
