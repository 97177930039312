import { memo, useCallback } from 'react';
import { Button, Flex, Select, SelectProps, Space } from 'antd';
import Plus from '/public/icons/plus.svg';
import Export from '/public/icons/export.svg';
import Vicon from '/public/icons/v.svg';
import { getXlsx, postNewNmckRow, useGetNmckById } from '@/api/nmckApi/nmckApi';
import { formatNumber } from '@/utils/formatNumber';
import styles from './NmckGridFooter.module.scss';
import { eventsGTM, sendEventGTM } from '@/metrika';

type Props = {
  id: string;
};

const labelRender: SelectProps['labelRender'] = ({ value }) => (
  <Flex
    align="center"
    gap={4}
  >
    <div className={styles.text}>Тип файла:</div>
    <div>{value}</div>
  </Flex>
);

export const NmckGridFooter = memo(({ id }: Props) => {
  const { data, mutate: refreshCalculation } = useGetNmckById(id);
  const addRow = useCallback(async () => {
    if (data) {
      sendEventGTM(eventsGTM.nmckAddRow);
      await postNewNmckRow(id);
      await refreshCalculation();
    }
  }, [data, id, refreshCalculation]);

  const generateReport = useCallback(() => {
    sendEventGTM(eventsGTM.nmckDownloadExcel);
    data && getXlsx(data.id, data.name);
  }, [data]);

  return (
    <div className={styles.wrapper}>
      <Button
        icon={<Plus />}
        className={styles.addBtn}
        onClick={addRow}
      >
        Добавить позицию {data && data.rows.length + 1}
      </Button>
      <Flex
        align="center"
        gap={12}
      >
        <Flex align="center">
          <Space>
            <div className={styles.text}>Итого:</div>
            <div>{data && formatNumber(data.nmckTotal)} ₽</div>
          </Space>
        </Flex>
        <Select
          labelRender={labelRender}
          defaultValue={'Excel'}
          variant="borderless"
          suffixIcon={<Vicon />}
        />
        <Button
          type="primary"
          icon={<Export />}
          className={styles.exportBtn}
          onClick={generateReport}
        >
          Сформировать отчёт
        </Button>
      </Flex>
    </div>
  );
});

NmckGridFooter.displayName = 'NmckGridFooter';
