import { Flex, Spin } from 'antd';
import { KtruCard, Pagination } from '@/widgets/OkpdKtpu/components';
import { useOkpdKtruList } from '@/hooks';
import { EntityType, KtruResult } from '@/api/okpdApi/okpdApiTypes';
import listStyles from '../List.module.scss';
import { memo } from 'react';
import { CharacteristicsParam } from '@/types/types';
import { SetOkpdKtruModalPageParamsType } from '@/components/OkpdFlowModal/types';

type KtruListProps = {
  searchString: string;
  characteristics: CharacteristicsParam[];
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const KtruList = memo(
  ({ searchString, characteristics, setModalPageParams }: KtruListProps) => {
    const { list, isListLoading, isMoreLoading, hasMoreResults, handleShowMore } =
      useOkpdKtruList<KtruResult>({
        searchString,
        listType: EntityType.KTRU,
        characteristics,
      });

    return (
      <div>
        <Flex
          justify="space-between"
          align="center"
          className={listStyles.wrapper}
        >
          <Flex
            gap={16}
            align="center"
          >
            <h2 className={listStyles.title}>КТРУ</h2>
            {isListLoading && (
              <Flex justify="center">
                <Spin />
              </Flex>
            )}
          </Flex>
        </Flex>
        {!isListLoading && list.length ? (
          <>
            <Flex
              vertical={true}
              gap={16}
            >
              {list.map((item) => (
                <KtruCard
                  key={item.code}
                  ktru={item}
                  setModalPageParams={setModalPageParams}
                />
              ))}
            </Flex>
            <Pagination
              isMoreLoading={isMoreLoading}
              hasMoreResults={hasMoreResults}
              handleShowMore={handleShowMore}
            />
          </>
        ) : (
          <div className={listStyles.noData}>Отсутствует в КТРУ</div>
        )}
      </div>
    );
  },
);

KtruList.displayName = 'KtruList';
