import { Dispatch, memo, SetStateAction, useMemo } from 'react';
import styles from './KtruDetailTabsCharacteristicsList.module.scss';
import { KtruCharacteristicRow } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/types';
import { KtruDetailTabsCharacteristicsRow } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/KtruDetailTabsCharacteristicsRow/KtruDetailTabsCharacteristicsRow';
import { getRowsByMandatory } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/utils';

type Props = {
  list: KtruCharacteristicRow[];
  setList: Dispatch<SetStateAction<KtruCharacteristicRow[]>>;
  setActiveTab: (tab: string) => void;
  ktruListTabDisabled: boolean;
};

export const KtruDetailTabsCharacteristicsList = memo(
  ({ list, setList, setActiveTab, ktruListTabDisabled }: Props) => {
    const requiredList = useMemo(() => getRowsByMandatory(list, true), [list]);
    const optionalList = useMemo(() => getRowsByMandatory(list, false), [list]);

    return (
      <div className={styles.list}>
        {!!requiredList.length && (
          <div className={styles.listTitle}>Обязательные характеристики</div>
        )}
        {requiredList.map((item) => (
          <KtruDetailTabsCharacteristicsRow
            key={item.name}
            row={item}
            setList={setList}
            setActiveTab={setActiveTab}
            ktruListTabDisabled={ktruListTabDisabled}
          />
        ))}

        {!!optionalList.length && (
          <div className={styles.listTitle}>Необязательные характеристики</div>
        )}
        {optionalList.map((item) => (
          <KtruDetailTabsCharacteristicsRow
            key={item.name}
            row={item}
            setList={setList}
            setActiveTab={setActiveTab}
            ktruListTabDisabled={ktruListTabDisabled}
          />
        ))}
      </div>
    );
  },
);

KtruDetailTabsCharacteristicsList.displayName = 'KtruDetailTabsCharacteristicsList';
