import { memo } from 'react';

import styles from './TabTitle.module.scss';

type TabTitleProps = {
  title: string;
  count?: number;
};

export const TabTitle = memo(({ title, count }: TabTitleProps) => {
  return (
    <div className={styles.wrapper}>
      {title}
      <div className={styles.count}>{count ?? 0}</div>
    </div>
  );
});

TabTitle.displayName = 'TabTitle';
