import { Dispatch, SetStateAction } from 'react';

export enum OkpdKtruPageTypes {
  LISTS = 'LISTS',
  OKPD = 'OKPD',
  KTRU = 'KTRU',
}

export type OkpdKtruModalPageParams = {
  page: OkpdKtruPageTypes;
  code: string;
  searchString: string;
};

export type SetOkpdKtruModalPageParamsType = Dispatch<SetStateAction<OkpdKtruModalPageParams>>;
