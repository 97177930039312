import useSWR, { SWRConfiguration } from 'swr';
import { SgosEdited, SgosResponse, SummaryChartsResponse } from './summaryApiTypes';
import { putData, getData } from '@/api/commonApi/commonApi';

export const useGetSummaryCharts = (dateFrom: string, dateTo: string) => {
  const params = new URLSearchParams();

  params.append('dateFrom', dateFrom);
  params.append('dateTo', dateTo);
  return useSWR(`/eis-all-contracts-summary?${params}`, getData<SummaryChartsResponse>, {
    revalidateOnFocus: false,
  } as SWRConfiguration);
};

export const useGetSummarySgos = (dateFrom: string, dateTo: string) => {
  const params = new URLSearchParams();

  params.append('dateFrom', dateFrom);
  params.append('dateTo', dateTo);
  return useSWR(`/eis-all-contracts-editable-summary?${params}`, getData<SgosResponse>, {
    revalidateOnFocus: false,
  } as SWRConfiguration);
};

export const putSummarySgos = async (
  dateFrom: string,
  dateTo: string,
  body: SgosEdited,
): Promise<SgosResponse> => {
  const params = new URLSearchParams();

  params.append('dateFrom', dateFrom);
  params.append('dateTo', dateTo);

  return putData<SgosResponse, SgosEdited>(`/eis-all-contracts-editable-summary?${params}`, body);
};
