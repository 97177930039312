import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AutoComplete, InputRef, Spin, Tooltip } from 'antd';
import DotsIcon from '/public/icons/dots.svg';

import { NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { updateNmckRow, useGetNmckById } from '@/api/nmckApi/nmckApi';

import styles from './Okpd2Input.module.scss';
import { KtruResult, OkpdResult } from '@/api/okpdApi/okpdApiTypes';
import { useGetOkpdList } from '@/api/okpdApi/okpdApi';
import { DebounceInput, OkpdFlowModal } from '@/components';
import { eventsGTM, sendEventGTM } from '@/metrika';

export const renderOption = (option: OkpdResult | KtruResult) => (
  <div className={styles.autocomplete}>
    <div>{`${option.code} -`}&nbsp;</div>
    <Tooltip
      arrow={false}
      title={<div dangerouslySetInnerHTML={{ __html: option.name }} />}
      placement="right"
    >
      <div
        className={styles.autocompleteName}
        dangerouslySetInnerHTML={{ __html: option.name }}
      />
    </Tooltip>
  </div>
);

type Props = {
  row: NmckRow;
  id: string;
};

export const Okpd2Input = memo(({ row, id }: Props) => {
  const [okpd2InputValue, setOkpd2InputValue] = useState(row.okpdCode ?? row.ktruCode);
  const [searchString, setSearchString] = useState(row.name);
  const [isOkpdFlowModalOpen, setIsOkpdFlowModalOpen] = useState(false);
  const okpd2InputRef = useRef<InputRef>(null);

  const { mutate: refreshCalculation } = useGetNmckById(id);
  const { data, isLoading } = useGetOkpdList({
    searchString: okpd2InputValue ?? searchString,
    characteristics: [],
  });

  const handleUpdateRow = useCallback(
    async (okpdCode?: string) => {
      await updateNmckRow(id, { ...row, okpdCode });
      await refreshCalculation();
    },
    [id, refreshCalculation, row],
  );

  const handleOkpdInputChange = useCallback(
    (value: string) => {
      setSearchString(value);
      setOkpd2InputValue(value);
      if (!value) {
        handleUpdateRow();
      }
    },
    [handleUpdateRow],
  );

  const options = useMemo(
    () =>
      data?.results.map((item: OkpdResult) => ({
        value: item.code,
        data: item,
        label: renderOption(item),
      })) ?? [],
    [data],
  );

  const onSelect = useCallback(
    (_: unknown, { data }: { data: OkpdResult }) => {
      sendEventGTM(eventsGTM.nmckRowOkpdChangeBySelect, { value: data.code });
      handleUpdateRow(data.code);
      okpd2InputRef.current?.blur();
      setOkpd2InputValue(data.code);
    },
    [handleUpdateRow],
  );

  const handleOpenModal = useCallback(() => setIsOkpdFlowModalOpen(true), []);

  const handleAutocompleteFocus = useCallback(() => {
    if (!okpd2InputValue && !searchString && row.name) {
      setSearchString(row.name);
      handleOpenModal();
    }
  }, [handleOpenModal, okpd2InputValue, row.name, searchString]);

  useEffect(() => {
    setOkpd2InputValue(row.okpdCode ?? row.ktruCode);
    setSearchString(row.name);
  }, [row.ktruCode, row.okpdCode, row.name]);

  return (
    <>
      <AutoComplete
        options={options}
        popupMatchSelectWidth={500}
        onSelect={onSelect}
        value={okpd2InputValue}
        className={styles.autocomplite}
        onFocus={handleAutocompleteFocus}
      >
        <DebounceInput
          ref={okpd2InputRef}
          className={styles.okpd2Input}
          suffix={
            isLoading ? (
              <Spin size="small" />
            ) : (
              <DotsIcon
                className={styles.okpd2InputIcon}
                onClick={handleOpenModal}
              />
            )
          }
          handleValueChange={handleOkpdInputChange}
        />
      </AutoComplete>
      <OkpdFlowModal
        isOpen={isOkpdFlowModalOpen}
        setIsOpen={setIsOkpdFlowModalOpen}
        searchString={searchString || (okpd2InputValue ?? '')}
        id={id}
        row={row}
        refreshCalculation={refreshCalculation}
      />
    </>
  );
});

Okpd2Input.displayName = 'Okpd2Input';
