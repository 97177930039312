import { memo, PropsWithChildren } from 'react';
import Link, { LinkProps } from 'next/link';

type Props = {
  className?: string;
  isLink?: boolean;
} & LinkProps &
  PropsWithChildren;

export const OkpdLink = memo(({ className, href, children, isLink = true, onClick }: Props) => {
  if (isLink && href) {
    return (
      <Link
        href={href}
        className={className}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link
      href={{}}
      className={className}
      onClick={onClick}
    >
      {children}
    </Link>
  );
});

OkpdLink.displayName = 'OkpdLink';
