import { memo, useCallback, useContext } from 'react';
import { Button, Flex, Pagination, Select } from 'antd';
import Vicon from '/public/icons/v.svg';
import Export from '/public/icons/export.svg';
import { ReestrAllPageResultsTable } from './components/ReestrAllPageResultsTable';
import { resultsTableColumns } from './data/data';
import styles from './ReestrAllPageResults.module.scss';

import { showOnPageOptions } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/constants';
import { EisAllContractsResponse, EisContractItem } from '@/api/reestrAllApi/reestrAllApiTypes';
import { ReestrAllContext } from '@/widgets/ReestrAll/context/context';
import { actions } from '@/widgets/ReestrAll/context/actions';
import { getXlsx } from '@/api/reestrAllApi/reestrAllApi';
import { useSortableTableColumns } from '@/components/Table/TableColumnsSettings/hooks';
import { TableColumnsSettings } from '@/components/Table/TableColumnsSettings/TableColumnsSettings';

const options = showOnPageOptions.slice(0, -1);
const dropdownStyle = {
  width: 'max-content',
};

type ReestrAllPageResultsProps = {
  contracts: EisAllContractsResponse | undefined;
  isContractsLoading: boolean;
};

export const ReestrAllPageResults = memo(
  ({ contracts, isContractsLoading }: ReestrAllPageResultsProps) => {
    const { state, dispatch } = useContext(ReestrAllContext);

    const { orderedColumns, orderedColumnsMap, setOrderedColumnsMap } =
      useSortableTableColumns<EisContractItem>({
        columns: resultsTableColumns,
      });

    const handleShowOnPageSelectChange = useCallback(
      (value: number) => {
        dispatch(actions.itemsPerPageUpdate(value));
      },
      [dispatch],
    );

    const pageChange = useCallback(
      (page: number) => {
        dispatch(actions.currentPageUpdate(page));
      },
      [dispatch],
    );

    return (
      <div className={styles.wrapper}>
        <ReestrAllPageResultsTable
          contracts={contracts}
          isContractsLoading={isContractsLoading}
          selectedColumns={orderedColumns}
        />
        <div className={styles.settings}>
          <Flex
            align="center"
            gap={42}
          >
            <TableColumnsSettings<EisContractItem>
              orderedColumnMap={orderedColumnsMap}
              setOrderedColumnMap={setOrderedColumnsMap}
              columnsData={orderedColumns}
            />
            <div className={styles.pagination}>
              Страницы:
              <Pagination
                showSizeChanger={false}
                current={state.currentPage}
                total={contracts && contracts.count}
                pageSize={state.itemsPerPage}
                onChange={pageChange}
              />
            </div>
            <div className={styles.sorting}>
              Показывать на странице:
              <Select
                dropdownStyle={dropdownStyle}
                title="Показывать на странице:"
                variant="borderless"
                suffixIcon={
                  <div className={styles.iconV}>
                    <Vicon />
                  </div>
                }
                value={state.itemsPerPage}
                onChange={handleShowOnPageSelectChange}
                options={options}
              />
            </div>
          </Flex>
          <Flex gap={24}>
            <div className={styles.fileType}>
              <div className={styles.text}>Тип файла:</div>
              <Select
                defaultValue={'Excel'}
                variant="borderless"
                suffixIcon={<Vicon className={styles.icon} />}
              />
            </div>
            <Button
              type="text"
              icon={<Export />}
              className={styles.exportBtn}
              onClick={getXlsx}
            >
              Сохранить
            </Button>
          </Flex>
        </div>
      </div>
    );
  },
);

ReestrAllPageResults.displayName = 'ReestrAllPageResults';
