import { memo, useState, useMemo, useCallback, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart,
  ArcElement,
  DoughnutControllerChartOptions,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  Legend,
  Tooltip,
  ChartEvent,
  ActiveElement,
  ChartTypeRegistry,
  Point,
  BubbleDataPoint,
  TooltipItem,
  LegendItem,
  Color,
} from 'chart.js';
import { isNumber } from 'lodash';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

import { useGetSummaryCharts } from '@/api/summaryApi/summaryApi';
import {
  getChartDataMap,
  getChildChartDataMap,
  getChildTooltipLabel,
  getDataChild,
  getTooltipLabel,
  getTooltipTitle,
  getChildtTooltipTitle,
  findCurrentData,
} from '@/widgets/Summary/utils/utils';
import { PlacementResult } from '@/api/summaryApi/summaryApiTypes';

import { SumDisplay } from '@/widgets/Summary/components';

import styles from './ChartsDisplay.module.scss';

type ChartsInfoProps = {
  dateFrom: string;
  dateTo: string;
};

Chart.register(ArcElement, Tooltip, Legend);

export const ChartsInfo = memo(({ dateFrom, dateTo }: ChartsInfoProps) => {
  const [childChart, setChildChart] = useState<PlacementResult[] | undefined>(undefined);
  const [mainLegend, setMainLegend] = useState<LegendItem[] | null>(null);
  const [childLegend, setChildLegend] = useState<LegendItem[] | null>(null);
  const [childInfo, setChildInfo] = useState<{
    sum?: number;
    text?: string;
  }>({
    sum: undefined,
    text: undefined,
  });

  const { data: charts } = useGetSummaryCharts(dateFrom, dateTo);

  const [mainRef, setMainRef] = useState<ChartJSOrUndefined<
    'doughnut',
    number[],
    unknown
  > | null>();

  const [childRef, setChildRef] = useState<ChartJSOrUndefined<
    'doughnut',
    number[],
    unknown
  > | null>();

  const chartsData = useMemo(() => getChartDataMap(charts?.proceduresSummaries), [charts]);

  useEffect(() => {
    setChildChart(undefined);
  }, [charts]);

  const handleTitle = useCallback(
    (data: TooltipItem<'doughnut'>[]) => {
      return getTooltipTitle(data[0], charts?.proceduresSummaries);
    },
    [charts],
  );

  const handleLabel = useCallback(
    (data: TooltipItem<'doughnut'>) => {
      return getTooltipLabel(data, charts?.proceduresSummaries);
    },
    [charts],
  );

  const handleChartClick = useCallback(
    (
      e: ChartEvent,
      activeEls: ActiveElement[],
      chart: Chart<
        keyof ChartTypeRegistry,
        (number | [number, number] | Point | BubbleDataPoint | null)[],
        unknown
      >,
    ) => {
      if (!activeEls[0]) return;

      const dataIndex = activeEls[0].index;
      const labels = chart.data.labels;
      const parsed = chart.data.datasets[0].data[dataIndex];

      if (!labels || !isNumber(parsed)) return;

      const curLabel = String(labels[dataIndex]);
      const newChild = getDataChild(parsed, curLabel, charts?.proceduresSummaries);

      const currentClicked = findCurrentData(parsed, curLabel, charts?.proceduresSummaries);
      if (!newChild) return;

      setChildInfo({
        sum: currentClicked?.sum,
        text: currentClicked?.placing,
      });
      setChildChart(newChild);
    },
    [charts],
  );

  const handleLegend = useCallback(
    (
      chart: Chart<
        keyof ChartTypeRegistry,
        (number | [number, number] | Point | BubbleDataPoint | null)[],
        unknown
      >,
    ): LegendItem[] => {
      const datasets = chart.data.datasets;
      let sum = 0;

      let legend: LegendItem[] = [];
      const labels = chart.data.labels;
      const background = datasets[0].backgroundColor;

      if (labels && Array.isArray(background)) {
        legend = datasets[0].data.map((data, i) => {
          sum += Number(data);
          return {
            text: `${labels[i]}`,
            lineWidth: 0,
            fillStyle: background[i] as Color,
          };
        });
      }

      legend.push({
        text: `Всего: ${sum} шт.`,
        lineWidth: 0,
        fillStyle: 'transparent',
      });

      return legend;
    },
    [],
  );

  const getOptions = useMemo((): _DeepPartialObject<
    CoreChartOptions<'doughnut'> &
      ElementChartOptions<'doughnut'> &
      PluginChartOptions<'doughnut'> &
      DatasetChartOptions<'doughnut'> &
      ScaleChartOptions<'doughnut'> &
      DoughnutControllerChartOptions
  > => {
    return {
      cutout: 80,
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: handleTitle,
            label: handleLabel,
          },
        },
      },
      onClick: handleChartClick,
    };
  }, [handleChartClick, handleTitle, handleLabel]);

  const childChartData = useMemo(() => getChildChartDataMap(childChart), [childChart]);

  const handleChildTitle = useCallback(
    (data: TooltipItem<'doughnut'>[]) => {
      return getChildtTooltipTitle(data[0], childChart);
    },
    [childChart],
  );

  const handleChildLabel = useCallback(
    (data: TooltipItem<'doughnut'>) => {
      return getChildTooltipLabel(data, childChart);
    },
    [childChart],
  );

  const getChildOptions = useMemo((): _DeepPartialObject<
    CoreChartOptions<'doughnut'> &
      ElementChartOptions<'doughnut'> &
      PluginChartOptions<'doughnut'> &
      DatasetChartOptions<'doughnut'> &
      ScaleChartOptions<'doughnut'> &
      DoughnutControllerChartOptions
  > => {
    return {
      cutout: 80,
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: handleChildTitle,
            label: handleChildLabel,
          },
        },
      },
    };
  }, [handleChildTitle, handleChildLabel]);

  useEffect(() => {
    if (mainLegend) return;
    const legend = mainRef
      ? handleLegend(
          mainRef as Chart<
            keyof ChartTypeRegistry,
            (number | [number, number] | Point | BubbleDataPoint | null)[],
            unknown
          >,
        )
      : null;
    setMainLegend(legend);
  }, [mainLegend, handleLegend, mainRef]);

  useEffect(() => {
    if (childLegend) return;
    const legend = childRef
      ? handleLegend(
          childRef as Chart<
            keyof ChartTypeRegistry,
            (number | [number, number] | Point | BubbleDataPoint | null)[],
            unknown
          >,
        )
      : null;
    setChildLegend(legend);
  }, [childLegend, handleLegend, childRef, childChart]);

  useEffect(() => {
    setChildLegend(null);
  }, [childChart]);

  useEffect(() => {
    setMainLegend(null);
  }, [charts]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        {chartsData && (
          <div className={styles.chart}>
            <div className={styles.doughnut}>
              <Doughnut
                ref={setMainRef}
                data={chartsData}
                options={getOptions}
              />
            </div>
            <ul className={styles.list}>
              {mainLegend &&
                mainLegend.map(({ text, fillStyle }, id) => {
                  return (
                    <li
                      key={id}
                      className={styles.listItem}
                    >
                      <span
                        className={styles.point}
                        style={{ backgroundColor: fillStyle?.toString() }}
                      />
                      {text}
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
        <SumDisplay
          text="Опубликованных процедур:"
          sum={charts?.sum}
        />
      </div>
      {childChartData && (
        <div className={styles.main}>
          <div className={styles.chart}>
            <div className={styles.doughnut}>
              <Doughnut
                data={childChartData}
                ref={setChildRef}
                options={getChildOptions}
              />
            </div>
            <ul className={styles.list}>
              {childLegend &&
                childLegend.map(({ text, fillStyle }, id) => {
                  return (
                    <li
                      key={id}
                      className={styles.listItem}
                    >
                      <span
                        className={styles.point}
                        style={{ backgroundColor: fillStyle?.toString() }}
                      />
                      {text}
                    </li>
                  );
                })}
            </ul>
          </div>
          <SumDisplay
            text={`${childInfo.text}:`}
            sum={childInfo.sum}
          ></SumDisplay>
        </div>
      )}
    </div>
  );
});

ChartsInfo.displayName = 'ChartsInfo';
