'use client';
import { memo, useCallback, useMemo } from 'react';
import { updateNmckRow, useGetNmckById } from '@/api/nmckApi/nmckApi';
import { NmckRow } from '@/api/nmckApi/nmckApiTypes';

import styles from './NmckUnitMeasureSelect.module.scss';
import { OptionWithNumberValue } from '@/types/types';
import { UnitMeasureSelect } from '@/components/UnitMeasureSelect/UnitMeasureSelect';

const dropdownStyle = {
  width: styles.unitMeasureDropdownWidth,
};

type UnitMeasureSelectProps = {
  record: NmckRow;
  nmckId: string;
};

export const NmckUnitMeasureSelect = memo(({ record, nmckId }: UnitMeasureSelectProps) => {
  const { unit, unitCode } = record;
  const { mutate } = useGetNmckById(nmckId);

  const value = useMemo<OptionWithNumberValue | undefined>(
    () => (unit && unitCode ? { value: unitCode, label: unit } : undefined),
    [unit, unitCode],
  );

  const selectUnitMeasure = useCallback(
    async (option?: OptionWithNumberValue) => {
      await updateNmckRow(nmckId, {
        ...record,
        unit: option?.label ?? '',
        unitCode: option?.value,
      });
      await mutate();
    },
    [mutate, nmckId, record],
  );

  return (
    <div className={styles.units}>
      <UnitMeasureSelect
        rowId={record.id}
        fetchOptionsAfterFocus
        allowClear
        value={value}
        dropdownStyle={dropdownStyle}
        onChange={selectUnitMeasure}
      />
    </div>
  );
});
NmckUnitMeasureSelect.displayName = 'NmckUnitMeasureSelect';
