import { CharacteristicForFilter } from '@/api/okpdApi/okpdApiTypes';
import { Option } from '@/types/types';

export const getItemsMapByCharsListResponse = (
  list: CharacteristicForFilter[],
): Record<string, Option[]> =>
  list.reduce(
    (acc, { code, values }) => ({
      ...acc,
      [code]: values.map(({ id, value }) => ({ value: String(id), label: value })),
    }),
    {},
  );
