import { memo, useCallback, useEffect, useState } from 'react';

import { calculateReport } from '@/api/spmReportApi/smpRepotApi';
import { SmpReport, SmpReportEdited } from '@/api/spmReportApi/smpReportApiTypes';
import { SmpReportRow } from '@/widgets/SmpReport/components';
import { parsePrice } from '@/utils/formatPrice';

import styles from './SmpReportInfo.module.scss';

type SmpReportInfoProps = {
  year: string;
  excludeMed: boolean;
};

const p1Tooltip = (
  <div className={styles.tooltip}>
    <p>
      <b>КАК ПОЛУЧИЛАСЬ ЭТА СУММА?</b>
    </p>
    <p>
      Вставить фильтр:
      <br /> <b>Год финансирования</b> = год отчета <br /> <b>СГОЗ </b>= итого по столбцу Фактически
      оплачено в фин. году + сумма прямых договоров
    </p>
  </div>
);

const p2Tooltip = 'стр.2 = стр.2.1 + стр.2.2 + стр.2.3 + стр.2.4 + стр.2.5 + стр.2.6';
const handTypeTooltip = 'вводятся вручную';

const p23aTooltip =
  'Сумма фактических оплат по прямым договорам в отчетном году (автоматически посчитана сумма цен прямых договоров, заключенных в отчетном году)';

const p23bTooltip = (
  <div className={styles.tooltip}>
    <p>
      <b>КАК ПОЛУЧИЛАСЬ ЭТА СУММА?</b>
    </p>
    <p>
      Вставить фильтры:
      <br /> <b>Год финансирования</b> = год отчета <br /> <b>Закупка у СМП </b>
      = Нет <br /> <b>Результат проведения </b>= С ед. поставщиком или Не указано <br />{' '}
      <b>Сумма </b> = итого по столбцу Фактически оплачено в фин. году
    </p>
  </div>
);

const p26Tooltip =
  'Только для организаций из числа ФОИВ или органов исп.власти субъектов РФ, подведомственных им ГАУ, ГБУ или ГУП, а также муниципальных мед.организаций';

const p4Tooltip = 'стр.4 = стр.3 * 15%';

const p5Tooltip = (
  <div className={styles.tooltip}>
    <p>
      <b>КАК ПОЛУЧИЛАСЬ ЭТА СУММА?</b>
    </p>
    <p>
      Вставить фильтры:
      <br /> <b>Год финансирования</b> = год отчета <br /> <b>Закупка у СМП </b>
      = Да <br /> <b>Результат проведения </b>= С ед. поставщиком или С победителем <br />{' '}
      <b>Сумма </b> = итого по столбцу Фактически оплачено в фин. году
    </p>
  </div>
);

const p6Tooltip = 'Учитывается % работ по субподряду от суммы фактических оплат в отчетном году';

const p7Tooltip = 'стр.7 = стр.5 + стр.6';

const p8Tooltip = 'стр.8 = 100% * стр.7 / стр.3';

const p9Tooltip = (
  <div className={styles.tooltip}>
    <p>
      <b>КАК ПОЛУЧИЛАСЬ ЭТА СУММА?</b>
    </p>
    <p>
      Вставить фильтры:
      <br /> <b>Закупка у СМП</b> = Да <br /> <b>Дата публикации извещения </b>
      = год отчета <br /> <b>Результат проведения </b>= Не будет заключен <br /> <b>Сумма </b> =
      итого по столбцу Начальная цена
    </p>
  </div>
);

export const SmpReportInfo = memo(({ year, excludeMed }: SmpReportInfoProps) => {
  const [currentReport, setCurrentReport] = useState<SmpReport | undefined>(undefined);

  const [reportEdited, setReportEdited] = useState<SmpReportEdited>({
    p1: null,
    p21: null,
    p22: null,
    p23a: null,
    p23b: null,
    p24: null,
    p25: null,
    p26: null,
    p5: null,
    p6: null,
    p9: null,
  });

  const handleReportEdit = useCallback((field: keyof SmpReportEdited, value: number | null) => {
    setReportEdited((prevReport) => ({
      ...prevReport,
      [field]: value,
    }));
  }, []);

  const handleFieldEdit = useCallback(
    (field: keyof SmpReportEdited) => {
      return (value: string | null) => {
        if (!value) {
          handleReportEdit(field, parsePrice('0'));
          return;
        }
        handleReportEdit(field, parsePrice(value));
      };
    },
    [handleReportEdit],
  );

  const handleFieldReset = useCallback(
    (field: keyof SmpReportEdited) => {
      return () => handleReportEdit(field, null);
    },
    [handleReportEdit],
  );

  const getReport = useCallback(async () => {
    const report = await calculateReport(year, excludeMed, reportEdited);
    setCurrentReport(report);
  }, [excludeMed, reportEdited, year]);

  useEffect(() => {
    handleReportEdit('p26', null);
  }, [excludeMed, handleReportEdit]);

  useEffect(() => {
    getReport();
  }, [getReport]);
  return (
    <div className={styles.wrapper}>
      {currentReport && (
        <div className={styles.main}>
          <SmpReportRow
            level={0}
            onEdit={handleFieldEdit('p1')}
            onReset={handleFieldReset('p1')}
            marker="1."
            label="СГОЗ"
            amount={currentReport.p1}
            tooltip={p1Tooltip}
            link={currentReport.p1Link}
          />
          <div>
            <SmpReportRow
              level={0}
              marker="2."
              label="Оплата контрактов, не подлежащих включению в расчёт СГОЗ при определении объёма закупок у СМП и СОНО, (руб.)"
              amount={currentReport.p2}
              tooltip={p2Tooltip}
              className={styles.upper}
            />
            <SmpReportRow
              level={1}
              onEdit={handleFieldEdit('p21')}
              onReset={handleFieldReset('p21')}
              marker="2.1"
              label="Контракты по обороне страны и безопасности государства"
              amount={currentReport.p21}
              tooltip={handTypeTooltip}
              className={styles.inner}
            />
            <SmpReportRow
              level={1}
              onEdit={handleFieldEdit('p22')}
              onReset={handleFieldReset('p22')}
              marker="2.2"
              label="Контракты по предоставлению кредитов"
              amount={currentReport.p22}
              tooltip={handTypeTooltip}
              className={styles.inner}
            />
            <SmpReportRow
              level={1}
              marker="2.3"
              label="Контракты с единственным поставщиком:"
              amount={currentReport.p23}
              className={styles.inner}
            />
            <SmpReportRow
              level={2}
              onEdit={handleFieldEdit('p23a')}
              onReset={handleFieldReset('p23a')}
              marker="а)"
              label="пп 4, 5 (за исключением ч.12), 23, 42, 44, 46 ч.1 ст.93"
              amount={currentReport.p23a}
              tooltip={p23aTooltip}
              className={styles.innerChild}
            />
            <SmpReportRow
              level={2}
              onEdit={handleFieldEdit('p23b')}
              onReset={handleFieldReset('p23b')}
              marker="б)"
              label="остальные пункты ч.1 ст. 93 (за исключением несостоявшихся закупок, размещаемых у СМП)"
              amount={currentReport.p23b}
              link={currentReport.p23bLink}
              tooltip={p23bTooltip}
              className={styles.innerChild}
            />
            <SmpReportRow
              level={1}
              onEdit={handleFieldEdit('p24')}
              onReset={handleFieldReset('p24')}
              marker="2.4"
              label="Контракты по использованию атомной энергетики"
              amount={currentReport.p24}
              tooltip={handTypeTooltip}
              className={styles.inner}
            />
            <SmpReportRow
              level={1}
              onEdit={handleFieldEdit('p25')}
              onReset={handleFieldReset('p25')}
              marker="2.5"
              label="Оплата по контрактам по результатам закрытых способов"
              amount={currentReport.p25}
              tooltip={handTypeTooltip}
              className={!currentReport.p26 ? styles.lower : styles.inner}
            />
            {currentReport.p26 && (
              <SmpReportRow
                level={1}
                onEdit={handleFieldEdit('p26')}
                onReset={handleFieldReset('p26')}
                marker="2.6"
                label="Контракты на поставку лекарственных препаратов для медицинского применения и медизделий"
                amount={currentReport.p26}
                className={styles.lower}
                tooltip={p26Tooltip}
              />
            )}
          </div>
          <SmpReportRow
            level={0}
            marker="3."
            label="СГОЗ, за вычетом закупок, которые не включаются в СГОЗ для СМП"
            amount={currentReport.p3}
          />
          <SmpReportRow
            level={0}
            marker="4."
            label="Объем закупок, который заказчик обязан осуществить у СМП и СОНО"
            amount={currentReport.p4}
            tooltip={p4Tooltip}
          />
          <SmpReportRow
            onEdit={handleFieldEdit('p5')}
            onReset={handleFieldReset('p5')}
            level={0}
            marker="5."
            label="Оплаты по контрактам с СМП и СОНО"
            amount={currentReport.p5}
            tooltip={p5Tooltip}
            link={currentReport.p5Link}
          />
          <SmpReportRow
            onEdit={handleFieldEdit('p6')}
            onReset={handleFieldReset('p6')}
            level={0}
            marker="6."
            label="Объем привлечения субподрядчиков СМП и СОНО"
            amount={currentReport.p6}
            tooltip={p6Tooltip}
          />
          <SmpReportRow
            level={0}
            marker="7."
            label="Объем закупок, который заказчик осуществил у СМП и СОНО"
            amount={currentReport.p7}
            tooltip={p7Tooltip}
          />
          <SmpReportRow
            level={0}
            marker="8."
            label="Доля закупок, осуществленных у СМП"
            amount={currentReport.p8}
            tooltip={p8Tooltip}
            color={currentReport.p8color}
          />
          <SmpReportRow
            onEdit={handleFieldEdit('p9')}
            onReset={handleFieldReset('p9')}
            level={0}
            marker="9."
            label="Сумма НМЦК несостоявшихся определений поставщиков с участием СМП"
            amount={currentReport.p9}
            link={currentReport.p9Link}
            tooltip={p9Tooltip}
          />
        </div>
      )}
    </div>
  );
});

SmpReportInfo.displayName = 'SmpReportInfo';
