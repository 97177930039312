import React, { createContext, useReducer } from 'react';
import { reestrDirectReducer, initialState, ReestrDirectParams } from './reducer';
import { Actions } from './actions';

const ReestrDirectContext = createContext<{
  state: ReestrDirectParams;
  dispatch: React.Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const ReestrDirectProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reestrDirectReducer, initialState);

  return (
    <ReestrDirectContext.Provider value={{ state, dispatch }}>
      {children}
    </ReestrDirectContext.Provider>
  );
};

export { ReestrDirectContext, ReestrDirectProvider };
