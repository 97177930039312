import React, { CSSProperties, memo } from 'react';
import { Flex, Tooltip } from 'antd';

import QuestionRoundIcon from '/public/icons/questionRound.svg';

import styles from './TooltipLabel.module.scss';

type TooltipLabelProps = {
  label: string;
  tooltip: string;
};

const tooltipStyle: CSSProperties = {
  textAlign: 'center',
  width: 300,
};

export const TooltipLabel = memo(({ label, tooltip }: TooltipLabelProps) => {
  return (
    <Flex
      className={styles.wrapper}
      gap={8}
      align="center"
    >
      {label}
      <Tooltip
        title={tooltip}
        overlayInnerStyle={tooltipStyle}
      >
        <QuestionRoundIcon className={styles.question} />
      </Tooltip>
    </Flex>
  );
});

TooltipLabel.displayName = 'TooltipLabel';
