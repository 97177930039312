import useSWR, { SWRConfiguration } from 'swr';
import { PlanningAllParams } from '@/widgets/PlanningAll/context/reducer';
import { NewPurchaseBody, PlanningPurchasesResponse } from './planningAllApiTypes';
import { Key } from 'react';
import { deleteData, postData, getData, putData } from '@/api/commonApi/commonApi';
import { PurchaseByIdResponse } from '@/api/planningCurrentApi/planningCurrentApiTypes';
import { pick } from 'lodash';
import { setURLParamsByBDUIFilters } from '@/utils/setURLParamsByBDUIFilters';

export const useGetPlanningPurchases = (state: PlanningAllParams) => {
  const params = new URLSearchParams();

  params.append('page', String(state.currentPage));
  params.append('count', String(state.itemsPerPage));
  setURLParamsByBDUIFilters(params, state.filters);

  return useSWR(`/purchases?${params}`, getData<PlanningPurchasesResponse>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  } as SWRConfiguration);
};

export const deletePlanningPurchases = (body: Key[]) => {
  return deleteData('/purchases', body);
};

export const createNewPlanningPurchase = (body: NewPurchaseBody) => {
  return postData<NewPurchaseBody>('/purchases', body) as Promise<PurchaseByIdResponse>;
};

export const updatePurchase = ({ id, ...body }: PurchaseByIdResponse) => {
  return putData<PurchaseByIdResponse, NewPurchaseBody>(
    `/purchase/${id}`,
    pick(body, [
      'number',
      'status',
      'supplyDate',
      'amount',
      'financeSource',
      'divisionName',
      'responsible',
      'name',
      'comment',
      'totalSum', // TODO: выпилить после бэковой задачи SARK-415
    ]),
  );
};
