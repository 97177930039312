import { memo, useCallback, useEffect, useState } from 'react';
import { Button, Flex, Form, FormItemProps, Input, Tooltip } from 'antd';
import MailIcon from '/public/icons/mail.svg';
import styles from './OkpdKtruRequestKP.module.scss';
import {
  getUserEmailFromStorage,
  openMailTo,
  saveUserEmailToStorage,
} from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/utils';
import { KtruDetail, OkpdDetail } from '@/api/okpdApi/okpdApiTypes';
import { useUserInfo } from '@/api/userApi/userApi';
import { eventsGTM, sendEventGTM } from '@/metrika';

type Props = {
  receivers: string[];
  okpdKtruDetailData: OkpdDetail | KtruDetail;
};

const emailRules: FormItemProps['rules'] = [
  {
    required: true,
    message: '',
  },
  {
    type: 'email',
    message: 'Некорректный формат email',
  },
];

const formInitialValues = { email: getUserEmailFromStorage() };

export const OkpdKtruRequestKP = memo(({ receivers, okpdKtruDetailData }: Props) => {
  const { data: userData } = useUserInfo();
  const [form] = Form.useForm();
  const [isEmailIncorrect, setIsEmailIncorrect] = useState(true);
  const isButtonDisabled = isEmailIncorrect || !receivers.length;
  const values = Form.useWatch([], form);

  const handleButtonClick = useCallback(() => {
    const email = form.getFieldValue('email');

    sendEventGTM(eventsGTM.requestKP, { value: email });
    saveUserEmailToStorage(email);
    openMailTo(email, receivers, {
      name: okpdKtruDetailData.name,
      code: okpdKtruDetailData.code,
      inn: userData ? String(userData.inn) : '—',
      kpp: userData ? String(userData.kpp) : '—',
      count: 1,
      unit: okpdKtruDetailData.unit ?? '—',
    });
  }, [form, userData, okpdKtruDetailData, receivers]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsEmailIncorrect(false))
      .catch(() => setIsEmailIncorrect(true));
  }, [form, values, receivers]);

  return (
    <Form
      form={form}
      initialValues={formInitialValues}
    >
      <Flex
        gap={30}
        align="center"
      >
        <Form.Item
          name="email"
          rules={emailRules}
          className={styles.formItem}
        >
          <Input
            className={styles.input}
            placeholder="Адрес электронной почты"
          />
        </Form.Item>
        <Tooltip
          placement="top"
          title={isButtonDisabled && 'Выберите поставщиков и заполните почту'}
        >
          <Button
            className={styles.mailButton}
            icon={<MailIcon />}
            size="large"
            disabled={isButtonDisabled}
            onClick={handleButtonClick}
          >
            Запросить КП
          </Button>
        </Tooltip>
      </Flex>
    </Form>
  );
});

OkpdKtruRequestKP.displayName = 'OkpdKtruRequestKP';
