import { isClientRender } from '@/utils/isClientRender';
import { isFunction } from 'lodash';
import { isLogMetricsEnabled } from '@/metrika/isLogMetricsEnabled';

const METRIKA_ID = process.env.NEXT_PUBLIC_YANDEX_METRIKA_ID;

export const sendHitYM = () => {
  if (isClientRender() && isFunction(window.ym) && METRIKA_ID && isLogMetricsEnabled()) {
    window.ym(Number(METRIKA_ID), 'hit', window.location.href);
  }
};
