import { Dispatch, memo, SetStateAction, useCallback } from 'react';
import type { TabsProps } from 'antd';
import { Flex, Tabs, Tag } from 'antd';
import { formatNumber } from '@/utils/formatNumber';
import styles from './PlanningCurrentHeader.module.scss';
import { useParams } from 'next/navigation';
import { useGetCurrentPurchaseById } from '@/api/planningCurrentApi/planningCurrentApi';
import { dateFormat } from '@/constants';
import { formatDate } from '@/utils';
import { PlanningCurrentTabsType } from '@/widgets/PlanningCurrent/types';

type Props = {
  activeTab: PlanningCurrentTabsType;
  setActiveTab: Dispatch<SetStateAction<PlanningCurrentTabsType>>;
};

const items: TabsProps['items'] = [
  {
    key: PlanningCurrentTabsType.DESCRIPTION,
    label: 'Описание',
  },
  {
    key: PlanningCurrentTabsType.PURCHASE_OBJECT,
    label: 'Объект закупки',
  },
  {
    key: PlanningCurrentTabsType.COMPATIBILITY,
    label: 'Совместимость',
  },
  {
    key: PlanningCurrentTabsType.SUPPLIERS,
    label: 'Поставщики',
  },
  {
    key: PlanningCurrentTabsType.NMCK,
    label: 'НМЦК',
    disabled: true,
  },
];

export const PlanningCurrentHeader = memo(({ activeTab, setActiveTab }: Props) => {
  const params = useParams();
  const { data: purchase } = useGetCurrentPurchaseById(params.id);

  const onChange = useCallback(
    (key: string) => {
      setActiveTab(key as PlanningCurrentTabsType);
    },
    [setActiveTab],
  );

  return (
    <div className={styles.wrapper}>
      <Flex
        align="center"
        gap={32}
      >
        <div className={styles.title}>Закупка №{purchase?.number}</div>
        <Tag className={styles.tag}>{purchase?.status.toUpperCase()}</Tag>
        <div className={styles.charachteristics}>
          <div>{purchase && formatDate(purchase.created, dateFormat.frontFormat)}</div>
          <div>{purchase?.responsible}</div>
          {purchase?.amount && <div>{formatNumber(purchase.amount)} ₽</div>}
        </div>
        <Tabs
          activeKey={activeTab}
          items={items}
          className={styles.tabs}
          onChange={onChange}
        />
      </Flex>
    </div>
  );
});
PlanningCurrentHeader.displayName = 'PlanningCurrentHeader';
