import { TableProps, TabsProps } from 'antd';
import { NmckCompatibilityRow } from '@/api/nmckApi/nmckApiTypes';
import styles from './CompatibilityTable/CompatibilityTable.module.scss';

export const staticColumns: TableProps<NmckCompatibilityRow>['columns'] = [
  {
    title: 'Закупка, №',
    dataIndex: 'purchaseNumber',
    key: 'purchaseNumber',
    className: styles.purchaseNumber,
    width: 120,
  },
  {
    title: 'Метод',
    dataIndex: 'calculationMethod',
    key: 'calculationMethod',
    className: styles.calculationMethod,
    width: 160,
  },
  {
    title: 'ОКПД2',
    dataIndex: 'code',
    key: 'code',
    className: styles.code,
    width: 140,
  },
  {
    title: 'Наименование',
    dataIndex: 'name',
    key: 'name',
    className: styles.nameRow,
    width: 240,
    render: (_, { name }) => <p className={styles.name}>{name || '—'}</p>,
  },
];

export const staticTabItems: TabsProps['items'] = [
  {
    key: '0',
    label: 'Весь список',
  },
];
