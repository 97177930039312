import { Dispatch, memo, SetStateAction, useMemo } from 'react';
import { Flex, Tabs } from 'antd';
import { NmckCompatibilityRow } from '@/api/nmckApi/nmckApiTypes';
import { staticTabItems } from '@/components/Compatibility/constants';
import { UiSwitch } from '@/components/Ui';
import styles from './CompatibilityFilter.module.scss';
import cx from 'classnames';
import { uniq } from 'lodash';

type Props = {
  activeKey: string;
  setActiveKey: Dispatch<SetStateAction<string>>;
  isNationalProjects: boolean;
  setIsNationalProjects: Dispatch<SetStateAction<boolean>>;
  showEmptyRows: boolean;
  setShowEmptyRows: Dispatch<SetStateAction<boolean>>;
  rows: NmckCompatibilityRow[];
  className?: string;
};

const getUniquePurchases = (rows: NmckCompatibilityRow[]): number[] => {
  return uniq(rows.map((r) => r.purchaseNumber));
};

export const CompatibilityFilter = memo(
  ({
    activeKey,
    setActiveKey,
    rows,
    className,
    isNationalProjects,
    setIsNationalProjects,
    showEmptyRows,
    setShowEmptyRows,
  }: Props) => {
    const tabItems = useMemo(
      () => [
        ...staticTabItems!,
        ...getUniquePurchases(rows).map((item) => {
          return { key: item.toString(), label: `Закупка ${item}` };
        }),
      ],
      [rows],
    );

    return (
      <Flex
        wrap
        className={cx(styles.wrapper, className)}
      >
        <Tabs
          items={tabItems}
          activeKey={activeKey}
          onChange={setActiveKey}
          className={styles.tabs}
        />
        <Flex gap={32}>
          <UiSwitch
            checked={isNationalProjects}
            onChange={setIsNationalProjects}
            wrapperClassName={styles.switch}
          >
            В рамках нац. проекта
          </UiSwitch>
          <UiSwitch
            checked={showEmptyRows}
            onChange={setShowEmptyRows}
            wrapperClassName={styles.switch}
          >
            Пустые столбцы
          </UiSwitch>
        </Flex>
      </Flex>
    );
  },
);

CompatibilityFilter.displayName = 'CompatibilityFilter';
