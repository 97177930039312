'use client';

import { memo, useContext, useMemo, useState } from 'react';
import { Flex, Tabs, TabsProps } from 'antd';
import moment from 'moment';

import { ContractsTable, PlannedTable, ProcessTable, TabTitle } from '@/widgets/Current/components';
import { CurrentContext } from '@/widgets/Current/context/context';
import {
  useGetContractsExecution,
  useGetPurchasePlanItems,
  useGetPurchasesInProcess,
} from '@/api/currentApi/currentApi';

import styles from './CurrentPage.module.scss';

export const CurrentPage = memo(() => {
  const [activeTab, setActiveTab] = useState('1');
  const { state } = useContext(CurrentContext);

  const { data: plannedItems, isLoading: isPurchasePlanLoading } = useGetPurchasePlanItems(
    state.plannedCurrentPage,
    state.plannedItemsPerPage,
  );

  const { data: processItems, isLoading: isPurchasesInProcessLoading } = useGetPurchasesInProcess(
    state.processCurrentPage,
    state.processItemsPerPage,
  );

  const { data: contractItems, isLoading: isContractItemsLoading } = useGetContractsExecution(
    state.contractsCurrentPage,
    state.contractsItemsPerPage,
  );

  const Items = useMemo((): TabsProps['items'] => {
    return [
      {
        key: '1',
        label: (
          <TabTitle
            title={`Запланированые до конца ${moment().year()}`}
            count={plannedItems?.count}
          />
        ),
      },
      {
        key: '2',
        label: (
          <TabTitle
            title="Проводятся"
            count={processItems?.count}
          />
        ),
      },
      {
        key: '3',
        label: (
          <TabTitle
            title="Контракты на исполнении"
            count={contractItems?.count}
          />
        ),
      },
    ];
  }, [contractItems?.count, plannedItems?.count, processItems?.count]);

  return (
    <div className={styles.wrapper}>
      <Flex
        gap={64}
        className={styles.header}
        align="center"
      >
        <div className={styles.title}>Текущие закупки</div>
        <Tabs
          activeKey={activeTab}
          items={Items}
          onChange={setActiveTab}
          className={styles.tab}
        />
      </Flex>
      <div className={styles.main}>
        <>
          {activeTab === '1' && plannedItems && (
            <PlannedTable
              data={plannedItems}
              loading={isPurchasePlanLoading}
            />
          )}
          {activeTab === '2' && processItems && (
            <ProcessTable
              data={processItems}
              loading={isPurchasesInProcessLoading}
            />
          )}
          {activeTab === '3' && contractItems && (
            <ContractsTable
              data={contractItems}
              loading={isContractItemsLoading}
            />
          )}
        </>
      </div>
    </div>
  );
});

CurrentPage.displayName = 'CurrentPage';
