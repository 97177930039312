import { memo, useCallback, useEffect, useState } from 'react';
import { useOkpdKtruList } from '@/hooks';
import { EntityType, KtruResult } from '@/api/okpdApi/okpdApiTypes';
import { Flex, Spin } from 'antd';
import { KtruCard, Pagination } from '@/widgets/OkpdKtpu/components';
import styles from './OkpdKtruDetailTabsKtruList.module.scss';
import { CharacteristicsParam } from '@/types/types';
import { CharacteristicsFilter } from '@/widgets';
import { SetOkpdKtruModalPageParamsType } from '@/components/OkpdFlowModal/types';
import { useGetKtruCharacteristics } from '@/api/okpdApi/okpdApi';
import { eventsGTM, sendEventGTM } from '@/metrika';

type Props = {
  code: string;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const OkpdKtruDetailTabsKtruList = memo(({ code, setModalPageParams }: Props) => {
  const [characteristics, setCharacteristics] = useState<CharacteristicsParam[]>([]);
  const { list, isListLoading, isMoreLoading, handleShowMore, hasMoreResults } =
    useOkpdKtruList<KtruResult>({
      code,
      listType: EntityType.KTRU,
      characteristics,
    });
  const { data = [], isLoading } = useGetKtruCharacteristics({
    okpdCode: code,
  });

  const onCharacteristicsChange = useCallback(
    (newChars: CharacteristicsParam[]) => setCharacteristics(newChars),
    [],
  );

  useEffect(() => {
    sendEventGTM(eventsGTM.ktruTabView, { value: code });
  }, [code]);

  return (
    <Flex
      vertical
      gap={28}
    >
      <CharacteristicsFilter
        list={data}
        characteristicsFilter={characteristics}
        isListLoading={isLoading}
        onCharacteristicsChange={onCharacteristicsChange}
      />

      {isListLoading && (
        <Flex>
          <Spin />
        </Flex>
      )}
      {!isListLoading && (
        <Flex
          vertical
          className={styles.list}
          gap={16}
        >
          {list.map((item) => (
            <KtruCard
              key={item.code}
              ktru={item}
              showNPAList={false}
              showContracts={false}
              setModalPageParams={setModalPageParams}
            />
          ))}
          <Pagination
            isMoreLoading={isMoreLoading}
            hasMoreResults={hasMoreResults}
            handleShowMore={handleShowMore}
          />
        </Flex>
      )}
    </Flex>
  );
});

OkpdKtruDetailTabsKtruList.displayName = 'OkpdKtruDetailTabsKtruList';
