import { Modal } from 'antd';
import { UnauthorizedResponse } from '@/api/commonApi/commonApiTypes';

let isModalOpen = false;

export const openUnauthorizedModal = ({
  caption,
  message,
  button,
  redirectUrl,
}: UnauthorizedResponse) => {
  if (!isModalOpen) {
    isModalOpen = true;

    Modal.info({
      title: caption,
      content: message,
      centered: true,
      okText: button,
      className: 'unauthorized-modal',
      width: 'fit-content',
      onOk: () => {
        window.location.replace(redirectUrl);
      },
    });
  }
};
