import { Button, Checkbox, Flex, Input, InputNumber, Modal, Select, Tooltip } from 'antd';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import cx from 'classnames';

import {
  DirectContract,
  DirectContractCause,
  DirectContractCauses,
} from '@/api/reestrDirectApi/reestrDirectApiTypes';
import { UiDatePicker } from '@/components';
import { dateFormat } from '@/constants';
import {
  createNewDirectContract,
  updateDirectContract,
} from '@/api/reestrDirectApi/reestrDirectApi';
import { OptionWithNumberValue } from '@/types/types';

import Vicon from '/public/icons/v.svg';
import Calendar from '/public/icons/calendar.svg';
import Question from '/public/icons/questionRound.svg';
import styles from './DirectContractEditModal.module.scss';
import { formatNumber } from '@/utils/formatNumber';
import { omit, pick } from 'lodash';
import { formatDate } from '@/utils';
import { postDrugDirectContract, updateDrugDirectContract } from '@/api/nmckDrugsApi/nmckDrugsApi';

type EditModalProps = {
  isOpenEditModal: boolean;
  closeEditModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
  record?: DirectContract;
  refreshContracts: () => void;
  causes: DirectContractCauses | undefined;
  isCausesLoading: boolean;
  nmckDrugId?: number;
  nmckDrugRowId?: number;
};
const currentDate = formatDate(moment(), dateFormat.serverFormat);

export const DirectContractEditModal = memo(
  ({
    isOpenEditModal,
    record,
    closeEditModal,
    refreshContracts,
    causes,
    isCausesLoading,
    nmckDrugId,
    nmckDrugRowId,
  }: EditModalProps) => {
    const initialState: Omit<DirectContract, 'id'> = useMemo(
      () => ({
        cause: record?.cause ?? 0,
        itemName: record?.itemName ?? '',
        isDrug: record?.isDrug ?? false,
        contractNumber: record?.contractNumber ?? '',
        supplierName: record?.supplierName ?? '',
        conclusionDate: record?.conclusionDate ?? currentDate,
        executionDate: record?.executionDate ?? currentDate,
        packagePrice: record?.packagePrice ?? 0,
        nds: record?.nds ?? 0,
        kbk: record?.kbk ?? '',
        comment: record?.comment ?? '',
        dosageForm: record?.dosageForm ?? '',
        primaryPackageQuantity: record?.primaryPackageQuantity ?? 0,
        secondaryPackageQuantity: record?.secondaryPackageQuantity ?? 0,
        countPackaged: record?.countPackaged ?? 0,
        priceForCalculation: record?.priceForCalculation ?? 0,
        wholesaleAddition: record?.wholesaleAddition ?? 0,
      }),
      [record],
    );
    const [modalState, setModalState] = useState(initialState);

    useEffect(() => {
      if (isOpenEditModal) {
        setModalState(initialState);
      }
    }, [initialState, isOpenEditModal]);

    const causesOptions = useMemo(() => {
      if (causes) {
        return causes.map((cause: DirectContractCause) => ({
          value: cause.id,
          label: cause.cause,
        }));
      }
      return [];
    }, [causes]);

    const saveEdit = useCallback(
      async (e: React.MouseEvent<HTMLButtonElement>) => {
        const dataToSend = omit(modalState, ['priceForCalculation']);

        if (nmckDrugId && nmckDrugRowId) {
          if (record) {
            await updateDrugDirectContract(nmckDrugId, nmckDrugRowId, record.id, dataToSend);
          } else {
            await postDrugDirectContract(nmckDrugId, nmckDrugRowId, dataToSend);
          }
        } else {
          if (record) {
            await updateDirectContract(record.id, dataToSend);
          } else {
            await createNewDirectContract(dataToSend);
          }
        }
        closeEditModal(e);
        refreshContracts();
      },
      [closeEditModal, modalState, nmckDrugId, nmckDrugRowId, record, refreshContracts],
    );

    useEffect(() => {
      const newPriceForCalculation =
        (modalState.packagePrice /
          (modalState.primaryPackageQuantity * modalState.secondaryPackageQuantity)) *
        (100 / (100 + modalState.nds)) *
        (100 / (100 + modalState.wholesaleAddition));

      isFinite(newPriceForCalculation) &&
        setModalState((prev) => ({
          ...prev,
          priceForCalculation: newPriceForCalculation,
        }));
    }, [
      modalState.nds,
      modalState.packagePrice,
      modalState.primaryPackageQuantity,
      modalState.secondaryPackageQuantity,
      modalState.wholesaleAddition,
      modalState.priceForCalculation,
    ]);
    const handleInputChange = useCallback((field: keyof typeof modalState, value: string) => {
      setModalState((prev) => ({ ...prev, [field]: value }));
    }, []);

    const handleDateChange = useCallback((field: keyof typeof modalState, value: Moment | null) => {
      setModalState((prev) => ({
        ...prev,
        [field]: value && formatDate(value, dateFormat.serverFormat),
      }));
    }, []);

    const handleCauseChange = useCallback((option: OptionWithNumberValue) => {
      setModalState((prev) => ({ ...prev, cause: option.value }));
    }, []);

    const inputChangeHandlers = useMemo(() => {
      const fieldsWithStringValue = pick(modalState, [
        'comment',
        'itemName',
        'dosageForm',
        'contractNumber',
        'supplierName',
        'kbk',
      ]);
      return Object.keys(fieldsWithStringValue).reduce(
        (acc, key) => {
          acc[key] = (e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(key as keyof typeof modalState, e.target.value);
          return acc;
        },
        {} as { [key: string]: (e: React.ChangeEvent<HTMLInputElement>) => void },
      );
    }, [modalState, handleInputChange]);

    const inputNumberChangeHandlers = useMemo(() => {
      const fieldsWithNumberValue = pick(modalState, [
        'packagePrice',
        'primaryPackageQuantity',
        'secondaryPackageQuantity',
        'countPackaged',
        'wholesaleAddition',
        'nds',
      ]);
      return Object.keys(fieldsWithNumberValue).reduce(
        (acc, key) => {
          acc[key] = (value: number | null) =>
            setModalState((prev) => ({
              ...prev,
              [key]: value,
            }));
          return acc;
        },
        {} as { [key: string]: (value: number | null) => void },
      );
    }, [modalState]);

    const dateChangeHandlers = useMemo(() => {
      return Object.keys(modalState).reduce(
        (acc, key) => {
          acc[key] = (value: Moment | null) =>
            handleDateChange(key as keyof typeof modalState, value);
          return acc;
        },
        {} as { [key: string]: (value: Moment | null) => void },
      );
    }, [modalState, handleDateChange]);

    const selectedCauseOption = useMemo(() => {
      return causesOptions.find((option) => option.value === modalState.cause);
    }, [causesOptions, modalState.cause]);

    const handleCheckboxChange = useCallback(
      (e: CheckboxChangeEvent) => {
        setModalState((prev) => ({
          ...prev,
          isDrug: e.target.checked,
        }));
      },
      [setModalState],
    );

    return (
      <Modal
        title={'Редактирование прямого договора'}
        open={isOpenEditModal}
        onCancel={closeEditModal}
        centered
        wrapClassName={styles.modal}
        width={1536}
        footer={[
          <Button
            className={styles.cancelBtn}
            key="cancel"
            onClick={closeEditModal}
          >
            Отмена
          </Button>,
          <Button
            className={styles.submitBtn}
            key="submit"
            type="primary"
            onClick={saveEdit}
          >
            Сохранить
          </Button>,
        ]}
      >
        <div className={styles.content}>
          <div
            className={cx(styles.top, {
              [styles.topDrug]: modalState.isDrug,
            })}
          >
            <Flex
              align="center"
              gap={32}
            >
              <div className={styles.text}>Основание заключения контракта:</div>
              <Select
                labelInValue
                onChange={handleCauseChange}
                className={styles.select}
                options={causesOptions}
                defaultValue={causesOptions[0]}
                value={selectedCauseOption}
                suffixIcon={
                  isCausesLoading ? <LoadingOutlined spin /> : <Vicon className="v-icon" />
                }
              />
            </Flex>
            <Flex
              align="center"
              gap={32}
            >
              <div className={styles.text}>Предмет контракта:</div>
              <Flex
                align="center"
                gap={20}
              >
                <Input
                  value={modalState.itemName}
                  onChange={inputChangeHandlers['itemName']}
                  className={cx(styles.itemNameInput, {
                    [styles.itemNameInputShort]: modalState.isDrug,
                  })}
                  placeholder="Не указано"
                />
                <Checkbox
                  checked={modalState.isDrug}
                  onChange={handleCheckboxChange}
                  className={styles.isDrug}
                >
                  <div
                    className={cx(styles.isDrugText, {
                      [styles.isDrugTextChecked]: modalState.isDrug,
                    })}
                  >
                    Лекарственные препараты
                  </div>
                </Checkbox>
              </Flex>
            </Flex>
          </div>
          <Flex
            align="center"
            gap={100}
          >
            {modalState.isDrug && (
              <Flex
                align="center"
                gap={32}
              >
                <div className={styles.text}>Лекарственная форма/дозировка:</div>
                <Input
                  value={modalState.dosageForm}
                  onChange={inputChangeHandlers['dosageForm']}
                  className={styles.input}
                  placeholder="Не указано"
                />
              </Flex>
            )}
            <Flex
              align="center"
              gap={32}
            >
              <div
                className={cx(
                  cx(styles.text, {
                    [styles.textShort]: modalState.isDrug,
                  }),
                )}
              >
                Номер:
              </div>
              <Input
                value={modalState.contractNumber}
                onChange={inputChangeHandlers['contractNumber']}
                className={styles.input}
                placeholder="Не указано"
              />
            </Flex>
          </Flex>
          <Flex
            align="center"
            gap={100}
          >
            {modalState.isDrug && (
              <Flex
                align="center"
                gap={32}
              >
                <div className={styles.text}>Цена за упаковку (поставка), ₽*:</div>
                <InputNumber
                  value={modalState.packagePrice}
                  onChange={inputNumberChangeHandlers['packagePrice']}
                  className={styles.input}
                  placeholder="Не указано"
                />
              </Flex>
            )}
            <Flex
              align="center"
              gap={32}
            >
              <div
                className={cx(
                  cx(styles.text, {
                    [styles.textShort]: modalState.isDrug,
                  }),
                )}
              >
                Поставщик:
              </div>
              <Input
                value={modalState.supplierName}
                onChange={inputChangeHandlers['supplierName']}
                className={styles.input}
                placeholder="Не указано"
              />
            </Flex>
          </Flex>
          <Flex
            align="center"
            gap={100}
          >
            {modalState.isDrug && (
              <Flex
                align="center"
                gap={32}
              >
                <div className={styles.text}>Лек. форм в первичной упаковке*:</div>
                <InputNumber
                  value={modalState.primaryPackageQuantity}
                  onChange={inputNumberChangeHandlers['primaryPackageQuantity']}
                  className={styles.input}
                  placeholder="Не указано"
                />
              </Flex>
            )}
            <Flex
              align="center"
              gap={32}
            >
              <div
                className={cx(
                  cx(styles.text, {
                    [styles.textShort]: modalState.isDrug,
                  }),
                )}
              >
                Дата заключения:
              </div>
              <UiDatePicker
                value={moment(modalState.conclusionDate)}
                onChange={dateChangeHandlers['conclusionDate']}
                className={styles.datePicker}
                suffixIcon={<Calendar />}
                allowClear={false}
              />
            </Flex>
          </Flex>
          <Flex
            align="center"
            gap={100}
          >
            {modalState.isDrug && (
              <Flex
                align="center"
                gap={32}
              >
                <div className={styles.text}>Перв-х упак. в потреб. упаковке*:</div>
                <InputNumber
                  value={modalState.secondaryPackageQuantity}
                  onChange={inputNumberChangeHandlers['secondaryPackageQuantity']}
                  className={styles.input}
                  placeholder="Не указано"
                />
              </Flex>
            )}
            <Flex
              align="center"
              gap={32}
            >
              <div
                className={cx(
                  cx(styles.text, {
                    [styles.textShort]: modalState.isDrug,
                  }),
                )}
              >
                Срок исполнения:
              </div>

              <UiDatePicker
                value={moment(modalState.executionDate)}
                onChange={dateChangeHandlers['executionDate']}
                className={styles.datePicker}
                suffixIcon={<Calendar />}
                allowClear={false}
              />
            </Flex>
          </Flex>

          {!modalState.isDrug && (
            <Flex
              align="center"
              gap={32}
            >
              <div className={styles.text}>Цена, ₽:</div>
              <InputNumber
                value={modalState.priceForCalculation}
                onChange={inputNumberChangeHandlers['priceForCalculation']}
                className={styles.input}
                placeholder="Не указано"
              />
            </Flex>
          )}
          <Flex
            align="center"
            gap={100}
          >
            {modalState.isDrug && (
              <Flex
                align="center"
                gap={32}
              >
                <div className={styles.text}>Поставлено, количество:</div>
                <InputNumber
                  value={modalState.countPackaged}
                  onChange={inputNumberChangeHandlers['countPackaged']}
                  className={styles.input}
                  placeholder="Не указано"
                />
              </Flex>
            )}
            {modalState.isDrug ? (
              <Flex
                align="center"
                gap={32}
              >
                <div
                  className={cx(
                    cx(styles.text, {
                      [styles.textShort]: modalState.isDrug,
                    }),
                  )}
                >
                  КБК:
                </div>
                <Input
                  value={modalState.kbk}
                  onChange={inputChangeHandlers['kbk']}
                  className={styles.input}
                  placeholder="Не указано"
                />
              </Flex>
            ) : (
              <Flex
                align="center"
                gap={32}
              >
                <div
                  className={cx(
                    cx(styles.text, {
                      [styles.textShort]: modalState.isDrug,
                    }),
                  )}
                >
                  НДС, %:
                </div>
                <InputNumber
                  value={modalState.nds}
                  onChange={inputNumberChangeHandlers['nds']}
                  className={styles.input}
                  placeholder="Не указано"
                />
              </Flex>
            )}
          </Flex>
          <Flex
            align="center"
            gap={100}
          >
            {modalState.isDrug && (
              <Flex
                align="center"
                gap={32}
              >
                <div className={styles.text}>Надбавка, ₽:</div>
                <InputNumber
                  value={modalState.wholesaleAddition}
                  onChange={inputNumberChangeHandlers['wholesaleAddition']}
                  className={styles.input}
                  placeholder="Не указано"
                />
              </Flex>
            )}
            {modalState.isDrug ? (
              <Flex
                align="center"
                gap={32}
              >
                <div
                  className={cx(
                    cx(styles.text, {
                      [styles.textShort]: modalState.isDrug,
                    }),
                  )}
                >
                  НДС, %:
                </div>
                <InputNumber
                  value={modalState.nds}
                  onChange={inputNumberChangeHandlers['nds']}
                  className={styles.input}
                  placeholder="Не указано"
                />
              </Flex>
            ) : (
              <Flex
                align="center"
                gap={32}
              >
                <div
                  className={cx(
                    cx(styles.text, {
                      [styles.textShort]: modalState.isDrug,
                    }),
                  )}
                >
                  КБК:
                </div>
                <Input
                  value={modalState.kbk}
                  onChange={inputChangeHandlers['kbk']}
                  className={styles.input}
                  placeholder="Не указано"
                />
              </Flex>
            )}
          </Flex>
          {modalState.isDrug && (
            <div className={styles.priceForCalculation}>
              <div className={styles.title}>
                Цена в расчёт, ₽:
                <Tooltip
                  title={
                    'Цена для расчета = Цена : (Кол-во потреб. единиц в потреб. упаковке * Кол-во первичных упак. в потреб. упак.)'
                  }
                  placement="bottom"
                >
                  <div className={styles.questionIcon}>
                    <Question />
                  </div>
                </Tooltip>
              </div>
              <div className={styles.priceForCalculationPrice}>
                {formatNumber(modalState.priceForCalculation)}
              </div>
            </div>
          )}
          <Flex
            align="center"
            gap={32}
          >
            <div className={styles.text}>Комментарий:</div>
            <Input
              placeholder="Не указано"
              value={modalState.comment}
              onChange={inputChangeHandlers['comment']}
            />
          </Flex>
        </div>
      </Modal>
    );
  },
);
DirectContractEditModal.displayName = 'DirectContractEditModal';
