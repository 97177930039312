import { Dispatch, memo, SetStateAction, useMemo } from 'react';
import { PlanningCurrentTabsType } from '@/widgets/PlanningCurrent/types';
import styles from '@/widgets/PlanningCurrent/components/PlanningCurrentHeader/PlanningCurrentHeader.module.scss';
import { PurchaseObjectTab } from '@/widgets/PlanningCurrent/components';
import { CompatibilityTab } from '@/widgets/PlanningCurrent/components/CompatibilityTab/CompatibilityTab';
import { DescriptionTab } from '@/widgets/PlanningCurrent/components/DescriptionTab/DescriptionTab';

type Props = {
  activeTab: PlanningCurrentTabsType;
  setActiveTab: Dispatch<SetStateAction<PlanningCurrentTabsType>>;
};

export const PlanningCurrentTabsContent = memo(({ activeTab, setActiveTab }: Props) => {
  const activeTabContent = useMemo(
    () =>
      ({
        [PlanningCurrentTabsType.DESCRIPTION]: <DescriptionTab />,
        [PlanningCurrentTabsType.PURCHASE_OBJECT]: (
          <PurchaseObjectTab setActiveTab={setActiveTab} />
        ),
        [PlanningCurrentTabsType.COMPATIBILITY]: <CompatibilityTab />,
        [PlanningCurrentTabsType.SUPPLIERS]: <div>SUPPLIERS</div>,
        [PlanningCurrentTabsType.NMCK]: <div>NMCK</div>,
      })[activeTab],
    [activeTab, setActiveTab],
  );

  return <div className={styles.contentWrapper}>{activeTabContent}</div>;
});

PlanningCurrentTabsContent.displayName = 'PlanningCurrentTabsContent';
