import { Moment } from 'moment/moment';

export enum DatePeriodType {
  HALF_YEAR = 'HALF_YEAR',
  YEAR = 'YEAR',
}

export type DatePeriodPickerType = {
  updatedDates: { startDate?: Moment; endDate?: Moment };
};
