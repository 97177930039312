import { Columns } from '@/widgets/PlanningCurrent/components/PurchaseObjectTab/types';
import { ColumnsType } from 'antd/lib/table';

export const resultsTableColumns: ColumnsType = [
  {
    title: 'Наименование',
    key: Columns.NAME,
    dataIndex: Columns.NAME,
  },
  {
    title: 'ОКПД2',
    key: Columns.OKPD,
    dataIndex: Columns.OKPD,
  },
  {
    title: 'Характеристики',
    key: Columns.CHARS,
    dataIndex: Columns.CHARS,
  },
  {
    title: 'Количество',
    key: Columns.AMOUNT,
    dataIndex: Columns.AMOUNT,
  },
  {
    title: 'Единица измерения',
    key: Columns.UNIT,
    dataIndex: Columns.UNIT,
  },
];
