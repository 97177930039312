import { Input, InputProps, InputRef } from 'antd';
import { ChangeEvent, forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

export type DebounceInputUpdatedOptions = {
  field: string;
  e?: ChangeEvent<HTMLInputElement>;
};

type DebounceInputProps = {
  field?: string;
  debounceMs?: number;
  handleValueChange: (value: string, options: DebounceInputUpdatedOptions) => void;
} & InputProps;

export const DebounceInput = memo(
  forwardRef<InputRef, DebounceInputProps>(
    (
      { value, field = '', debounceMs = 500, handleValueChange, ...inputProps }: DebounceInputProps,
      ref,
    ) => {
      const [inputValue, setInputValue] = useState(value);

      const debouncedChangeValue = useMemo(
        () =>
          debounce((value: string, e: ChangeEvent<HTMLInputElement>) => {
            handleValueChange(value, { field, e });
          }, debounceMs),
        [debounceMs, handleValueChange, field],
      );

      const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
          setInputValue(e.target.value);
          debouncedChangeValue(e.target.value, e);
        },
        [debouncedChangeValue],
      );

      useEffect(() => {
        setInputValue(value);
      }, [value]);

      return (
        <Input
          {...inputProps}
          ref={ref}
          value={inputValue}
          onChange={handleInputChange}
        />
      );
    },
  ),
);

DebounceInput.displayName = 'DebounceInput';
