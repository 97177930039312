import { BDUIFilterParam } from '@/components/BDUIFilter/types';

export enum ActionType {
  SET_SEARCH = 'SET_SEARCH',
  UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE',
  UPDATE_ITEMS_PER_PAGE = 'UPDATE_ITEMS_PER_PAGE',
  FILTERS_UPDATE = 'FILTERS_UPDATE',
}

type ActionCreator<T extends ActionType, P> = (payload: P) => {
  type: T;
  payload: P;
};

const actionCreator = <P, T extends ActionType>(type: T) =>
  ((payload: P) => ({
    type,
    payload,
  })) as ActionCreator<T, P>;

export const actions = {
  setSearch: actionCreator<string, ActionType.SET_SEARCH>(ActionType.SET_SEARCH),
  currentPageUpdate: actionCreator<number, ActionType.UPDATE_CURRENT_PAGE>(
    ActionType.UPDATE_CURRENT_PAGE,
  ),
  itemsPerPageUpdate: actionCreator<number, ActionType.UPDATE_ITEMS_PER_PAGE>(
    ActionType.UPDATE_ITEMS_PER_PAGE,
  ),
  filtersUpdate: actionCreator<BDUIFilterParam[], ActionType.FILTERS_UPDATE>(
    ActionType.FILTERS_UPDATE,
  ),
};

export type Actions = ReturnType<(typeof actions)[keyof typeof actions]>;
