export type DataForColumnsSettingsType = {
  title: string;
  dataIndex: Columns;
  key: string;
};

export enum Columns {
  NUMBER = 'number',
  STATUS = 'status',
  CREATED = 'created',
  RESPONSIBLE = 'responsible',
  AMOUNT = 'amount',
  NAME = 'name',
}

export const resultsTableColumns: DataForColumnsSettingsType[] = [
  {
    title: '№',
    dataIndex: Columns.NUMBER,
    key: 'number',
  },
  {
    title: 'Cтатус',
    dataIndex: Columns.STATUS,
    key: 'status',
  },
  {
    title: 'Дата создания',
    dataIndex: Columns.CREATED,
    key: 'created',
  },
  {
    title: 'Исполнитель',
    dataIndex: Columns.RESPONSIBLE,
    key: 'responsible',
  },
  {
    title: 'Сумма, ₽',
    dataIndex: Columns.AMOUNT,
    key: 'amount',
  },
  {
    title: 'Наименование',
    dataIndex: Columns.NAME,
    key: 'name',
  },
];
