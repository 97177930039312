import { Dispatch, memo, SetStateAction, useCallback, useMemo } from 'react';
import { CalculationMethod } from '@/api/nmckApi/nmckApiTypes';
import { Button, Dropdown, Flex, MenuProps } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { updateNmck, useGetNmckById } from '@/api/nmckApi/nmckApi';
import styles from './NmckCalculationMethodDropdown.module.scss';

type Props = {
  nmckId: string;
  calculationMethod: CalculationMethod;
  setCalculationMethod: Dispatch<SetStateAction<CalculationMethod>>;
  isMedDevices: boolean;
};

const calculationMethodToTextMap = {
  [CalculationMethod.MIN]: 'Минимальная',
  [CalculationMethod.AVG]: 'Средняя',
  [CalculationMethod.WEIGHED_AVG]: 'Средневзвеш.',
};

export const NmckCalculationMethodDropdown = memo(
  ({ calculationMethod, setCalculationMethod, isMedDevices, nmckId }: Props) => {
    const { data, mutate } = useGetNmckById(nmckId);

    const handleItemMenuClick = useCallback(
      async ({ key }: MenuInfo) => {
        const calculationMethod = key as CalculationMethod;

        setCalculationMethod(calculationMethod);
        if (data) {
          await updateNmck({ ...data, calculationMethod });
          await mutate();
        }
      },
      [data, mutate, setCalculationMethod],
    );

    const items: MenuProps['items'] = useMemo(
      () => [
        {
          label: calculationMethodToTextMap[CalculationMethod.MIN],
          key: CalculationMethod.MIN,
        },
        {
          label: calculationMethodToTextMap[CalculationMethod.AVG],
          key: CalculationMethod.AVG,
        },
        ...(isMedDevices
          ? [
              {
                label: calculationMethodToTextMap[CalculationMethod.WEIGHED_AVG],
                key: CalculationMethod.WEIGHED_AVG,
              },
            ]
          : []),
      ],
      [isMedDevices],
    );

    const menu = useMemo(
      () => ({
        items,
        onClick: handleItemMenuClick,
      }),
      [handleItemMenuClick, items],
    );

    return (
      <Dropdown menu={menu}>
        <Flex justify="end">
          <Button
            type="link"
            size="small"
            className={styles.button}
          >
            {calculationMethodToTextMap[calculationMethod]} цена
          </Button>
        </Flex>
      </Dropdown>
    );
  },
);

NmckCalculationMethodDropdown.displayName = 'NmckCalculationMethodDropdown';
