import { Dispatch, memo, SetStateAction, useCallback, useState } from 'react';
import { KtruDetailTabsCharacteristicsHeader } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/KtruDetailTabsCharacteristicsHeader/KtruDetailTabsCharacteristicsHeader';
import { KtruDetailTabsCharacteristicsList } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/KtruDetailTabsCharacteristicsList/KtruDetailTabsCharacteristicsList';
import { KtruCharacteristic } from '@/api/okpdApi/okpdApiTypes';
import { getMappedCharacteristicsList } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/utils';
import { KtruCharacteristicRow } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/types';

type Props = {
  characteristics: KtruCharacteristic[];
  setActiveTab: Dispatch<SetStateAction<string>>;
  ktruListTabDisabled: boolean;
};

export const KtruDetailTabsCharacteristics = memo(
  ({ characteristics, setActiveTab, ktruListTabDisabled }: Props) => {
    const [list, setList] = useState<KtruCharacteristicRow[]>(
      getMappedCharacteristicsList(characteristics),
    );

    const handleSetActiveTab = useCallback((tab: string) => setActiveTab(tab), [setActiveTab]);

    return (
      <div>
        <KtruDetailTabsCharacteristicsHeader
          list={list}
          setList={setList}
        />
        <KtruDetailTabsCharacteristicsList
          setActiveTab={handleSetActiveTab}
          list={list}
          setList={setList}
          ktruListTabDisabled={ktruListTabDisabled}
        />
      </div>
    );
  },
);

KtruDetailTabsCharacteristics.displayName = 'KtruDetailTabsCharacteristics';
