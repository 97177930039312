import styles from './UiSwitch.module.scss';
import { memo, PropsWithChildren } from 'react';
import { Flex, Switch, SwitchProps } from 'antd';
import cx from 'classnames';

type Props = SwitchProps &
  PropsWithChildren & {
    wrapperClassName?: string;
  };

export const UiSwitch = memo(({ children, wrapperClassName, ...props }: Props) => {
  return (
    <Flex
      gap={8}
      align="center"
      component="label"
      className={cx(wrapperClassName)}
    >
      <Switch {...props} />
      <div
        className={cx(styles.label, props.className, {
          [styles.active]:
            props.checked || props.defaultChecked || props.value || props.defaultValue,
        })}
      >
        <Flex
          gap={8}
          align="center"
        >
          {children}
        </Flex>
      </div>
    </Flex>
  );
});

UiSwitch.displayName = 'UiSwitch';
