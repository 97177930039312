import { isClientRender } from '@/utils/isClientRender';

const STORAGE_LOG_METRICS_ENABLED = 'log-metrics-enabled';

export const isLogMetricsEnabled = (): boolean =>
  isClientRender() && localStorage.getItem(STORAGE_LOG_METRICS_ENABLED) === 'true';

export const saveLogMetricsFlagToStorage = (enabled: boolean) => {
  localStorage.setItem(STORAGE_LOG_METRICS_ENABLED, String(enabled));
};
