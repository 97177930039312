import { Key } from 'react';
import { ColumnType } from 'antd/es/table/interface';
import { OrderedColumnTypeMap } from '@/components/Table/TableColumnsSettings/types';

export const getStringKey = (key: Key | undefined) => key!.toString();

export const getOrderedColumns = <T>(
  columns: ColumnType<T>[],
  orderedColumnsMap: OrderedColumnTypeMap<T>,
) =>
  columns
    .map((column) => ({
      ...column,
      hidden: orderedColumnsMap[getStringKey(column.key)].hidden,
    }))
    .sort(
      (a, b) =>
        orderedColumnsMap[getStringKey(a.key)].order - orderedColumnsMap[getStringKey(b.key)].order,
    );
