import { ColumnType } from 'antd/es/table/interface';
import { useMemo, useState } from 'react';
import { OrderedColumnTypeMap } from '@/components/Table/TableColumnsSettings/types';
import { getOrderedColumns, getStringKey } from '@/components/Table/TableColumnsSettings/utils';

type Props<T> = {
  columns: ColumnType<T>[];
};

export const useSortableTableColumns = <T>({ columns }: Props<T>) => {
  const [orderedColumnsMap, setOrderedColumnsMap] = useState<OrderedColumnTypeMap<T>>(
    columns.reduce(
      (acc, column, currentIndex) => ({
        ...acc,
        [getStringKey(column.key)]: {
          ...column,
          order: currentIndex,
        },
      }),
      {} as OrderedColumnTypeMap<T>,
    ),
  );

  const orderedColumns = useMemo(
    () => getOrderedColumns(columns, orderedColumnsMap),
    [columns, orderedColumnsMap],
  );

  return {
    orderedColumns,
    orderedColumnsMap,
    setOrderedColumnsMap,
  };
};
