import { CustomersResponse } from '@/api/docsApi/docsApiTypes';

export const getSelectDataMap = (data: CustomersResponse | undefined) => {
  if (!data || !Array.isArray(data)) return;
  return data.map((item) => {
    return {
      ...item,
      label: item.name,
      value: item.id,
    };
  });
};
