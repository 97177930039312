import { Dispatch, memo, SetStateAction, useCallback, useMemo, useState } from 'react';
import styles from './KtruDetailTabsCharacteristicsRow.module.scss';
import { KtruCharacteristicRow } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/types';
import { Button, Checkbox, Flex } from 'antd';
import commonStyles from '../KtruDetailTabsCharacteristics.module.scss';
import cx from 'classnames';
import CopyIcon from '/public/icons/copy.svg';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { copyKtruCharacteristics } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabsCharacteristics/utils';
import { KtruCharacteristicSelectionType } from '@/api/okpdApi/okpdApiTypes';
import { eventsGTM, sendEventGTM } from '@/metrika';

type Props = {
  row: KtruCharacteristicRow;
  setList: Dispatch<SetStateAction<KtruCharacteristicRow[]>>;
  setActiveTab: (tab: string) => void;
  ktruListTabDisabled: boolean;
};

const isCheckboxDisabled = (row: KtruCharacteristicRow, valueIndex: number) => {
  if (row.selection === KtruCharacteristicSelectionType.ALWAYS_SELECTED) {
    return true;
  }
  if (row.selection === KtruCharacteristicSelectionType.SINGLE) {
    const selectedIndex = row.values.findIndex(({ checked }) => checked);
    return selectedIndex === -1 ? false : selectedIndex !== valueIndex;
  }

  return false;
};

export const KtruDetailTabsCharacteristicsRow = memo(
  ({ row, setList, setActiveTab, ktruListTabDisabled }: Props) => {
    const isCopyButtonDisabled = useMemo(() => row.values.every(({ checked }) => !checked), [row]);
    const [isExpand, setIsExpand] = useState(false);
    const values = useMemo(
      () => (row.values.length <= 4 || isExpand ? row.values : row.values.slice(0, 4)),
      [isExpand, row.values],
    );

    const handleAllButtonClick = useCallback(() => setIsExpand(true), []);

    const labelToCheckedChangeHandlerMap = useMemo(
      () =>
        values.reduce(
          (acc, { id }) => ({
            ...acc,
            [id]: (e: CheckboxChangeEvent) =>
              setList((prev) =>
                prev.map((item) => {
                  if (item.name === row.name) {
                    sendEventGTM(eventsGTM.checkedCharacteristic, { value: id });
                    return {
                      ...item,
                      values: item.values.map((value) =>
                        value.id === id ? { ...value, checked: e.target.checked } : value,
                      ),
                    };
                  }

                  return item;
                }),
              ),
          }),
          {} as Record<string, (e: CheckboxChangeEvent) => void>,
        ),
      [row.name, setList, values],
    );

    const handleSelectByValueClick = useCallback(() => setActiveTab('3'), [setActiveTab]);

    const copyRow = useCallback(() => {
      sendEventGTM(eventsGTM.copyCharacteristicsRow);
      copyKtruCharacteristics(row, true);
    }, [row]);

    return (
      <div className={styles.row}>
        <div className={cx(styles.columns, commonStyles.columnGrid)}>
          <div className={styles.column}>
            <span className={styles.bold}>{row.name}</span>
          </div>
          <div className={styles.column}>
            <span className={styles.gray}>{row.kind}</span>
          </div>
          <div className={styles.column}>
            <span className={styles.gray}>{row.type}</span>
          </div>
          <div className={styles.column}>
            <Flex
              wrap
              className={styles.values}
            >
              {values.map(({ label, checked, id }, index) => (
                <label
                  key={id}
                  className={styles.checkboxWrapper}
                >
                  <Checkbox
                    checked={checked}
                    onChange={labelToCheckedChangeHandlerMap[id]}
                    disabled={isCheckboxDisabled(row, index)}
                  />
                  <span
                    className={cx(styles.checkboxLabel, {
                      [styles.checked]: checked,
                    })}
                  >
                    {label}
                  </span>
                </label>
              ))}
              {row.values.length !== values.length && (
                <Button
                  type="link"
                  className={styles.button}
                  size="small"
                  onClick={handleAllButtonClick}
                >
                  Все
                </Button>
              )}
            </Flex>
          </div>
        </div>
        <Flex
          align="center"
          gap={30}
        >
          {
            <Button
              type="link"
              className={cx(styles.button, {
                [styles.buttonDisabled]: ktruListTabDisabled,
              })}
              size="small"
              onClick={handleSelectByValueClick}
              disabled={ktruListTabDisabled}
            >
              Подобрать по значению
            </Button>
          }
          <Button
            type="link"
            className={cx(styles.button, styles.copyButton)}
            size="small"
            disabled={isCopyButtonDisabled}
            onClick={copyRow}
          >
            <CopyIcon />
          </Button>
        </Flex>
      </div>
    );
  },
);

KtruDetailTabsCharacteristicsRow.displayName = 'KtruDetailTabsCharacteristicsRow';
