import { Button, Flex } from 'antd';
import cx from 'classnames';
import styles from './Pagination.module.scss';
import { memo, useCallback } from 'react';

type PaginationProps = {
  isMoreLoading: boolean;
  handleShowMore: () => void;
  hasMoreResults: boolean;
};

export const Pagination = memo(
  ({ isMoreLoading, handleShowMore, hasMoreResults }: PaginationProps) => {
    const disabled = isMoreLoading;

    const onShowMoreButtonClick = useCallback(() => handleShowMore(), [handleShowMore]);

    return (
      hasMoreResults && (
        <Flex className={styles.pagination}>
          <Button
            type="link"
            size="small"
            className={cx(styles.button, {
              [styles.buttonDisabled]: disabled,
            })}
            onClick={onShowMoreButtonClick}
            disabled={disabled}
            loading={isMoreLoading}
          >
            Показать еще
          </Button>
        </Flex>
      )
    );
  },
);

Pagination.displayName = 'Pagination';
