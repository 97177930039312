'use client';
import { memo, useCallback, useState } from 'react';

import styles from './PurchaseUnitMeasureSelect.module.scss';
import { PurchaseItem } from '@/api/planningCurrentApi/planningCurrentApiTypes';
import { useParams } from 'next/navigation';
import { omit } from 'lodash';
import { updateCurrentPurchaseItem } from '@/api/planningCurrentApi/planningCurrentApi';
import { OptionWithNumberValue } from '@/types/types';
import { UnitMeasureSelect } from '@/components/UnitMeasureSelect/UnitMeasureSelect';

const dropdownStyle = {
  width: styles.unitMeasureDropdownWidth,
};

type UnitMeasureSelectProps = {
  record: PurchaseItem;
};

export const PurchaseUnitMeasureSelect = memo(({ record }: UnitMeasureSelectProps) => {
  const { unit, unitCode, id } = record;
  const params = useParams();
  const updateBody = omit(record, ['id']);
  const [unitsMeasureValue, setUnitsMeasureValue] = useState<OptionWithNumberValue | undefined>(
    unit && unitCode ? { value: unitCode, label: unit } : undefined,
  );

  const handleValueChange = useCallback(
    async (option?: OptionWithNumberValue) => {
      setUnitsMeasureValue(option);
      await updateCurrentPurchaseItem(params.id, id, {
        ...updateBody,
        unit: option?.label ?? '',
        unitCode: option?.value,
      });
    },
    [id, params.id, updateBody],
  );

  return (
    <UnitMeasureSelect
      className={styles.select}
      value={unitsMeasureValue}
      dropdownStyle={dropdownStyle}
      allowClear
      onChange={handleValueChange}
    />
  );
});
PurchaseUnitMeasureSelect.displayName = 'PurchaseUnitMeasureSelect';
