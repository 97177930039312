import { memo } from 'react';

import { formatPrice } from '@/utils/formatPrice';

import styles from './PenaltiesResult.module.scss';

type PenaltiesProps = {
  penalties?: number;
  amountOfUnfulfilledObligations?: number;
  overdueDaysCount?: number;
};

export const PenaltiesResult = memo(
  ({ penalties, amountOfUnfulfilledObligations, overdueDaysCount }: PenaltiesProps) => {
    return (
      <div className={styles.wrapper}>
        <p className={styles.title}>Результаты расчёта</p>
        <div className={styles.main}>
          <p className={styles.text}>Сумма пеней:</p>
          <span className={styles.amount}>
            {penalties && `${formatPrice(penalties.toString())} ₽`}
          </span>
          <p className={styles.text}>Неисполненных обязательств:</p>
          <span className={styles.amount}>
            {amountOfUnfulfilledObligations &&
              `${formatPrice(amountOfUnfulfilledObligations.toString())}₽`}
          </span>
          <p className={styles.text}>Просроченных дней:</p>
          <span className={styles.amount}>{overdueDaysCount}</span>
        </div>
      </div>
    );
  },
);

PenaltiesResult.displayName = 'PenaltiesResult';
