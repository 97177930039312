import {
  Dispatch,
  Key,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Flex, Modal } from 'antd';
import {
  createCalculationFromTru,
  deleteTruCompatibilityList,
  getTruCompatibilityXlsx,
  updateTruCompatibilityCell,
  useGetTruList,
} from '@/api/okpdApi/okpdApi';
import { useCompatibility } from '@/components/Compatibility/useCompatibility';
import { CompatibilityTable } from '@/components/Compatibility/CompatibilityTable/CompatibilityTable';
import { CompatibilityFilter } from '@/components/Compatibility/CompatibilityFilter/CompatibilityFilter';
import { NmckCompatibilityByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import styles from './OkpdKtruDetailCompatibilityModal.module.scss';
import ExportIcon from 'public/icons/export.svg';
import { setSplittedNmckIds } from '@/utils';
import { useRouter } from 'next/navigation';
import { pageRoutes } from '@/app/pageRoutes';
import { KeyedMutator } from 'swr';
import { TruCountResponse } from '@/api/okpdApi/okpdApiTypes';
import { CompatibilityWarning } from '@/components/Compatibility/CompatibilityWarning/CompatibilityWarning';

type Props = {
  modalKey?: Key;
  refreshCount: KeyedMutator<TruCountResponse>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const OkpdKtruDetailCompatibilityModal = memo(
  ({ isOpen, setIsOpen, modalKey, refreshCount }: Props) => {
    const router = useRouter();
    const [compatibility, setCompatibility] = useState<NmckCompatibilityByIdResponse>();

    const {
      rows,
      rawRows,
      header,
      activeTab,
      showEmptyRows,
      isNationalProjects,
      setActiveTab,
      setShowEmptyRows,
      setIsNationalProjects,
    } = useCompatibility({ data: compatibility });
    const { data, isLoading, mutate } = useGetTruList(!showEmptyRows, isNationalProjects);

    const handleCloseModal = useCallback(() => setIsOpen(false), [setIsOpen]);

    const handleSaveExcelButtonClick = useCallback(() => {
      getTruCompatibilityXlsx(!showEmptyRows, isNationalProjects);
    }, [isNationalProjects, showEmptyRows]);

    const handleClearListButtonClick = useCallback(async () => {
      await deleteTruCompatibilityList();
      await refreshCount();
      handleCloseModal();
    }, [handleCloseModal, refreshCount]);

    const handleCreateCalculationButtonClick = useCallback(async () => {
      const ids = await createCalculationFromTru(isNationalProjects);
      await mutate();
      await refreshCount();
      setSplittedNmckIds(ids);
      router.push(pageRoutes.nmckHistory);
    }, [isNationalProjects, mutate, refreshCount, router]);

    const modalTitle = useMemo(
      () => (
        <Flex
          align="center"
          gap={64}
          className={styles.modalHeader}
        >
          <h2 className={styles.title}>Список позиций ОКПД2/КТРУ</h2>
          <Button
            icon={<ExportIcon />}
            type="link"
            className="button-with-icon"
            onClick={handleSaveExcelButtonClick}
          >
            Сохранить в Excel
          </Button>
          <Button
            type="primary"
            onClick={handleCreateCalculationButtonClick}
          >
            Создать расчет
          </Button>
          <Button onClick={handleClearListButtonClick}>Очистить список</Button>
        </Flex>
      ),
      [handleClearListButtonClick, handleCreateCalculationButtonClick, handleSaveExcelButtonClick],
    );

    useEffect(() => {
      setCompatibility(data);
    }, [data]);

    useEffect(() => {
      if (modalKey) {
        mutate();
      }
    }, [modalKey, mutate]);

    return (
      <Modal
        open={isOpen}
        onCancel={handleCloseModal}
        width="95%"
        className={styles.modal}
        title={modalTitle}
        footer={null}
        centered
      >
        <CompatibilityWarning hasRestrictions={!!data?.hasRestrictions} />
        <CompatibilityFilter
          activeKey={activeTab}
          setActiveKey={setActiveTab}
          isNationalProjects={isNationalProjects}
          setIsNationalProjects={setIsNationalProjects}
          showEmptyRows={showEmptyRows}
          setShowEmptyRows={setShowEmptyRows}
          rows={rawRows}
          className={styles.filterWrapper}
        />
        <CompatibilityTable
          id=""
          rows={rows}
          header={header}
          isLoading={isLoading}
          refreshTableData={mutate}
          updateCell={updateTruCompatibilityCell}
          withVerticalScroll
        />
      </Modal>
    );
  },
);

OkpdKtruDetailCompatibilityModal.displayName = 'OkpdKtruDetailCompatibilityModal';
