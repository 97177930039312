import { CSSProperties, memo } from 'react';
import { Flex, Space, Tooltip } from 'antd';
import cx from 'classnames';
import Link from 'next/link';

import { P8Colors } from '@/api/spmReportApi/smpReportApiTypes';
import { formatPrice } from '@/utils/formatPrice';
import { DebounceInput } from '@/components';

import QuestionRoundIcon from '/public/icons/questionRound.svg';
import New from '/public/icons/new.svg';
import ExportDiagonal from '/public/icons/exportDiagonal.svg';

import styles from './SmpReportRow.module.scss';

const tooltipStyle: CSSProperties = {
  textAlign: 'center',
  width: 290,
};

type TooltipLabelProps = {
  label: string;
  tooltip?: JSX.Element | string;
  amount: number;
  marker: string;
  color?: P8Colors;
  className?: string;
  link?: string;
  onEdit?: (value: string | null) => void;
  onReset?: () => void;
  level: number;
};

export const SmpReportRow = memo(
  ({
    label,
    amount,
    tooltip,
    className,
    marker,
    color,
    link,
    onEdit,
    onReset,
    level,
  }: TooltipLabelProps) => {
    const textMargin: CSSProperties = {
      marginLeft: 32 * level,
    };

    return (
      <div className={cx(styles.wrapper, className)}>
        <div className={styles.main}>
          <Space
            size={20}
            style={textMargin}
          >
            {marker}
            <p>
              {label}
              {tooltip && (
                <span className={styles.tooltip}>
                  <Tooltip
                    title={tooltip}
                    overlayInnerStyle={tooltipStyle}
                  >
                    <QuestionRoundIcon className={styles.question} />
                  </Tooltip>
                </span>
              )}
            </p>
          </Space>
          <Flex gap={50}>
            <div className={styles.amount}>
              {!onEdit && (
                <Flex
                  gap={10}
                  justify="space-between"
                  className={cx(styles.price, {
                    [styles.priceRed]: color === 'RED',
                    [styles.priceGreen]: color === 'GREEN',
                  })}
                  align="center"
                >
                  {formatPrice(amount)}
                  <span className={styles.sign}>{!color ? '₽' : '%'}</span>
                </Flex>
              )}
              {onEdit && (
                <div className={styles.inputContainer}>
                  <DebounceInput
                    debounceMs={600}
                    variant="borderless"
                    value={formatPrice(amount)}
                    handleValueChange={onEdit}
                    suffix={'₽'}
                    className={styles.input}
                  />
                  {onReset && (
                    <New
                      onClick={onReset}
                      className={styles.reset}
                    />
                  )}
                </div>
              )}
            </div>
          </Flex>
        </div>
        {link && (
          <Link
            href={link}
            className={styles.link}
            target="_blank"
          >
            <ExportDiagonal />
            Контракты
          </Link>
        )}
      </div>
    );
  },
);

SmpReportRow.displayName = 'SmpReportRow';
