import { EisContractItem } from '@/api/reestrAllApi/reestrAllApiTypes';

type DataForColumnsSettingsType = {
  title: string;
  dataIndex: Columns;
  key: string;
};
export type TotalRow = Partial<EisContractItem>;

export enum Columns {
  NAME = 'name',
  NMCK = 'nmck',
  PURCHASE_TYPE = 'purchaseType',
  PUBLISHED = 'published',
  RESULT = 'result',
  SIGNED = 'signed',
  SUPPLIER = 'supplier',
  CONTRACT_PRICE = 'contractPrice',
  SMP = 'smp',
  STATUS = 'status',
  CONTRACT_REESTR_NUMBER = 'contractReestrNumber',
  PLANNED_THIS_YEAR = 'plannedThisYear',
  CONTRACT_NUMBER = 'contractNumber',
  NOTIFICATION_NUMBER = 'notificationNumber',
  PAID = 'paid',
  SUPPLIED = 'supplied',
  PAID_THIS_YEAR = 'paidThisYear',
  REMAINING_AMOUNT_TO_PAY = 'remainingAmountToPay',
  ECONOMY = 'economy',
  EXECUTION_DATE = 'executionDate',
  IKZ = 'ikz',
  DRUGS = 'drugs',
  MED_DEVICES = 'medDevices',
}

export const resultsTableColumns: DataForColumnsSettingsType[] = [
  {
    title: 'Название',
    dataIndex: Columns.NAME,
    key: Columns.NAME,
  },
  {
    title: 'Начальная цена, ₽',
    dataIndex: Columns.NMCK,
    key: Columns.NMCK,
  },
  {
    title: 'Способ закупки',
    dataIndex: Columns.PURCHASE_TYPE,
    key: Columns.PURCHASE_TYPE,
  },
  {
    title: 'Публикация',
    dataIndex: Columns.PUBLISHED,
    key: Columns.PUBLISHED,
  },
  {
    title: 'Результат проведения',
    dataIndex: Columns.RESULT,
    key: Columns.RESULT,
  },
  {
    title: 'Заключено',
    dataIndex: Columns.SIGNED,
    key: Columns.SIGNED,
  },
  {
    title: 'Поставщик',
    dataIndex: Columns.SUPPLIER,
    key: Columns.SUPPLIER,
  },
  {
    title: 'Цена контракта, ₽',
    dataIndex: Columns.CONTRACT_PRICE,
    key: Columns.CONTRACT_PRICE,
  },
  {
    title: 'Закупка у СМП',
    dataIndex: Columns.SMP,
    key: Columns.SMP,
  },
  {
    title: 'Статус',
    dataIndex: Columns.STATUS,
    key: Columns.STATUS,
  },
  {
    title: 'Реестровый номер',
    dataIndex: Columns.CONTRACT_REESTR_NUMBER,
    key: Columns.CONTRACT_REESTR_NUMBER,
  },
  {
    title: 'Запланировано в фин. году, ₽',
    dataIndex: Columns.PLANNED_THIS_YEAR,
    key: Columns.PLANNED_THIS_YEAR,
  },
  {
    title: 'Номер контракта',
    dataIndex: Columns.CONTRACT_NUMBER,
    key: Columns.CONTRACT_NUMBER,
  },
  {
    title: 'Номер извещения',
    dataIndex: Columns.NOTIFICATION_NUMBER,
    key: Columns.NOTIFICATION_NUMBER,
  },
  {
    title: 'Фактически оплачено всего, ₽',
    dataIndex: Columns.PAID,
    key: Columns.PAID,
  },
  {
    title: 'Фактически поставлено всего, ₽',
    dataIndex: Columns.SUPPLIED,
    key: Columns.SUPPLIED,
  },
  {
    title: 'Фактически оплачено в фин. году, ₽',
    dataIndex: Columns.PAID_THIS_YEAR,
    key: Columns.PAID_THIS_YEAR,
  },
  {
    title: 'Осталось оплатить, ₽',
    dataIndex: Columns.REMAINING_AMOUNT_TO_PAY,
    key: Columns.REMAINING_AMOUNT_TO_PAY,
  },
  {
    title: 'Экономия, ₽',
    dataIndex: Columns.ECONOMY,
    key: Columns.ECONOMY,
  },
  {
    title: 'Срок исполнения',
    dataIndex: Columns.EXECUTION_DATE,
    key: Columns.EXECUTION_DATE,
  },
  {
    title: 'ИКЗ',
    dataIndex: Columns.IKZ,
    key: Columns.IKZ,
  },
  {
    title: 'Лекарственные препараты',
    dataIndex: Columns.DRUGS,
    key: Columns.DRUGS,
  },
  {
    title: 'Мед. изделия',
    dataIndex: Columns.MED_DEVICES,
    key: Columns.MED_DEVICES,
  },
];
