import { useCallback, useEffect, useMemo, useState } from 'react';
import { CalculationMethod, NmckRow, VariationColor } from '@/api/nmckApi/nmckApiTypes';
import styles from './useNmckGrid.module.scss';
import { deleteNmckProposition, postNewProposition, useGetNmckById } from '@/api/nmckApi/nmckApi';
import { Button, Flex, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { NameInput } from '@/components/NmckGrid/NameInput/NameInput';
import { Okpd2Input } from '@/components/NmckGrid/Okpd2Input/Okpd2Input';
import { EisSearch } from '@/components/NmckGrid/EisSearch/EisSearch';
import Cross from '/public/icons/cross.svg';
import Plus from '/public/icons/plus.svg';
import { InputWithSupplier } from '@/components';
import { NmckUnitMeasureSelect } from '@/components/NmckGrid/NmckUnitMeasureSelect/NmckUnitMeasureSelect';
import { AmountInput } from '@/components/NmckGrid/AmountInput/AmountInput';
import { More } from '@/components/NmckGrid/More/More';
import { ColumnProps } from 'antd/es/table';
import { formatPrice } from '@/utils/formatPrice';
import { NkmiAutocomplete } from '@/components/NmckGrid/NkmiAutocomplete/NkmiAutocomplete';
import { NmckGridContracts } from '@/components/NmckGrid/NmckGridContracts/NmckGridContracts';
import { formatNumber } from '@/utils/formatNumber';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { NmckCalculationMethodDropdown } from '@/components/NmckGrid/NmckCalculationMethodDropdown/NmckCalculationMethodDropdown';

type Props = {
  nmckId: string;
  isMedDevices?: boolean;
};

export const useNmckGrid = ({ nmckId, isMedDevices = false }: Props) => {
  const [calculationMethod, setCalculationMethod] = useState<CalculationMethod>(() =>
    isMedDevices ? CalculationMethod.WEIGHED_AVG : CalculationMethod.AVG,
  );
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const { data, mutate: refreshCalculation, isLoading } = useGetNmckById(nmckId);

  const list: NmckRow[] = useMemo(() => data?.rows ?? [], [data]);

  const rowClassName: TableProps['rowClassName'] = useCallback(
    (_: unknown, index: number) => {
      return expandedRowKeys.includes(index + 1) ? styles.rowExpanded : '';
    },
    [expandedRowKeys],
  );

  const handleAddProposition = useCallback(async () => {
    await postNewProposition(nmckId);
    await refreshCalculation();
  }, [refreshCalculation, nmckId]);

  const handleDeleteProposition = useCallback(
    async (columnNumber: number) => {
      await deleteNmckProposition(nmckId, columnNumber);
      await refreshCalculation();
    },
    [refreshCalculation, nmckId],
  );

  const handleExpandRow = useCallback(
    (record: NmckRow) => {
      const newExpandedRowKeys = [...expandedRowKeys];
      const index = newExpandedRowKeys.indexOf(record.id);
      if (index > -1) {
        newExpandedRowKeys.splice(index, 1);
      } else {
        newExpandedRowKeys.push(record.id);
      }
      setExpandedRowKeys(newExpandedRowKeys);
    },
    [expandedRowKeys],
  );

  const handleEisSearchClick = useCallback(
    (record: NmckRow) => {
      sendEventGTM(eventsGTM.nmckEisOpen);
      handleExpandRow(record);
    },
    [handleExpandRow],
  );

  const expandable = useMemo(
    () => ({
      expandedRowKeys,
      expandedRowRender: (record: NmckRow) => <NmckGridContracts record={record} />,
      expandIconColumnIndex: -1,
    }),
    [expandedRowKeys],
  );

  const columns: ColumnsType<NmckRow> = useMemo(
    () => [
      {
        title: '№',
        dataIndex: 'count',
        key: 'count',
        align: 'center',
        width: 60,
        render: (_: unknown, record: NmckRow, index: number) => {
          const formattedIndex = String(index + 1).padStart(2, '0');
          return <p className={styles.countIndex}>{formattedIndex}</p>;
        },
      },
      {
        title: 'Наименование',
        dataIndex: 'name',
        key: 'name',
        width: 230,
        render: (_: unknown, record: NmckRow) => {
          return (
            <NameInput
              record={record}
              id={nmckId}
            />
          );
        },
      },
      {
        title: 'ОКПД 2 / КТРУ',
        dataIndex: 'okpd2',
        key: 'okpd2',
        width: 210,
        render: (_: unknown, record: NmckRow) => {
          return (
            <Okpd2Input
              row={record}
              id={nmckId}
            />
          );
        },
      },
      {
        title: '',
        dataIndex: '',
        key: 'expand',
        width: 70,
        render: (_: unknown, record: NmckRow) => {
          return <EisSearch onClick={() => handleEisSearchClick(record)} />;
        },
      },
      ...(isMedDevices
        ? [
            {
              title: 'НКМИ',
              dataIndex: 'nkmiId',
              key: 'nkmi',
              width: 90,
              render: (_: unknown, record: NmckRow) => (
                <NkmiAutocomplete
                  id={nmckId}
                  row={record}
                />
              ),
            },
          ]
        : []),
      ...(list[0]?.propositions?.map(
        (proposition, i, propositions): ColumnProps<NmckRow> => ({
          key: `price-${proposition.columnNumber}`,
          title: (
            <Flex
              align="center"
              gap={6}
              key={proposition.columnNumber}
            >
              Цена {proposition.columnNumber}
              {propositions.length > 1 && (
                <Button
                  className={styles.crossButton}
                  type="link"
                  icon={<Cross />}
                  onClick={() => handleDeleteProposition(proposition.columnNumber)}
                />
              )}
            </Flex>
          ),
          render: (_: unknown, record: NmckRow) => {
            return (
              <InputWithSupplier
                proposition={proposition}
                record={record}
                key={proposition.columnNumber}
                id={nmckId}
              />
            );
          },
          width: 220,
        }),
      ) ?? []),
      {
        title: (
          <Button
            type="link"
            icon={<Plus />}
            className={styles.addBtn}
            onClick={handleAddProposition}
          >
            Цена
          </Button>
        ),
        width: 70,
      },
      {
        title: <div className={styles.variationHead}>Вариация</div>,
        dataIndex: 'variation',
        key: 'variation',
        width: 95,
        align: 'end',
        render: (_: unknown, record: NmckRow) => {
          return (
            <div
              className={
                record.variationColor === VariationColor.GREEN
                  ? styles.variationGreen
                  : styles.variationRed
              }
            >
              {formatNumber(record.variation)}%
            </div>
          );
        },
      },
      {
        title: 'Ед. изм.',
        dataIndex: 'unit',
        key: 'unit',
        width: 90,
        render: (_: unknown, record: NmckRow) => {
          return (
            <NmckUnitMeasureSelect
              record={record}
              nmckId={nmckId}
            />
          );
        },
      },
      {
        title: 'Кол-во',
        dataIndex: 'quantity',
        key: 'quantity',
        width: 75,
        render: (_: unknown, record: NmckRow) => {
          return (
            <AmountInput
              record={record}
              id={nmckId}
            />
          );
        },
      },
      {
        title: (
          <NmckCalculationMethodDropdown
            nmckId={nmckId}
            calculationMethod={calculationMethod}
            setCalculationMethod={setCalculationMethod}
            isMedDevices={isMedDevices}
          />
        ),
        dataIndex: 'averagePrice',
        key: 'averagePrice',
        width: 110,
        render: (_: unknown, record: NmckRow) => {
          return (
            <Flex justify="right">
              <div className={styles.averagePrice}>
                {calculationMethod === CalculationMethod.MIN
                  ? formatNumber(record.minPrice)
                  : formatNumber(record.averagePrice)}
                {'\u200A'}₽
              </div>
            </Flex>
          );
        },
      },
      {
        title: 'НМЦК',
        dataIndex: 'nmck',
        key: 'nmck',
        align: 'end',
        width: 100,
        render: (_: unknown, record: NmckRow) => `${formatPrice(record.nmck)} ₽`,
      },
      {
        title: '',
        dataIndex: 'more',
        key: 'more',
        render: (_: unknown, record: NmckRow) => (
          <More
            record={record}
            id={nmckId}
          />
        ),
      },
    ],
    [
      isMedDevices,
      list,
      handleAddProposition,
      calculationMethod,
      nmckId,
      handleEisSearchClick,
      handleDeleteProposition,
    ],
  );

  useEffect(() => {
    if (data) {
      setCalculationMethod(data.calculationMethod);
    }
  }, [data]);

  return {
    columns,
    list,
    isLoading,
    rowClassName,
    expandable,
  };
};
