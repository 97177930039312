import { isClientRender } from '@/utils/isClientRender';

const REGION_IDS_STORAGE_KEY = 'region-ids';

export const saveRegionsToStorage = (regionIds: number[]) => {
  if (isClientRender()) {
    localStorage.setItem(REGION_IDS_STORAGE_KEY, JSON.stringify(regionIds));
  }
};

export const getRegionsFromStorage = (): number[] => {
  if (isClientRender()) {
    const storedRegions = localStorage.getItem(REGION_IDS_STORAGE_KEY);
    return storedRegions ? JSON.parse(storedRegions) : [];
  }

  return [];
};
