import { memo, useCallback, useContext } from 'react';
import Link from 'next/link';
import { ConfigProvider, Pagination, Select, Table, TableProps, Tooltip } from 'antd';

import { PurchaseInProcess, PurchasesInProcessResponse } from '@/api/currentApi/currentApiTypes';
import { formatPrice } from '@/utils/formatPrice';
import { CurrentContext } from '@/widgets/Current/context/context';
import { actions } from '@/widgets/Current/context/actions';
import {
  dropdownStyle,
  regNumberLink,
  showOnPageOptions,
  theme,
} from '@/widgets/Current/constants/constants';

import Vicon from '/public/icons/v.svg';
import ExportDiagonalIcon from '/public/icons/exportDiagonal.svg';
import Question from '/public/icons/questionRound.svg';

import styles from './ProcessTable.module.scss';

const formatString = (string: string) => {
  return string.split('(').join('\n(');
};

const columns: TableProps<PurchaseInProcess>['columns'] = [
  {
    title: 'Наименование объекта закупки',
    dataIndex: 'title',
    key: 'title',
    width: 762,
    render: (_, { title, regNum }) => (
      <Link
        href={regNumberLink + regNum}
        className={styles.title}
        target="_blank"
      >
        <ExportDiagonalIcon className={styles.diagonal} />
        <span className={styles.titleText}>{title}</span>
      </Link>
    ),
  },
  {
    title: 'НЦМК, ₽',
    dataIndex: 'nmck',
    key: 'nmck',
    width: 130,
    className: styles.nmckRow,
    render: (_, { nmck }) => formatPrice(nmck),
  },
  {
    title: 'Способ закупки',
    dataIndex: 'placingWay',
    key: 'placingWay',
    className: styles.placingWayRow,
    width: 195,
    render: (_, { placingWay }) => formatString(placingWay),
  },
  {
    title: 'Следующий шаг',
    dataIndex: 'nextStep',
    key: 'nextStep',
    width: 450,
    className: styles.nextStepRow,
    render: (_, { nextStep }) => formatString(nextStep),
  },
  {
    title: 'Срок',
    dataIndex: 'dates',
    key: 'dates',
    className: styles.datesRow,
    render: (_, { dates, datesTipText, datesTipLinkText, datesTipLinkUrl, expired }) => {
      return (
        <div className={styles.datesContainer}>
          <span className={expired ? styles.expired : undefined}>{dates}</span>
          <Tooltip
            title={
              <p className={styles.tooltipText}>
                {datesTipText}
                <br />
                {datesTipLinkUrl ? (
                  <Link href={datesTipLinkUrl}>{`(${datesTipLinkText})`}</Link>
                ) : (
                  <p>{`(${datesTipLinkText})`}</p>
                )}
              </p>
            }
            placement="bottom"
          >
            <div className={styles.questionIcon}>
              <Question />
            </div>
          </Tooltip>
        </div>
      );
    },
  },
];

export type ProcessTable = {
  data: PurchasesInProcessResponse;
  loading: boolean;
};

export const ProcessTable = memo(({ data, loading }: ProcessTable) => {
  const { state, dispatch } = useContext(CurrentContext);

  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(actions.setProcessCurrentPage(page));
    },
    [dispatch],
  );

  const handleShowOnPageSelectChange = useCallback(
    (value: number) => {
      dispatch(actions.setProcessItemsPerPage(value));
    },
    [dispatch],
  );

  const getFooter: TableProps<PurchaseInProcess>['footer'] = useCallback(() => {
    return (
      <div className={styles.tableFooter}>
        <div className={styles.paginationContainer}>
          <p className={styles.paginationText}>Страницы:</p>
          <Pagination
            current={state.processCurrentPage}
            total={data.count}
            pageSize={state.processItemsPerPage}
            showSizeChanger={false}
            onChange={handlePageChange}
            className={styles.pagination}
          />
        </div>
        <div className={styles.pageSize}>
          Показывать на странице:
          <Select
            dropdownStyle={dropdownStyle}
            variant="borderless"
            suffixIcon={<Vicon className={styles.iconV} />}
            value={state.processItemsPerPage}
            onChange={handleShowOnPageSelectChange}
            options={showOnPageOptions}
          />
        </div>
      </div>
    );
  }, [
    data.count,
    handlePageChange,
    handleShowOnPageSelectChange,
    state.processCurrentPage,
    state.processItemsPerPage,
  ]);

  return (
    <div className={styles.wrapper}>
      <ConfigProvider theme={theme}>
        <Table
          rowKey={'title'}
          loading={loading}
          columns={columns}
          dataSource={data.results}
          className={styles.table}
          pagination={false}
          footer={getFooter}
        />
      </ConfigProvider>
    </div>
  );
});

ProcessTable.displayName = 'ProcessTable';
