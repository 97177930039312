import { memo, useCallback, useContext, useState } from 'react';
import Vicon from '/public/icons/v.svg';
import { Button, Flex } from 'antd';
import cx from 'classnames';
import styles from './PlanningAllPageHeader.module.scss';
import { BDUIFilterParam, BDUIFilterResponse } from '@/components/BDUIFilter/types';
import { PlanningAllContext } from '@/widgets/PlanningAll/context/context';
import { actions } from '@/widgets/PlanningAll/context/actions';
import { NewPurchaseModal } from './NewPurchaseModal/NewPurchaseModal';
import { BDUIFilter } from '@/components/BDUIFilter/BDUIFilter';

type PlanningAllPageHeaderProps = {
  filtersResponse?: BDUIFilterResponse[];
  isPurchasesLoading: boolean;
  refreshPurchases: () => void;
};
export const PlanningAllPageHeader = memo(
  ({ filtersResponse = [], isPurchasesLoading, refreshPurchases }: PlanningAllPageHeaderProps) => {
    const { dispatch, state } = useContext(PlanningAllContext);
    const [additionalParams, setAdditonalParams] = useState(true);
    const [isOpenModal, setOpenModal] = useState(false);

    const handleAdditionalParamsClick = useCallback(() => {
      setAdditonalParams((prev) => !prev);
    }, []);

    const handleFiltersChange = useCallback(
      (newFilters: BDUIFilterParam[]) => {
        dispatch(actions.filtersUpdate(newFilters));
      },
      [dispatch],
    );

    const addNewPurchase = useCallback(() => {
      setOpenModal(true);
    }, []);

    const closeModal = useCallback(() => {
      setOpenModal(false);
    }, []);

    return (
      <div className={styles.wrapper}>
        <NewPurchaseModal
          isOpenModal={isOpenModal}
          closeModal={closeModal}
          refreshPurchases={refreshPurchases}
        />
        <div className={styles.flex}>
          <Flex gap={64}>
            <div className={styles.title}>Планирование закупок</div>
            <Flex gap={36}>
              <Flex
                justify="center"
                align="center"
                gap={8}
                className={cx(styles.expand, {
                  [styles.active]: additionalParams,
                })}
                onClick={handleAdditionalParamsClick}
              >
                <div>Уточняющие параметры</div>
                <Vicon
                  className={cx(styles.expandIcon, {
                    [styles.active]: additionalParams,
                  })}
                ></Vicon>
              </Flex>
              <Button
                type="primary"
                onClick={addNewPurchase}
              >
                Создать новую заявку
              </Button>
            </Flex>
          </Flex>
          {additionalParams && (
            <BDUIFilter
              currentFilterParams={state.filters}
              filtersResponse={filtersResponse}
              isFiltersLoading={isPurchasesLoading}
              onFiltersChange={handleFiltersChange}
            />
          )}
        </div>
      </div>
    );
  },
);
PlanningAllPageHeader.displayName = 'PlanningAllPageHeader';
