import { TokenBasedPaginationTokenValue } from '@/api/commonApi/commonApiTypes';

export enum ActionType {
  PAGE_SIZE_UPDATE = 'PAGE_SIZE_UPDATE',
  TOKEN_UPDATE = 'TOKEN_UPDATE',
}

type ActionCreator<T extends ActionType, P> = (payload: P) => {
  type: T;
  payload: P;
};

const actionCreator = <P, T extends ActionType>(type: T) =>
  ((payload: P) => ({
    type,
    payload,
  })) as ActionCreator<T, P>;

export const actions = {
  pageSizeUpdate: actionCreator<number, ActionType.PAGE_SIZE_UPDATE>(ActionType.PAGE_SIZE_UPDATE),
  tokenUpdate: actionCreator<TokenBasedPaginationTokenValue, ActionType.TOKEN_UPDATE>(
    ActionType.TOKEN_UPDATE,
  ),
};

export type Actions = ReturnType<(typeof actions)[keyof typeof actions]>;
