import styles from './Badge.module.scss';
import cx from 'classnames';

type BadgeProps = {
  count: number;
  className?: string;
};

export const Badge = ({ count, className }: BadgeProps) => {
  return <div className={cx(styles.badge, className, { [styles.disabled]: !count })}>{count}</div>;
};
